import { useLocation } from 'react-router';
import classNames from 'classnames';
import { format } from 'date-fns';
// import ruPlural from 'helpers/ruPlural';
import getDuration from 'helpers/getDuration';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';
import MentorButtons from './MentorButtons';
import PlayerButtons from './PlayerButtons';

import styles from './homework.module.css';

const Homework = ({ user, info, tasks, content, onClickReject, onClickSubmit, onSendComment, onChangeFile, settings }) => {
  const { hash } = useLocation();
  const currentIndex = (hash && tasks && tasks[hash.substring(1)]) ? hash.substring(1) : 0;
  const current = tasks && tasks[currentIndex];

  if (!info) return (
    <div className={classNames(styles.block, 'skeleton-loader')} />
  );

  const { is_overdue, is_done, now_timer } = info;
  let rows = [
    {
      label: 'ДЗ выполнено',
      value: settings && `<span>${settings.domashka_small} <img src="/images/crystal_2.svg" alt="" /></span>`,
      status: 'danger'
    },
    {
      label: 'ДЗ выполнено не вовремя',
      value: settings && `<span>${settings.domashka_small_crystall} <img src="/images/crystal_2.svg" alt="" /></span>`,
      status: 'danger'
    },
    {
      label: 'Бонус за аккуратность',
      value: settings && `<span>+${settings.domashka_accur_crystall_bonus} <img src="/images/crystal_2.svg" alt="" /></span>`,
      status: 'success'
    }
  ];

  if (!is_done) {
    rows.push({
      label: is_overdue ? 'Просрочено' : 'Осталось',
      value: now_timer && getDuration(now_timer),
      status: is_overdue ? 'danger' : 'success'
    });
  } else {
    rows.push({
      label: 'Выполнено',
      value: format(new Date(is_done.replace(' ', 'T')), 'dd.MM.yyyy'),
      status: 'success'
    });
  }

  return (
    <div className={styles.root}>
      <ButtonsContainer className={styles.buttons}>
        {tasks.map(({ type_name }, index) => (
          <Button
            butStyle={Number(currentIndex) === Number(index) ? 'primary' : 'secondary'}
            to={'#' + index}
            key={index}
          >
            {type_name}
          </Button>
        ))}
      </ButtonsContainer>

      <div className={styles.block}>
        {rows.map(({ label, value, status }, index) => (
          <div className={styles.row} key={index}>
            <div className={classNames(styles.label, status && styles[status])}>{label}:</div>
            <div
              className={classNames(styles.value, status && styles[status])}
              dangerouslySetInnerHTML={{ __html: value }}
            />
          </div>
        ))}
      </div>
      <div className={styles.content} dangerouslySetInnerHTML={{ __html: content }} />

      {user.type === 'M' ? (
        <MentorButtons
          current={current}
          tasks={tasks}
          onClickReject={onClickReject}
          onClickSubmit={onClickSubmit}
          onSendComment={onSendComment}
          onChangeFile={onChangeFile}
          bonus={settings?.domashka_accur_crystall_bonus}
        />
      ) : (
        <PlayerButtons
          current={current}
          tasks={tasks}
          onClickSubmit={onClickSubmit}
          onChangeFile={onChangeFile}
        />
      )}
    </div>
  )
};

export default Homework;
