import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import ArtefactsList from 'components/ArtefactsList';
import { selectItem } from 'store/app/getters';
import useApiQuery from 'hooks/useApiQuery';

const Artefacts = () => {
  const user = useSelector(selectItem(['user']));

  const { data: artefacts } = useApiQuery({
    name: ['artefacts'],
    path: `/playerrartefacts/${user.uid}/`,
    skip: !user
  });

  if (!user) return null;

  return (
    <MainLayout title="Артефакты">
      <ArtefactsList items={artefacts} />
    </MainLayout>
  );
};

export default Artefacts;
