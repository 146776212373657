import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import HomeworkList from 'components/homework/HomeworkList';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';

const HomeworkListPage = () => {
  const user = useSelector(selectItem(['user']));

  const { data: homework } = useApiQuery({
    name: ['homework_all'],
    path: `/api/getuserdomashka/${user.uid}/all/`,
    skip: !user
  });

  return (
    <MainLayout title="Школьная домашка">
      <HomeworkList items={homework && homework.tasks} emptyText="Нет домашних заданий" />
    </MainLayout>
  );
};

export default HomeworkListPage;