import classnames from 'classnames';

import styles from './buttons-container.module.css';

const ButtonsContainer = ({ className, children }) => {
  return (
    <div className={classnames(styles.main, className)}>
      { children }
    </div>
  );
};

export default ButtonsContainer;