import classnames from 'classnames';

import styles from './info-block.module.css';

const InfoBlock = ({ color = 'green', children }) => {
  return (
    <div className={classnames(styles.root, styles[color])}>
      {children}
    </div>
  );
}

export default InfoBlock;
