export const filterByKey = (arr, key, value) => {
  if (!(key && key.length)) return arr;

  return arr.reduce((prev, current) => {
    if (String(current[key]) === String(value)) return [...prev, current];
    return prev;
  }, []);
};

export const keyBy = (obj, path, def) => {
  if (!(path && path.length)) return obj;

  let res = obj;

  for (const pathElement of path) {
    if (!(typeof res === 'object' && pathElement in res)) return def;

    res = res[pathElement];
  }

  return res;
};

export const setTo = (obj, path, value) => {
  if (!path.length) return typeof value === 'function' ? value(null) : value;
  const res = unFrozen(obj || {})

  let prom = res;

  for (const pathElement of path.slice(0, -1)) {
    prom = prom[pathElement] = unFrozen(prom[pathElement] || {});
  }

  const [ key ] = path.slice(-1);

  prom[key] = unFrozen(prom[key]);
  prom[key] = typeof value === 'function' ? value(prom[key]) : value;

  return res;
};

const unFrozen = n => {
  if (typeof n !== 'undefined' && n !== null && Object.isFrozen(n)) {
    return Object.assign(Array.isArray(n) ? [] : {}, n);
  }
  return n;
}
