const params = {
  visible:      3,    // квестов на экране
  minQuests:    30,   // для fullRolls
  fullRolls:    3,    // кол-во кругов - если квестов < minQuests
  speed:        600
}

let interval = null;

export const wheelInit = (container, list) => {
  const childs = list.children;

  if (childs && childs.length) {
    // let containerHeight = 0;

    // for (let i = 0; i < params.visible; i++) {
    //   containerHeight += childs[i].getBoundingClientRect().height;
    // }
    // containerHeight--;
    // container.style.height = containerHeight + 'px';
    list.style.transition = `transform ${params.speed / 1000}s ease`;
  }
}

export const wheelStart = (list) => {
  const childs = list.children;

  if (childs && childs.length) {
    const random = Math.floor(Math.random() * (childs.length - params.visible));
    const distance = childs[random].offsetTop;
    const rollDistance = (childs.length < params.minQuests) ? list.getBoundingClientRect().height * params.fullRolls : 0;

    // wheelRoll(list, rollDistance + distance);
    list.style.transform = `translateY(${-rollDistance -distance}px)`;
  }
}

export const wheelStop = (list) => {
  const childs = list.children;

  if (childs && childs.length) {
    // const random = Math.floor(Math.random() * (childs.length - params.visible));
    // const distance = childs[random].offsetTop;

    window.cancelAnimationFrame(interval);
    list.style.transform = `translateY(0)`;
    // list.style.transform = `translateY(${-distance}px)`;
  }
}

let distance = 0;

export const wheelRoll = (list) => {
  const childs = list.children;
  list.style.transition = `transform ${params.speed / 1000}s ease`;

  if (childs && childs.length) {
    const itemsHeight = list.getBoundingClientRect().height;
    const parentHeight = list.parentElement.getBoundingClientRect().height;
    const end = itemsHeight - parentHeight;
    list.style.transition = `none`;

    roll(list, end);
  }
}

const roll = (list, end) => {
  if (distance > end - 50) {
    distance = 0;
    list.style.transform = `translateY(${-distance}px)`;
  } else {
    distance = distance + 50;
    list.style.transform = `translateY(${-distance}px)`;
  }

  interval = window.requestAnimationFrame(() => roll(list, end));
}
