import { forwardRef, useState, useEffect } from 'react';
import { ReactSVG } from 'react-svg';
import Modal from '../../Modal';
import classnames from 'classnames';

import styles from './tariff-players.module.css';

const SelectPlayers = ({ players, selected, onChange }) => {
  const handleAdd = (player_uid) => {
    onChange([ ...selected, player_uid ]);
  };

  const handleRemove = (player_uid) => {
    onChange(selected.filter(id => Number(player_uid) !== Number(id)));
  };

  return (
    <ul className={styles.selectList}>
      {Object.values(players).map((player) => {
        let { uid, uid_gamer, name, surname, nickname, gender, avatar, info } = player;

        if (uid_gamer) uid = uid_gamer;
        if (info && info.uid) uid = info.uid;
        if (info && info.uid_gamer) uid = info.uid_gamer;
        if (info && info.name) name = info.name;
        if (info && info.surname) surname = info.surname;
        if (info && info.nickname) nickname = info.nickname;
        if (info && info.gender) gender = info.gender;
        if (info && info.avatar) avatar = info.avatar;

        const active = selected?.find(id => Number(uid) === Number(id));
        const photo = avatar ? avatar : (`/icons/icon-child-${gender ? gender.toLowerCase() : 'm'}.svg`);

        return (
          <li
            key={uid}
            className={classnames(styles.selectItem, active && styles.active)}
            onClick={() => active ? handleRemove(uid) : handleAdd(uid)}
          >
            <img src={photo} className={styles.selectIcon} alt="" />
            <div className={styles.selectName}>
              {(name || surname) ? `${name ? name : ''} ${surname ? surname : ''}` : 'Без имени'}
              <div className={styles.selectNickname}>
                {nickname}
              </div>
            </div>
          </li>
        )
      })}
    </ul>
  )
}

const TariffPlayers = ({ players, selected, onChange }) => {
  const [ popupSelected, setPopupSelected ] = useState(selected);

  useEffect(() => {
    setPopupSelected(selected);
  }, [selected]);

  if (!players) return null;

  const handleChangePopup = (selected) => {
    setPopupSelected(selected);
  };

  const handleRemove = (player_uid) => {
    onChange(selected.filter(id => Number(player_uid) !== Number(id)));
  };

  const control = forwardRef(({ onClick }, ref) => (
    <li className={classnames(styles.item, styles.itemAdd)} onClick={onClick} ref={ref} disabled={players.length <= Object.keys(selected).length}>
      <ReactSVG src="/icons/icon-plus.svg" className={styles.iconAdd} wrapper="span" />
    </li>
  ));

  return (
    <ul className={classnames(styles.list, 'scroll-list')}>
      {Object.values(players).map(({ uid, uid_gamer, name, gender, avatar, info }) => {
        if (!selected?.find(id => Number(uid_gamer) === Number(id))) return null;

        if (uid_gamer) uid = uid_gamer;
        if (info && info.uid) uid = info.uid;
        if (info && info.uid_gamer) uid = info.uid_gamer;
        if (info && info.name) name = info.name;
        if (info && info.gender) gender = info.gender;
        if (info && info.avatar) avatar = info.avatar;

        const photo = avatar ? avatar : (`/icons/icon-child-${gender ? gender.toLowerCase() : 'm'}.svg`);

        return (
          <li key={uid} className={styles.item}>
            <img src={photo} className={styles.icon} alt="" />
            <div className={styles.name}>
              {name ? name : 'Без имени'}
            </div>
            <button className={styles.remove} onClick={() => handleRemove(uid_gamer)}>
              <img src="/icons/icon-close.svg" className={styles.removeIcon} alt="" />
            </button>
          </li>
        )
      })}

      <Modal
        viewStyle="bottom"
        buttons={[
          { text: 'Подтвердить', action: 'close', style: 'primary', onClick: () => onChange(popupSelected) }
        ]}
        control={control}
      >
        <SelectPlayers selected={popupSelected} players={players} onChange={handleChangePopup} />
      </Modal>
    </ul>
  );
};

export default TariffPlayers;