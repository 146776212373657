import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import FileInput from 'components/fields/FileInput';
import Gallery from 'components/Gallery';
import HomeworkComments from './HomeworkComments';

import styles from './homework.module.css';


const PlayerButtons = ({ current, onClickSubmit, onChangeFile }) => {
  if (!current) return null;

  return (
    <>
      <HomeworkComments {...current} user_type="P" />

      {(current.is_rejected && current.comment) &&
        <div className={styles.comment}>{current.comment}</div>
      }

      {(current.files && current.files.length) ? (
        <div className={styles.gallery}>
          <Gallery
            items={current.files.map(({ file_url }) => ({ link: file_url }))}
            onChangeFile={(file, old_file) => onChangeFile(file, current.uid, current.type, old_file)}
          />
        </div>
      ) : null}

      {!(current.is_complete || current.is_done) && (
        <ButtonsContainer>
          <FileInput
            id="photo"
            name="photo"
            butStyle="secondary"
            className={styles.downloadButton}
            text={`Загрузить ${current.type_name}`}
            onChange={e => onChangeFile(e.target.files[0], current.uid, current.type)}
          />
          <Button
            butStyle="primary"
            fullWidth={true}
            onClick={() => onClickSubmit(current.uid)}
            disabled={!current.files.length}
          >
            Я выполнил домашку
          </Button>
        </ButtonsContainer>
      )}
    </>
  );
};

export default PlayerButtons;
