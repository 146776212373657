import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import HomeworkList from 'components/homework/HomeworkList';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';

const HomeworkListPage = () => {
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;

  const { data: homework } = useApiQuery({
    name: ['homework_all', currentPlayer.uid_gamer],
    path: `/api/getuserdomashka/${currentPlayer.uid_gamer}/all/`,
    skip: !user || !currentPlayer
  });

  return (
    <MainLayout title="Школьная домашка">
      <HomeworkList items={homework?.tasks} emptyText="Нет домашних заданий" />
    </MainLayout>
  );
};

export default HomeworkListPage;