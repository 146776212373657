import Select from 'components/fields/Select';
import ScheduleDay from './ScheduleDay';

import styles from './homework-schedule.module.css';

const minHour = 8;
const maxHour = 22;

const HomeworkSchedule = ({ gmt, cities, items, onChangeGmt, onChange }) => {
  if (!gmt && cities) gmt = cities[0].gmt;

  let timeOptionsFrom = [];
  let timeOptionsTo = [];
  for (let hour=minHour; hour<=maxHour; hour++) {

    if (hour < maxHour) timeOptionsFrom.push({ label: `С ${hour}:00`, value: `0${hour}:00`.slice(-5, 6)});
    if (hour < maxHour) timeOptionsFrom.push({ label: `С ${hour}:30`, value: `0${hour}:30`.slice(-5, 6)});

    timeOptionsTo.push({ label: `До ${hour}:00`, value: `0${hour}:00`.slice(-5, 6)});
    if (hour < maxHour) timeOptionsTo.push({ label: `До ${hour}:30`, value: `0${hour}:30`.slice(-5, 6)});
  };

  const handleChangeDay = (day, time_uid, checked, start, end) => {
    onChange(day, time_uid, checked, gmt, start, end);
  };

  const lastSelectedDay = Object.keys(items).findLast(day => !!(items[day].time && items[day].time.uid));

  return (
    <div className={styles.root}>
      <div className={styles.gmt}>
        <div className={styles.gmtTitle}>Установить часовой пояс</div>
        <Select
          name="gmt_mark"
          placeholder="Выберите часовой пояс"
          modalTitle="Установить часовой пояс"
          className={styles.select}
          options={cities.map(({ uid, name, gmt }) => ({ label: `${name} +${gmt}GMT`, value: gmt }))}
          onChange={e => onChangeGmt(e.target.value)}
          value={gmt}
        />
      </div>

      <div className={styles.gmtSubTitle}>Выберите периоды времени для ДЗ</div>
      {Object.keys(items).map(day => {
        let time = null;
        if (items[day].time && items[day].time.uid) time = items[day].time;
        else if (lastSelectedDay) time = { start: items[lastSelectedDay].time.start, end: items[lastSelectedDay].time.end };

        return (
          <ScheduleDay
            id={day}
            name={items[day].name}
            time={time}
            timeOptionsFrom={timeOptionsFrom}
            timeOptionsTo={timeOptionsTo}
            onChange={handleChangeDay}
            key={day}
          />
        );
      })}
    </div>
  );
};

export default HomeworkSchedule;
