import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Button from 'components/Button';

import styles from './homework.module.css';

const HomeworkItem = ({ index, info, type, title, notice, noticeType, buttonText, showSettings }) => {
  return (
    <div className={classNames(styles.item, styles[`type-${type}`])}>
      <Link to={info ? `/homework/${info.date}/#${index}` : (showSettings && '/homework-update')} className={styles.link} />
      {showSettings && (
        <Link className={styles.settings} to='/homework-update'>
          <img src="/icons/icon-settings.svg" className={styles.settingsIcon} alt="" />
        </Link>
      )}
      <div className={styles.itemContent}>
        <img
          src={type === 1 ? `https://play.skillary.club/person/201-1.svg` : `https://play.skillary.club/person/101-1.svg`}
          className={styles.itemAvatar}
          alt=""
        />
        <div className={styles.itemText}>
          <div className={styles.itemTitle}>{title}</div>
          {buttonText && (
            <Button butStyle="primary" className={styles.smallButton}>{buttonText}</Button>
          )}
          {notice && <div className={classNames(styles.itemNotice, styles[noticeType])} dangerouslySetInnerHTML={{ __html: notice }} />}
        </div>
      </div>
    </div>
  )
};

export default HomeworkItem;
