import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import PinPad from 'components/header/PinPad';
import Input from 'components/fields/Input';
import Modal from 'components/Modal';
import Button from 'components/Button';
import { setItem } from 'store/app/actions';
import * as api from 'helpers/api';

import styles from './login-player.module.css';

const LoginPlayer = forwardRef(({ user, player, players, pin_code }, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [ saveStatus, setSavePinSuccess ] = useState(false);
  const [ isShowPinModal, setShowPinModal ] = useState(false);
  const [ isShowSuccessModal, setShowSuccessModal ] = useState(false);
  const [ pinCode, setPinCode ] = useState('');

  useEffect(() => {
    if (saveStatus) setShowSuccessModal(true);
  }, [saveStatus]);

  const handleClickLogin = (uid_gamer) => {
    if (pin_code && players) {
      const player = players.find((player) => player.uid_gamer === uid_gamer);
      const token = (player.info && player.info.self_key) ? player.info.self_key : player.self_key;

      api
        .get(`/childauth/${token}/`)
        .then(response => {
          const result = response.data.response;

          if (result.user) {
            dispatch(setItem(['user'], result.user, { isHard: true }));
            navigate('/');
          } else {
            const error = result.error ? result.error : 'Неверная ссылка авторизации'
            dispatch(setItem(['notifications', 'login'], { type: 'error', content: error }));
          }
        })
        .catch(error => {
          dispatch(setItem(['notifications', 'login'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
        });
    } else {
      setShowPinModal(true);
    }
  };

  useImperativeHandle(ref, () => ({
    login: handleClickLogin
  }));

  if (!player) return null;

  const handleClickButton = (number) => {
    setPinCode(state => state.length < 4 ? `${state}${number}` : state);
  };

  const handleClickRemove = () => {
    setPinCode(state => String(state).slice(0, String(state).length-1));
  };

  const handleSavePin = (code) => {
    setSavePinSuccess(false);

    if (String(code) === '0000') {
      dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: 'Невозможно установить пин-код 0000' }));
      return null;
    };

    const fd = new FormData();
    fd.append('params[pin_code]', code);

    api
      .post(`/updateUserProfile/${user.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          dispatch(setItem(['pin_code'], true));
          setSavePinSuccess(true);
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  return (
    <>
      <Modal
        viewStyle="bottom"
        open={isShowPinModal}
        onClose={() => setShowPinModal(false)}
      >
        <div className={styles.root}>
          <div className={styles.title}>Придумайте пин-код</div>
          <Input mask="####" placeholder="0000" inputClass={styles.input} value={pinCode} readOnly={true} />
          <Button
            butStyle="primary"
            className={styles.submit}
            disabled={pinCode.length < 4}
            onClick={() => handleSavePin(pinCode)}
          >
            Далее
          </Button>
          <PinPad
            onClickButton={handleClickButton}
            onClickRemove={handleClickRemove}
          />
        </div>
      </Modal>
      <Modal
        viewStyle="bottom"
        open={isShowSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        buttons={[
          {
            text: 'Войти как игрок',
            style: 'primary',
            onClick: () => handleClickLogin(player.uid_gamer)
          }
        ]}
      >
        <div className={styles.dinoRoot}>
          <div className={styles.title}>Пин-код сохранён</div>
          <ReactSVG src="/images/dino.svg" className={styles.dino} />
        </div>
      </Modal>
    </>
  );
});

export default LoginPlayer;
