import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import * as api from 'helpers/api';
import { RegisterLayout } from 'components/Layouts';
import Form from 'components/Form';
import Input from 'components/fields/Input';
// import Select from 'components/fields/Select';
import Switch from 'components/fields/Switch';
import Button from 'components/Button';
import DateInput from 'components/fields/DateInput';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';

import styles from './account.module.css';

import { genders } from 'data/mock';

const CreatePlayer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useLocation();

  const [ isSubmitActive, setSubmitActive ] = useState(false);

  const user = useSelector(selectItem(['user']));
  // const gender = user?.gender;

  let locationOpts = [];
  if (user.location) {
    locationOpts.push({
      label: user.location,
      value: user.location
    });
  }

  const minDateBirth = new Date();
  minDateBirth.setFullYear(minDateBirth.getFullYear() - 12);

  const maxDateBirth = new Date();
  maxDateBirth.setFullYear(maxDateBirth.getFullYear() - 6);

  // const mapMentoringStructure = (data) => {
  //   if (gender) {
  //     return data.for_sex[gender].map(({ name, uid }) => ({ label: name, value: uid }));
  //   } else {
  //     return Object.values(data.for_uid).map(({ name, uid }) => ({ label: name, value: uid }));
  //   }
  // }

  const inputs = [
    { id: 'name', name: 'name', component: Input, validation: 'string',
      props: { label: 'Имя ребенка', placeholder: 'Иван', fullWidth: true }
    },
    { id: 'birth_date', name: 'birth_date', component: DateInput, validation: 'date',
      props: {
        label: 'Дата рождения',
        type: 'date',
        placeholder: '5 июня 2006 года',
        fullWidth: true,
        icon: <ReactSVG src="/icons/icon-calendar.svg" />,
        minDate: minDateBirth,
        maxDate: maxDateBirth
      }
    },
    // { id: 'location', name: 'location', component: Select, validation: 'select',
    //   props: {
    //     label: 'Город проживания', value: user.location, query: user.location, options: locationOpts,
    //     placeholder: 'Выберите город', fullWidth: true, isSearchable: true, searchPlaceholder: 'Например, "Новоуральск',
    //     loadUrl: '/getcity', mapper: (data) => data.map(({ city }) => ({ label: city, value: city }))
    //   }
    // },
    { id: 'gender', name: 'gender', component: Switch, validation: 'select',
      props: { id: 'gender', title: 'Пол ребенка', options: genders, color: 'orange' }
    },
    // { id: 'relation', name: 'relation', component: Select, validation: 'select',
    //   props: {
    //     title: 'Вы для ребенка', placeholder: 'Выберите тип родства', fullWidth: true,
    //     loadUrl: `/getmentoringstructure`, mapper: (data) => mapMentoringStructure(data)
    //   }
    // }
  ];

  const onFormSubmit = (data) => {
    api
      .get(`/createMentoringForChild/new_player_create/9999/`)
      .then(response => {
        const result = response.data.response;
        if (result.success && result.player) {
          saveData(result.player.uid, data);
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const saveData = (uid, data) => {
    if (!uid) return;

    const fd = new FormData();
    for (let key in data) {
      fd.append(`params[${key}]`, data[key]);
    }

    api
      .post(`/updateUserProfile/${uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'success', content: 'Ребенок добавлен!' }));
          if (hash === '#update') navigate('/update');
          else navigate(`/create`);
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  return (
    <RegisterLayout showLogo>
      <h1 className={styles.title}> Данные ребёнка</h1>
      <div className={styles.content}>
        <Form
          inputs={inputs}
          onSubmit={onFormSubmit}
          onValidChange={setSubmitActive}
          buttons={
            <Button
              type="submit"
              fullWidth={true}
              butStyle="primary"
              disabled={!isSubmitActive}
            >
              Далее
            </Button>
          }
        />
      </div>
    </RegisterLayout>
  );
}

export default CreatePlayer;
