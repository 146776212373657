import { useState } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Modal from 'components/Modal';
import NoItems from 'components/NoItems';
import CrystalsBalance from 'components/CrystalsBalance';

import styles from './shop.module.css';

const Shop = ({ items = [1, 2, 3, 4], crystalls, onClickBuy }) => {
  const [ itemToPay, setItemToPay ] = useState(null);

  if (!Object.keys(items).length) return ( // no items
    <NoItems children="Нет назначенных наград" />
  );

  return (
    <div className={styles.root}>
      <div className={classNames(styles.header, crystalls === undefined && 'skeleton-loader')}>
        {(crystalls || crystalls === 0) && <CrystalsBalance crystals={crystalls} />}
      </div>

      <div className={styles.list}>
        {items.map(({ uid, name, price, url }, index) => {
          if (!uid) return (
            <div className={styles.item} key={index}>
              <div className={classNames(styles.imageWrapper, 'skeleton-loader')} />
              <div className={styles.content}>
                <div className={classNames(styles.title, 'skeleton-loader')} />
                <div className={classNames(styles.price, 'skeleton-loader')} />
              </div>
            </div>
          );
          return (
            <div className={styles.item} key={uid} onClick={() => onClickBuy && setItemToPay({ uid, name, url, price })}>
              <div className={styles.imageWrapper}>
                <img className={styles.image} src={url} alt={name} />
              </div>
              <div className={styles.content}>
                <div className={styles.title}>
                  {name}
                </div>
                <div className={styles.price}>
                  <div className={styles.priceValue}>
                    <ReactSVG src="/images/crystal.svg" className={styles.priceIcon} wrapper="span" />
                    {price}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <Modal
        viewStyle="bottom"
        open={!!itemToPay}
        onClose={() => setItemToPay(null)}
        buttons={[
          {
            text: 'Да, купить',
            style: 'primary',
            onClick: () => {
              onClickBuy(itemToPay);
              setItemToPay(null);
            }
          },
          {
            action: 'close',
            text: 'Нет, я передумал',
            style: 'secondary',
          }
        ]}
      >
        {itemToPay && (
          <>
            <div className={styles.modalImageContainer}>
              <img src={itemToPay.url} className={styles.image} alt={itemToPay.name} />
            </div>
            <div className={styles.modalTitle}>
              Купить за {itemToPay.price} <ReactSVG src="/images/crystal.svg" className={styles.modalPriceIcon} wrapper="span" /> ?
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default Shop;