import classNames from 'classnames';

import styles from './constructor-placeholder.module.css';

const ConstructorPlaceholder = () => {
  return (
    <div className={styles.root}>
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={classNames(styles.balance, 'skeleton-loader')}>
          </div>
          <div className={classNames(styles.imageContainer, 'skeleton-loader')}>
          </div>
          <div className={classNames(styles.buttonContainer, 'skeleton-loader')}>
          </div>
        </div>
        <div className={classNames(styles.right, 'skeleton-loader')} />
      </div>
      <div className={classNames(styles.bottom, 'skeleton-loader')} />
    </div>
  )
};

export default ConstructorPlaceholder;
