import { useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectItem } from '../../store/app/getters';

import styles from './game-chat.module.css';

const Dots = () => {
  const [ dots, setDots ] = useState('.');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(prev => {
        if (prev.length === 3) return '.';
        else return prev + '.';
      });
    }, 400);

    return () => clearInterval(interval);
    //eslint-disable-next-line
  }, []);

  return dots;
}

const ChatMessage = ({ avatar, name, hidden, showDots, text, align }) => {
  return (
    <div className={classNames(styles.item, hidden ? styles.hidden : undefined)}>
      {align === 'left' && <img src={avatar} className={styles.image} alt={name} />}
      <div className={classNames(styles.message, styles[align])}>
        {showDots ? <Dots /> : text}
      </div>
      {align === 'right' && <img src={avatar} className={styles.image} alt={name} />}
    </div>
  );
};

const ChatItem = ({ dialogId, messageKey, user, ava_emo, name, text, ask, hidden, showDots, onAnswer }) => {
  const [ isShow, setShow ] = useState(false);
  const [ isShowQestion, setShowQestion ] = useState(false);
  const [ isShowAnswer, setShowAnswer ] = useState(false);

  useEffect(() => {
    if (!hidden) {
      setTimeout(() => setShow(true), 100);
      setTimeout(() => setShowQestion(true), (ask && !ask.user_variant) ? 800 : 100);
      setTimeout(() => setShowAnswer(true), (ask && !ask.user_variant) ? 1400 : 100);
    }
    // eslint-disable-next-line
  }, [hidden]);

  if (hidden) return null;

  return (
    <Fragment>
      <ChatMessage
        avatar={ava_emo}
        hidden={!isShow}
        name={name}
        showDots={showDots}
        align="left"
        text={text}
      />

      {(ask && ask.uid) && (
        <>
          <ChatMessage
            avatar={ava_emo}
            hidden={!isShowQestion}
            name={name}
            text={ask.text_ask}
            align="left"
          />

          {Object.values(ask).map(({ is_choisen, label, text, npc_ava_emo, npc_name }, index) => {
            if (!is_choisen) return null;
            const avatar = user.info ? user.info.avatar : user.avatar;
            const name = user.info ? user.info.name : user.name;
            return (
              <Fragment key={'answer_' + index}>
                <ChatMessage
                  avatar={avatar}
                  hidden={!isShow}
                  name={name}
                  text={label}
                  align="right"
                />

                <ChatMessage
                  avatar={npc_ava_emo}
                  hidden={!isShow}
                  name={npc_name}
                  text={text}
                  align="left"
                />
              </Fragment>
            );
          })}


          {(!ask.user_variant && isShowAnswer) ? (
            <div className={styles.buttons}>
              {ask.yes && (
                <button className={styles.button} onClick={() => onAnswer(dialogId, messageKey, ask.uid, 1)}>
                  {ask.yes.label}
                </button>
              )}
              {ask.no && (
                <button className={styles.button} onClick={() => onAnswer(dialogId, messageKey, ask.uid, 2)}>
                  {ask.no.label}
                </button>
              )}
            </div>
          ) : null}
        </>
      )}
    </Fragment>
  );
};

const GameChat = ({ user, chat, onAnswer }) => {
  const [ current, setCurrent ] = useState(0);
  const lastDialogId = Number(Object.keys(chat)[Object.keys(chat).length-1]);
  const lastDialog = chat[lastDialogId].dialog;
  const isChatOpen = useSelector(selectItem(['service', 'chat_open']));

  useEffect(() => {
    if (isChatOpen) {
      window.scrollTo(0, 100000);

      if (lastDialog && current < lastDialog.length) {
        const hasQuestion = !!(lastDialog[current].ask && lastDialog[current].ask.uid);
        const hasAnswer = hasQuestion && !!lastDialog[current].ask.user_variant;
        const timeout = (hasQuestion && hasAnswer) ? 0 : lastDialog[current].text.length * 35;

        if (!hasQuestion || hasAnswer) {
          setTimeout(() => {
            setCurrent(current + 1);
          }, timeout);
        }
      }
    } else {
      setCurrent(0);
    }
    //eslint-disable-next-line
  }, [chat, lastDialog, current, isChatOpen]);

  if (!chat || !lastDialog) return null;

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {Object.keys(chat).map(key => (
          <Fragment key={key}>
            {chat[key].dialog.map((item, index) => (
              <ChatItem
                {...item}
                user={user}
                dialogId={key}
                hidden={Number(key) === lastDialogId && index > current}
                showDots={Number(key) === lastDialogId && index === current && !(item.ask && item.ask.uid)}
                onAnswer={onAnswer}
                messageKey={index}
                key={index}
              />
            ))}
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default GameChat;
