import axios from 'axios';
import { SVG } from '@svgdotjs/svg.js';

/* monolog uid
1   Герои   #00FF7F
2   Легенды   #7FFFD4
3   Игроки   #FF69B4
4   Злодеи   #FF0000
*/

export function childGame(options) {
  let defaults = {
    game: null,
    container: null,
    delayDiff: 300,
    reactHeaderMessage: () => null,
    addNewPoint: () => null,
    addMonolog: () => null,
    addHeaderMessage: () => null,
    addComics: () => null
  };
  options = { ...defaults, ...options };

  let elements = {};
  let state = {
    skipRewards: false,
    rewardShowed: false,
    monologShowed: false,
    reactionShowed: false,
    comicsShowed: false,
    isBusy: false,
    isMonologOpen: false,
    isSVGEventsDisable: navigator.userAgent.toLowerCase().indexOf('safari') > -1 || navigator.userAgent.toLowerCase().indexOf('firefox') > -1
  };

  const rewardLayouts = [
    [
      { width: 300, height: 300, top: 355, left: 195 }
    ],
    [
      { width: 251, height: 220, top: 241, left: 195 },
      { width: 251, height: 220, top: 445, left: 195 }
    ],
    [
      { width: 330, height: 330, top: 275, left: 195 },
      { width: 156, height: 136, top: 466, left: 120 },
      { width: 156, height: 136, top: 466, left: 270 }
    ],
    [
      { width: 300, height: 300, top: 226, left: 195 },
      { width: 142, height: 125, top: 400, left: 127 },
      { width: 142, height: 125, top: 400, left: 263 },
      { width: 142, height: 125, top: 520, left: 195 }
    ],
    [
      { width: 300, height: 300, top: 226, left: 195 },
      { width: 142, height: 125, top: 400, left: 127 },
      { width: 142, height: 125, top: 400, left: 263 },
      { width: 142, height: 125, top: 520, left: 127 },
      { width: 142, height: 125, top: 520, left: 263 }
    ],
  ];

  this.init = async function() {
    const svg = await this.appendMap(options.game.city.uid, options.container);

    // делаем доступной запись в массив game
    options.game = { ...options.game };
    options.game.dialogs = { ...options.game.dialogs };
    options.game.points = { ...options.game.points };

    if (svg) {
      elements.draw = SVG(svg);
      const currentPoint = options.game && Object.values(options.game.points).find(({ is_active }) => !!is_active);
      state.currentPoint = currentPoint.uid;

      if (Number(currentPoint.path_number) !== 0) this.hideClouds(1);

      setTimeout(() => {
        this.setActiveIsland();
        const nextPoint = Object.values(options.game.points).find(({ path_number }) => Number(path_number) === Number(currentPoint.path_number) + 1);
        if (nextPoint) state.nextPoint = nextPoint.uid;

        const islands = elements.draw.find('[id^=island_]');

        islands.each(async elem => {
          if (!elem.attr('id').match('active')) {
            const index = elem.attr('id').replace('island_', '').slice(0, 1);

            if (index > currentPoint.path_number) {
              const stars = await this.appendElem(`/images/game/stars_${index}.svg`, [110, 85], `stars_${index}`);
              if (stars) {
                this.moveElem(stars, elem, 'L', [-10, -20], [0, 0]);
                elem.after(stars);
              }
            }
          }
        });

        this.addGates();

        if (options.afterInit) options.afterInit();

        this.createCache();
      }, 100);
    }
  };

  this.selectAction = async function() {
    const currentPoint = options.game.points[state.currentPoint];
    if (currentPoint) {
      const rewards = currentPoint.rewards;
      const dialogs = (options.game.dialogs && options.game.dialogs[currentPoint.uid]) && options.game.dialogs[currentPoint.uid];

      if (!(!state.rewardShowed && !state.skipRewards && rewards && rewards.chest && rewards.artefact)) {
        // skip showing rewards
        state.rewardShowed = true;
      }

      if (!state.comicsShowed && currentPoint.comics && currentPoint.comics.length) {
        // comics
        setTimeout(() => {
          this.addComics(currentPoint);
          state.comicsShowed = true;
        }, 1500);
      } else if (!state.rewardShowed) {
        // rewards
        setTimeout(() => {
          this.addChest(rewards);
          state.rewardShowed = true;
        }, 1500);
      } else if (elements.person && dialogs && dialogs.monolog && Object.keys(dialogs.monolog).length) {
        if (state.monologShowed && !state.reactionShowed) {
          // reaction
          this.selectHeaderMessage(dialogs);
          state.reactionShowed = true;
        }

        // monolog
        this.addPointInfo(currentPoint, !state.monologShowed);
        state.monologShowed = true;
      }

    }
  };

  this.appendMap = async function(city_uid, container) {
    const map = await axios.get(`/images/game/cities/city_${city_uid}.svg`);
    container.innerHTML = map.data;

    const map_active = await axios.get(`/images/game/cities/city_${city_uid}_active.svg`);
    let div = document.createElement('div');
    div.innerHTML = map_active.data;
    div.querySelectorAll('[id^="way_"]').forEach(item => {
      const id = item.id.replace('_active', '');
      const elem = document.getElementById(id);
      if (elem) elem.after(item);
      item.style.display = 'none';
      item.setAttribute('opacity', 0);
    });
    div.querySelectorAll('[id^="island_"]').forEach(item => {
      const id = item.id.replace('_active', '');
      const elem = document.getElementById(id);
      if (elem) elem.after(item);
      item.style.display = 'none';
      item.setAttribute('opacity', 0);
    });

    return container.children[0];
  };

  this.onClickIsland = async function(elem) {
    if (state.isBusy || state.isMonologOpen) return null;

    const nextPoint = state.nextPoint && options.game.points[state.nextPoint];

    if (nextPoint && nextPoint.must_check && options.game.energy >= 1000) {
      state.isBusy = true;

      const pointData = await options.addNewPoint(state.nextPoint);

      if (pointData) {
        state.rewardShowed = false;
        state.monologShowed = false;
        state.reactionShowed = false;
        if (elements.info) elements.info.remove();

        options.game.dialogs[pointData.point.uid] = { ...pointData.dialogs, mess_reaction: pointData.point.mess_reaction };
        options.game.points[state.nextPoint] = { ...nextPoint, rewards: pointData.rewards };
        options.game.points[state.nextPoint].must_check = false;
        options.game.energy = pointData.energy;

        if (pointData.next_point) options.game.points[pointData.next_point.uid] = pointData.next_point;

        if (state.isSVGEventsDisable) {
          this.pointTransformations(pointData);
        } else {
          const animate = elements.childs.find('animateTransform')[0].node;
          const that = this;
          animate.addEventListener('repeatEvent', function me(e) {
            that.pointTransformations(pointData);
            animate.removeEventListener('repeatEvent', me, false);
          }, false);
        }
      }
    } else {
      this.createHeaderMessage(
        `Нам не хватает ещё ${1000 - options.game.energy} энергии, для перемещения!`,
        [ '/images/game/alisa-wow.svg' ]
      );
    }
  };

  this.pointTransformations = async function(pointData) {
    let currentPoint = options.game.points[state.currentPoint];
    // add teleport to old island
    let teleport = await this.appendElem('/images/game/kids_teleport.svg', [100, 115], 'teleport', true);
    teleport.insertAfter(elements.childs);
    this.moveElem(
      teleport,
      elements.draw.find(`#island_${currentPoint.path_number}_active`),
      currentPoint.orientation,
      [-6.8, -71], [-210.5, -71]
    );

    // hide childs and stop animation
    elements.childs.css('opacity', '0');
    elements.childs.node.children[0].pauseAnimations();
    // elements.childs.find('animate, animateMotion, animateTransform').each(elem => {
    //   elem.attr({ repeatCount: '1' });
    // });

    // hide person and info icon
    if (elements.info) elements.info.animate(1000).attr({ opacity: 0 });
    if (elements.person) elements.person.animate(1000).attr({ opacity: 0 });

    // remove point highlight
    if (elements.pointHighlight) elements.pointHighlight.remove();
    if (elements.pointHand) elements.pointHand.remove();

    async function changePoint(that) {
      teleport.remove();

      // set new active island
      state.currentPoint = state.nextPoint;
      options.game.points[state.currentPoint].legend_icon = pointData.point.legend_icon;
      currentPoint = options.game.points[state.currentPoint];

      that.setActiveIsland(1000, 1000);
      state.nextPoint = Object.values(options.game.points).find(({ path_number }) => Number(path_number) === Number(currentPoint.path_number) + 1)?.uid;

      // add teleport to new island
      teleport = await that.appendElem('/images/game/island-transformation.svg', [195, 165], 'teleport', true);
      teleport.insertAfter(elements.childs);
      that.moveElem(
        teleport,
        elements.draw.find(`#island_${currentPoint.path_number}_active`),
        currentPoint.orientation,
        // [-52.5, -78], [-83, -79]
        [-52.5, -78], [-82.8, -78]
      );
    };

    function changePointAfter() {
      teleport.remove();

      // show childs and start animation
      elements.childs.css('opacity', '1');
      elements.childs.node.children[0].unpauseAnimations();
      // elements.childs.find('animate, animateMotion, animateTransform').each(elem => {
      //   elem.attr({ repeatCount: 'indefinite' });
      // });

      // show person and info icon
      if (elements.info) elements.info.animate(1000).attr({ opacity: 1 });
      if (elements.person) elements.person.animate(1000).attr({ opacity: 1 });

      state.isBusy = false;
    }

    const that = this;
    if (state.isSVGEventsDisable) {
      const duration = teleport.find('animate')[0].attr('dur').slice(0,-1);
      setTimeout(async () => {
        await changePoint(that);

        const duration2 = teleport.find('animate')[0].attr('dur').slice(0,-1);
        setTimeout(() => {
          elements.childs.find('animateMotion')[0].node.removeEventListener('repeatEvent', this.pointTransformations);
          changePointAfter();
        }, duration2 * 1000 - 100);
      }, duration * 1000 - options.delayDiff);
    } else {
      teleport.find('animate')[0].node.addEventListener('endEvent', async () => {
        console.log('start teleport end')
        await changePoint(that);

        teleport.find('animate')[0].node.addEventListener('endEvent', () => {
          console.log('end teleport end')
          changePointAfter();
        }, false);
      }, false);
    }
  };

  this.addGates = async function() {
    if (elements.draw) {
      const gates = elements.draw.find('#gates');
      if (!gates) return null;
      const gatesAnimation = await this.appendElem(`/images/game/gates/gates_L.svg`, [0, 0], 'gates', true);
      this.moveGates(gates, gatesAnimation);
      // setTimeout(() => {
      //   gatesAnimation.node.children[0].pauseAnimations();
      // }, 0);

      return gatesAnimation;
    }
  };

  this.addGatesAnimation = async function() {
    const gates = elements.draw.find('#gates');
    if (!gates) return null;

    const gatesAnimation = await this.appendElem(`/images/game/gates/gates_L_open.svg`, [0, 0], 'gates', true);
    this.moveGates(gates, gatesAnimation);

    return gatesAnimation;
  };

  this.moveGates = function(gates, gatesAnimation) {
    const child = gates.children()[0]
    const width = child.width()[0];
    const height = child.height()[0];
    const x = child.x()[0];
    const y = child.y()[0];
    gatesAnimation.children().size(width.toFixed(3), height.toFixed(3)).move(x, y);

    gates.after(gatesAnimation);
    // gatesAnimation.show();
    gates.remove();

    gatesAnimation.on('click', e => this.onClickGates(gates));
  };

  this.onClickGates = async function(gates) {
    if (state.isBusy) return null;

    gates.off('click');

    const res = await options.changeCity();
    if (res) {
      gates = await this.addGatesAnimation();
      gates.off('click');

      const city_out_animation = await axios.get(res.city_out_animation);

      function addCityOut(res, city_out_animation) {
        if (city_out_animation) {
          options.animationContainer.innerHTML = city_out_animation.data;
          options.animationContainer.style.opacity = 1;
          options.animationContainer.style.visibility = 'visible';

          setTimeout(() => options.onCityOut(res.new_point_uid), 3000);
        }
      };

      if (state.isSVGEventsDisable) {
        const duration = gates.find('animate')[0].attr('dur').slice(0,-1);
        setTimeout(() => {
          addCityOut(res, city_out_animation);
        }, duration * 1000 - options.delayDiff);
      } else {
        gates.find('animate')[0].node.addEventListener('endEvent', () => {
          addCityOut(res, city_out_animation);
        });
      }
    }
  };

  this.setActiveIsland = async function(speed = 0, delay = 0) {
    if (!state.currentPoint) return null;

    const currentPoint = options.game.points[state.currentPoint];

    if (Number(currentPoint.path_number) !== 0) this.hideClouds(3000);
    const index = currentPoint.path_number;

    if (index > 0) {
      // set images for previous and current islands
      for (let i=index; i>0; i--) {
        const island = elements.draw.find(`#island_${i}`);
        if (island) island.animate(speed, delay).attr({ opacity: 0 }).css({ display: 'none' });

        const islandActive = elements.draw.find(`#island_${i}_active`);
        if (islandActive) islandActive.css({ display: 'block' }).animate(speed, delay).attr({ opacity: 1 });

        const way = elements.draw.find(`#way_${i}`);
        if (way) way.animate(speed, delay).attr({ opacity: 0 }).css({ display: 'none' });

        const wayActive = elements.draw.find(`#way_${i}_active`);
        if (wayActive) wayActive.css({ display: 'block' }).animate(speed, delay).attr({ opacity: 1 });

        const stars = elements.draw.find(`#stars_${i}`);
        if (stars) stars.animate(speed, delay).attr({ opacity: 0 }).css({ display: 'none' });
      }
    }

    if (!elements.childs) elements.childs = await this.appendElem(`/images/game/Kids_idle.svg`, [70, 70], 'childs');
    if (!elements.person) elements.person = await this.appendElem(`/images/game/legends/legends_${options.game.city.uid}_idle.svg`, [70, 70], 'person');

    const island = elements.draw.find(`#island_${index}_active`);
    if (island) {
      if (elements.childs) this.moveElem(elements.childs, island, currentPoint.orientation, [9, -36], [9, -36]);

      setTimeout(() => {
        if (elements.person) this.moveElem(elements.person, island, currentPoint.orientation, [-60, -39], [90, -39]);
        setTimeout(() => this.selectAction(), 500);
      }, 100);
    }

    const mustCheckPointIndex = Object.values(options.game.points).findIndex(({ must_check }) => !!must_check);
    const mustCheckPoint = elements.draw.find(`#island_${mustCheckPointIndex}`);

    if (mustCheckPoint) {
      const group = mustCheckPoint;

      if (group && group.length) {
        if (elements.pointHighlight) elements.pointHighlight.remove();

        const width = group.width()[0] + 20*2;
        const height = group.height()[0] + 19*2;

        elements.pointHighlight = await this.appendElem(`/images/game/island_active_bottum.svg`, [width, height], 'point_highlight');

        if (elements.pointHighlight) {
          this.moveElem(elements.pointHighlight, group, 'L', [-20, -22], [-20, -22]);
          mustCheckPoint.put(elements.pointHighlight);
          elements.pointHighlight.back();
        }

        if (elements.pointHand) elements.pointHand.remove();
        elements.pointHand = await this.appendElem(`/images/game/hand_move.svg`, [60, 60], 'point_hand');
        if (elements.pointHand) {
          this.moveElem(elements.pointHand, group, 'L', [10, -55], [10, -55]);
          elements.pointHand.click(e => this.onClickIsland(elements.pointHand));
        }

      }

      mustCheckPoint.click(e => this.onClickIsland(mustCheckPoint));
    }
  };

  this.addPointInfo = async function(currentPoint, hightligt) {
    elements.person.off('click');
    let infoParams = null;

    if (elements.info) {
      infoParams = elements.info.attr();
      elements.info.remove();
    }

    if (
      elements.person
      && options.game.dialogs
      && options.game.dialogs[currentPoint.uid]
    ) {
      const pointDialogs = options.game.dialogs[currentPoint.uid];

      if (pointDialogs.monolog && Object.keys(pointDialogs.monolog).length) {
        const monolog = pointDialogs.monolog;

        if (monolog && monolog.length) {
          // let icon = hightligt ? `/images/game/person_info_orange.svg` : `/images/game/person_info.svg`;
          // if (currentPoint.legend_icon && currentPoint.legend_icon === 2)
          //   icon = hightligt ? `/images/game/person_build_orange.svg` : `/images/game/person_build.svg`;
          // elements.info = await this.appendElem(icon, [56, 56], 'info');

          elements.info = await this.appendElem('/images/game/hand_move.svg', [60, 60], 'info');
          if (elements.info) {
            elements.info.attr(infoParams);
            this.moveElem(elements.info, elements.person, currentPoint.orientation, [-7, -65], [-35, -65]);
            // this.moveElem(elements.info, elements.person, currentPoint.orientation, [4, -54], [-8, -54]);
            elements.info.on('click', () => this.onClickInfo(monolog, currentPoint));
            elements.person.on('click', () => this.onClickInfo(monolog, currentPoint));
            elements.childs.on('click', () => this.onClickInfo(monolog, currentPoint));
          }
        }
      }
    }
  };

  this.onClickInfo = function(monolog, currentPoint) {
    elements.info.remove();

    this.addMonolog(
      elements.person, monolog, currentPoint, 0,
      () => {
        this.removeOverlay();
        this.selectAction();
        // this.addPointInfo(currentPoint);
      }, () => {
        this.removeOverlay();
        this.selectAction();
        // this.addPointInfo(currentPoint);
      }
    );
  };

  this.selectHeaderMessage = function(dialogs) {
    if (dialogs.mess_reaction && dialogs.mess_reaction.text) {
      let persons = [];
      if (dialogs.mess_reaction.left_person) persons.push(dialogs.mess_reaction.left_person);
      if (dialogs.mess_reaction.right_person) persons.push(dialogs.mess_reaction.right_person);
      this.highlightHeaderButton('game_chat');
      this.createHeaderMessage(dialogs.mess_reaction.text, persons);
    } else if (dialogs.dialog && dialogs.dialog.length) {
      this.highlightHeaderButton('game_chat');
      this.createHeaderMessage('Нам нужно с тобой поговорить!', [ '/images/game/timur-wow.svg', '/images/game/alisa-wow.svg' ]);
    }
  };

  this.addMonolog = function(person, monolog, currentPoint, index, exitFunction, closeFunction) {
    const button = index >= monolog.length-1 ? 'Завершить' : 'Продолжить';
    const { pers_type, map_action } = monolog[index];
    this.removeOverlay();

    if (map_action) this.addOverlay(map_action);

    if (pers_type && pers_type === 4) {
      this.createMessage(
        '/images/game/legends/Virus_head.svg',
        monolog[index],
        button,
        'danger',
        () => {
          if (index < monolog.length-1) {
            setTimeout(() => this.addMonolog(person, monolog, currentPoint, index+1, exitFunction, closeFunction), 0);
          } else {
            exitFunction();
          }
        },
        closeFunction
      );
    } else {
      this.createMessage(
        // `/images/game/legends/legends_${options.game.city.uid}_head.svg`,
        monolog[index].avatar,
        monolog[index],
        button,
        'default',
        () => {
          if (index < monolog.length-1) {
            setTimeout(() => this.addMonolog(person, monolog, currentPoint, index+1, exitFunction, closeFunction), 0);
          } else {
            exitFunction();
          }
        },
        closeFunction
      );
    }
  };

  this.addOverlay = function(url) {
    elements.overlay = elements.draw.foreignObject(elements.draw.width(), elements.draw.height());
    elements.overlay.move(0, 0);
    elements.overlay.node.innerHTML = `<img src="${url}" style="width: 100%; height: 100%" id="overlay" />`;
  };

  this.removeOverlay = function() {
    if (elements.overlay) elements.overlay.remove();
  };

  this.appendElem = async function(url, size, id, stopRepeat) {
    const res = await axios.get(url);
    if (res.data) {
      const group = elements.draw.group();
      // group.hide();

      let data = res.data;

      const unique = Math.random().toString(16).slice(2);
      const ids = data.match(/id="[a-zA-Z0-9_]*"/gm);
      if (ids && ids.length) {
        ids.map(item => {
          const id = item.replace('id="', '').replace('"', '');
          data = data.replaceAll(`${id}'`, `${id}_${unique}'`);
          data = data.replaceAll(`${id}"`, `${id}_${unique}"`);
          data = data.replaceAll(`${id})`, `${id}_${unique})`);

          return null;
        });
      }

      data = data.replaceAll('NaN', '0'); // fix NaN attributes

      if (stopRepeat) {
        data = data.replaceAll('indefinite', '1');
      }

      group.id(id);
      group.put(data).size(size[0], size[1]);

      // if (stopRepeat) {
      //   group.find('animate, animateMotion, animateTransform').each(elem => {
      //     const dur = elem.attr('dur');
      //     elem.attr({ end: dur });
      //     elem.attr({ repeatCount: '1' });
      //   });
      // }

      return group;
    };
  };

  this.moveElem = function(elem, targetElem, orientation, shift = [0, 0], inverceShift = [0, 0]) {
    elem.attr('transform', '');

    const svg = elem.children()[0];
    const targetElemX = typeof(targetElem.x()) === 'object' ? targetElem.x()[0] : targetElem.x();
    const targetElemY = typeof(targetElem.y()) === 'object' ? targetElem.y()[0] : targetElem.y();

    const x = orientation === 'R' ? (targetElemX + inverceShift[0]) : (targetElemX + shift[0]);
    const y = orientation === 'R' ? (targetElemY + inverceShift[1]) : (targetElemY + shift[1]);

    svg.move(x.toFixed(3), y.toFixed(3));

    // elem.css({ 'transform': 'none' });
    if (orientation === 'R') elem.flip('x');

    // elem.show();
  };

  this.hideClouds = function(speed = 0) {
    const clouds = elements.draw.find('[id^=clouds_]');

    clouds.each(elem => {
      const id = elem.attr('id');

      if (id != null) {
        if (id.match(/^clouds_top_top*/i))
          elem.animate(speed).move(0, -300).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_top_left*/i))
          elem.animate(speed).move(-300, -300).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_top_right*/i))
          elem.animate(speed).move(300, -300).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_left*/i))
          elem.animate(speed).move(-300, 0).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_right*/i))
          elem.animate(speed).move(300, 0).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_bottom_left*/i))
          elem.animate(speed).move(-300, 300).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_bottom_right*/i))
          elem.animate(speed).move(300, 300).animate().attr({ opacity: 0 });

        if (id.match(/^clouds_bottom_bottom*/i))
          elem.animate(speed).move(0, 300).animate().attr({ opacity: 0 });
      }
    });
  };

  this.createMessage = function(person, content, button, type, exitFunction, closeFunction) {
    if (state.isMonologOpen) return null;
    state.isMonologOpen = true;

    options.addMonolog({
      type, content, button, person,
      onExit: () => {
        state.isMonologOpen = false;
        exitFunction && exitFunction();
      },
      onClose: () => {
        state.isMonologOpen = false;
        closeFunction();
      }
    });
  };

  this.createHeaderMessage = function(content, icons) {
    options.addHeaderMessage({
      content,
      icons,
      position: 'right',
      onClose: () => {
        this.unhighlightHeaderButton('game_chat');
        this.selectAction();
      }
    });
  };

  this.highlightHeaderButton = function(id) {
    document.getElementById(id).classList.add('highlight');
  };

  this.unhighlightHeaderButton = function(id) {
    document.getElementById(id).classList.remove('highlight');
  };

  this.addComics = (currentPoint) => {
    options.addComics({
      items: currentPoint.comics.map(({ audio, url }) => ({ audio, src: url })),
      onClose: () => {
        this.selectAction();
      }
    });
  };

  this.addChest = async function(rewards) {
    this.addOverlay('/images/game/white_bg.png');
    const chestAppend = await this.appendElem(`/images/game/chests/chest-append-${rewards.chest}.svg`, [390, 520], `chest`, true);
    if (chestAppend) {
      chestAppend.move(0, 0);
      // chestAppend.show();

      async function addChestIdle(that) {
        const chestIdle = await that.appendElem(`/images/game/chests/chest-idle-${rewards.chest}.svg`, [390, 520], `chest`);
        chestIdle.move(0, 0);
        chestAppend.remove();
        // chestIdle.show();

        chestIdle.click(async () => {
          chestIdle.off('click');

          const chestOpen = await that.appendElem(`/images/game/chests/chest-open-${rewards.chest}.svg`, [390, 520], `chest`, true);
          chestOpen.move(0, 0);
          chestIdle.remove();
          // chestOpen.show();

          if (state.isSVGEventsDisable) {
            const duration = chestOpen.find('animate')[0].attr('dur').slice(0,-1);
            setTimeout(async () => {
              that.addRewards(rewards);
              chestOpen.remove();
            }, duration * 1000 - options.delayDiff);
          } else {
            chestOpen.find('animate')[0].node.addEventListener('endEvent', async (e) => {
              that.addRewards(rewards);
              chestOpen.remove();
            });
          }
        });
      };

      const that = this;
      if (state.isSVGEventsDisable) {
        const duration = chestAppend.find('animate')[0].attr('dur').slice(0,-1);
        setTimeout(async () => {
          await addChestIdle(that);
        }, duration * 1000 - options.delayDiff);
      } else {
        chestAppend.find('animate')[0].node.addEventListener('endEvent', async (e) => {
          await addChestIdle(that);
        });
      }
    }
  };

  this.addRewards = async function(rewards) {
    rewards = Object.entries(rewards).reduce((prev, current) =>
      ((current[0] !== 'chest' && Object.keys(current[1]).length) ? [ ...prev, { id: current[0], ...current[1] } ] : prev), []);
    const layout = rewardLayouts[rewards.length-1];

    for (const index in rewards) {
      const { id, type_name, value } = rewards[index];
      let name = value && value.name ? value.name : type_name;
      let image = value && value.url ? value.url : '';
      if (id === 'crystall') {
        name = name + ' ' + value;
        image = '/images/game/rewards/crystalls.svg';
      } else if (id === 'mentor') {
        image = '/images/game/rewards/mentor.svg';
      }

      // todo: разбить строку на подстроки
      // const content = name.match(/.{18}/g);
      const content = [ name ];

      const { width, height, top, left } = layout[index];
      // const bg = index > 0 ? '/images/game/reward_simple.svg' : '/images/game/reward.svg';
      const bg = '/images/game/reward_simple.svg';
      const reward = await this.addReward(bg, image, content, [width, height], [top, left], index);
      if (reward) {
        reward.click(e => this.onClickReward(reward));
      }
    }
  };

  this.addReward = async function(bg, image, content, size, position, index) {
    const reward = await this.appendElem(bg, [0, 0], `reward_${index}`);

    if (reward) {
      // reward.show();
      const svg = reward.children()[0];
      const rect = svg.find('rect');

      if (rect[0]) {
        const rectX = rect[0].x();
        const rectY = rect[0].y();
        const rectWidth = rect[0].width();
        const rectHeight = rect[0].height();

        const text = svg.text(function(add) {
          content.forEach(function(row) { add.tspan(row).fill('#AE6541').newLine() });
        });

        text
          .move(svg.viewbox().width / 2, rectY + rectHeight - 18 - 33)
          .font({ family: 'Roboto', size: 14, anchor: 'middle', 'alignment-baseline': 'middle', weight: 700 });

        const rewardIcon = svg.foreignObject(rectWidth-84, rectHeight-84);
        rewardIcon.node.innerHTML = `<img src="${image}" style="width: 100%; height: 100%" />`;
        rewardIcon.move(rectX + 42, rectY + 24);

        svg.center(position[1], position[0]);
        svg.animate(500).attr({ width: size[0], height: size[1] }).center(position[1], position[0]);

        return reward;
      }
    }

    return null;
  };

  this.onClickReward = async function(reward) {
    reward.off('click');
    reward.find('rect').animate(300).attr({ opacity: 0 });
    reward.find('text').animate(300).attr({ opacity: 0 });
    reward.children()[0].animate(700, 300).attr({ width: 0, height: 0 }).move(315, 0)

    setTimeout(() => {
      reward.remove();

      const rewards = elements.draw.find('[id^=reward_]');
      const layout = rewardLayouts[rewards.length-1];

      rewards.each(function(item, index) {
        const { width, height, top, left } = layout[index];
        const svg = item.children()[0];
        svg.animate(500).attr({ width: width, height: height }).center(left, top);
      });

      if (rewards.length === 0) {
        this.removeOverlay();
        this.selectAction();
      }

      if (state.backpackTimeout) clearTimeout(state.backpackTimeout);

      document.getElementById('game_backpack').classList.add('highlight');

      state.backpackTimeout = setTimeout(() => {
        document.getElementById('game_backpack').classList.remove('highlight');
      }, 3000);
    }, 1000);
  };

  this.createCache = function() {
    axios.get('/images/game/person_info_orange.svg')
    axios.get('/images/game/person_build_orange.svg')
    axios.get('/images/game/person_info.svg')
    axios.get('/images/game/person_build.svg')

    axios.get('/images/game/message_tick.svg')
    axios.get('/images/game/message_tick_danger.svg')
    axios.get('/images/game/message_tick_edge.svg')

    axios.get('/images/game/timur-wow.svg')
    axios.get('/images/game/alisa-wow.svg')
    axios.get('/images/game/kids_teleport.svg')
    axios.get('/images/game/island-transformation.svg')
    axios.get('/images/game/reward.svg')
    axios.get('/images/game/reward_simple.svg')

    axios.get('/images/game/legends/Virus_idle.svg')
    axios.get('/images/game/legends/Virus_head.svg')

    axios.get(`/images/game/legends/legends_${options.game.city.uid}_head.svg`)

    axios.get('/images/train.svg')
    axios.get('/images/bus.svg')
    axios.get('/images/airplane.svg')
  };

  this.init();
};
