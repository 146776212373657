import { useSelector } from 'react-redux';
import Audio from 'components/Audio';
import { selectItem } from 'store/app/getters';

import styles from './quests.module.css';

const QuestDescription = ({ user, quest }) => {
  const mobile_visible_video = useSelector(selectItem(['settings', 'mobile_visible_video']));
  const mobile_visible_images = useSelector(selectItem(['settings', 'mobile_visible_images']));

  if (!quest) return null;

  const { audio, stater, description, ender, video, picture } = quest.info ? quest.info : quest;
  const { need } = quest;

  return (
    <div>
      {audio &&
        <div className={styles.audio}>
          <Audio src={audio} />
        </div>
      }

      {stater && <div dangerouslySetInnerHTML={{ __html: stater }} />}

      <p dangerouslySetInnerHTML={{ __html: description }} />

      {ender && <div dangerouslySetInnerHTML={{ __html: ender }} />}

      {(need && need.length) ? (
        <>
          <h3 className={styles.listTitle}>Потребуется</h3>
          <ul className={styles.list}>
            {need.map((item, index) => (
              <li className={styles.listItem} key={index}>{item}</li>
            ))}
          </ul>
        </>
      ) : null}

      {(picture && mobile_visible_images) &&
        <img src={picture} className={styles.questImage} alt="" />
      }

      {(video && mobile_visible_video) &&
        <iframe
          className={styles.questVideo}
          width="100%"
          height="214px"
          src={video.replace(/(?:https:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?.*v=)?(\w+)/g, 'https://www.youtube.com/embed/$1')}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
          allowFullScreen
        ></iframe>
      }
    </div>
  );
};

export default QuestDescription;
