import { useEffect, useState } from 'react';
import Select from 'components/fields/Select';
import Switcher from 'components/fields/Switcher';

import styles from './schedule-day.module.css';

const ScheduleDay = ({ id, name, time, timeOptionsFrom, timeOptionsTo, onChange }) => {
  const [ startTime, setStartTime ] = useState((time && time.start) ? time.start.slice(0, 5) : '13:00');
  const [ endTime, setEndTime ] = useState((time && time.end) ? time.end.slice(0, 5) : '20:00');
  const [ checked, setChecked ] = useState(!!(time && time.uid));
  const [ optionsFrom, setOptionsFrom ] = useState(timeOptionsFrom);
  const [ optionsTo, setOptionsTo ] = useState(timeOptionsTo);

  useEffect(() => {
    if (time) {
      if (time.start) setStartTime(time.start.slice(0, 5));
      if (time.end) setEndTime(time.end.slice(0, 5));
    }
    if (!!(time && time.uid)) setChecked(true);
    else setChecked(false);
  }, [time]);

  useEffect(() => {
    setOptionsFrom(prev => prev.map(option => {
      return { ...option, disabled: endTime && (Number(option.value.replace(':', '')) >= Number(endTime.replace(':', ''))) }
    }));
    setOptionsTo(prev => prev.map(option => {
      return { ...option, disabled: startTime && (Number(option.value.replace(':', '')) <= Number(startTime.replace(':', ''))) }
    }));
  }, [startTime, endTime]);

  const handleChangeTime = (type, time) => {
    setChecked(true);

    if (type === 'start') {
      setStartTime(time);
      if (endTime !== '') onChange(id, time.uid, true, time, endTime);
    } else {
      setEndTime(time);
      if (startTime !== '') onChange(id, time.uid, true, startTime, time);
    }
  };

  const handleChangeChecked = (checked) => {
    setChecked(checked);
    if (startTime && endTime) onChange(id, time ? time.uid : null, checked, startTime, endTime);
  };

  return (
    <div className={styles.root}>
      <div className={styles.heading}>
        <div className={styles.title}>{name}</div>
        <Switcher
          name={`day_${id}`}
          checked={checked}
          containerClassName={styles.checkbox}
          onChange={e => handleChangeChecked(e.target.checked)}
        />
      </div>
      <div className={styles.inputs}>
        <Select
          modalTitle="Установить время"
          id={`day_start_${id}`}
          name={`day_start_${id}`}
          placeholder="С 13:00"
          options={optionsFrom}
          className={styles.select}
          iconClassName={styles.selectArrow}
          onChange={e => handleChangeTime('start', e.target.value)}
          value={startTime}
        />
        <Select
          modalTitle="Установить время"
          id={`day_end_${id}`}
          name={`day_end_${id}`}
          placeholder="До 18:00"
          options={optionsTo}
          className={styles.select}
          iconClassName={styles.selectArrow}
          onChange={e => handleChangeTime('end', e.target.value)}
          value={endTime}
        />
      </div>
    </div>
  );
};

export default ScheduleDay;