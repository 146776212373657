import Checkbox from 'components/fields/Checkbox';

import styles from './reward-checkbox.module.css';

const RewardCheckbox = ({ uid, name, selected, description, onChange, checked }) => {
  return (
    <div className={styles.row} key={uid}>
      <Checkbox
        id={uid}
        name={uid}
        label={<>{name} {description && <div className={styles.selected}>{description}</div>}</>}
        checked={checked || !!selected}
        onChange={e => onChange(uid, name, e.target.checked, selected)}
      />
    </div>
  );
};

export default RewardCheckbox;
