import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import HomeworkSchedule from 'components/homework/HomeworkSchedule';
import { MainLayout } from 'components/Layouts';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';
import { setItem, pushItem } from 'store/app/actions';
import * as api from 'helpers/api';

import styles from './Homework.module.css';

const HomeworkSchedulePage = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const [ tmpGmt, setTmpGmt ] = useState(null);

  const { data: homework } = useApiQuery({
    name: ['homework_all', currentPlayer.uid_gamer],
    path: `/api/getuserdomashka/${currentPlayer.uid_gamer}/all/`,
    skip: !user || !currentPlayer
  });

  const handleChangeSchedule = (day, time_uid, checked, gmt, start, end) => {
    const fd = new FormData();
    fd.append('user_uid', currentPlayer.uid_gamer);
    fd.append('day', day);
    fd.append('gmt_mark', tmpGmt ? tmpGmt : gmt);
    fd.append('start', start);
    fd.append('end', end);
    if (time_uid) fd.append('uid', time_uid);
    if (!checked) fd.append('delete', 1);

    api
      .post(`/getuserdomashka/`, fd)
      .then(response => {
        const result = response.data.response;

        if (result) {
          dispatch(setItem(['homework_all', currentPlayer.uid_gamer], result, { isHard: true }));
          dispatch(setItem(['notifications', 'updateSchedule'], { type: 'success', content: 'Сохранено' }));
        }
        if (response.data.error) {
          dispatch(setItem(['notifications', 'updateSchedule'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });

    if (tmpGmt) handleChangeGmt(tmpGmt);
    if (day === '1' && checked) {
      if (!(homework.main[2].time && homework.main[2].time.uid)) handleChangeSchedule(2, null, true, gmt, start, end);
      if (!(homework.main[3].time && homework.main[3].time.uid)) handleChangeSchedule(3, null, true, gmt, start, end);
      if (!(homework.main[4].time && homework.main[4].time.uid)) handleChangeSchedule(4, null, true, gmt, start, end);
      if (!(homework.main[5].time && homework.main[5].time.uid)) handleChangeSchedule(5, null, true, gmt, start, end);
      if (!(homework.main[6].time && homework.main[6].time.uid)) handleChangeSchedule(6, null, true, gmt, start, end);
    }
  };

  const handleChangeGmt = (value) => {
    if (homework && homework.main && Object.values(homework.main).findIndex(({ time }) => (time && Object.values(time).length)) !== -1) {
      const fd = new FormData();
      fd.append('user_uid', currentPlayer.uid_gamer);
      fd.append('gmt_update', value);

      api
        .post(`/getuserdomashka/`, fd)
        .then(response => {
          const result = response.data.response;

          if (result) {
            dispatch(setItem(['homework_all', currentPlayer.uid_gamer], result, { isHard: true }));
            dispatch(setItem(['notifications', 'updateSchedule'], { type: 'success', content: 'Сохранено' }));
          }
          if (response.data.error) {
            dispatch(setItem(['notifications', 'updateSchedule'], { type: 'error', content: response.data.error }));
          }
        })
        .catch(error => {
          dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
        });

      setTmpGmt(null);
    } else {
      setTmpGmt(value);
    }
  };

  const handleClickNext = () => {
    dispatch(pushItem(['onboarding'], {
      key: 'rewards',
      image: <img src="/images/onboarding/rewards.svg?v=2" alt="" />,
      style: {
        top: '70px',
        right: '0px',
        left: 'auto',
        bottom: 'auto'
      },
    }));
  };

  return (
    <MainLayout title="Школьная домашка" subtitle="Выберите периоды времени для ДЗ">
      {homework && (
        <HomeworkSchedule
          gmt={tmpGmt || homework.gmt}
          cities={homework.city}
          items={homework.main}
          onChangeGmt={handleChangeGmt}
          onChange={handleChangeSchedule}
        />
      )}

      {(state?.onboarding && homework?.main && Object.values(homework.main).find(({ time }) => !!time.uid)) && (
        <div className={styles.onboardButtons}>
          <ButtonsContainer className="container">
            <Button
              butStyle="secondary"
              to="/select-success"
            >
              Назад
            </Button>
            <Button
              butStyle="primary"
              to="/update-rewards"
              state={{ onboarding: true }}
              onClick={handleClickNext}
            >
              Далее
            </Button>
          </ButtonsContainer>
          </div>
      )}
    </MainLayout>
  );
};

export default HomeworkSchedulePage;
