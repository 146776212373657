import { Link } from 'react-router-dom';
import classNames from 'classnames';
import HomeworkItem from './HomeworkItem';

import styles from './homework.module.css';

const HomeworkPlaceholder = ({ info, schedule, isSetted }) => {
  return (
    <div
      className={classNames(styles.root, !schedule && 'skeleton-loader')}
      style={{
        backgroundImage: schedule ? 'url(/images/homework_bg.svg?v=1)' : null,
        backgroundSize: 'cover',
        backgroundColor: 'var(--color-white-primary)',
        backgroundPosition: 'center center'
      }}
    >
      <Link to={info ? `/homework/${info.date}` : '/'} className={styles.link} />
      {schedule && (
        !isSetted ? (
          <>
            <div className={styles.text}>
              Попроси родителя настроить “Домашку” чтобы получать баллы за выполнение школьных заданий
            </div>
          </>
        ) : (
          <div className={styles.text}>На сегодня домашних заданий нет</div>
        )
      )}
    </div>
  );
};

const HomeworkPlayer = ({ user, schedule, info, tasks, settings }) => {
  const isSetted = schedule && Object.values(schedule).findIndex(({ time }) => !!(time && time.uid)) !== -1;

  if (!isSetted || !info) return (
    <HomeworkPlaceholder info={info} schedule={schedule} isSetted={isSetted} />
  );

  return (
    <div>
      {tasks.map(({ type, type_name, is_done, is_complete, is_overdue, is_rejected, oper_complete, now_status, start, end, reward, on_work }, index) => {
        const { gender } = user;

        let title = `Ты ${gender === 'M' ? 'должен' : 'должна'} выполнить ${type_name} ${start && `с ${start.split(' ')[1].slice(0, 5)}`} ${end && `до ${end.split(' ')[1].slice(0, 5)}`}`,
          notice = now_status,
          noticeType = is_overdue && 'overdue',
          buttonText = null;

        if (is_done) {
          title = `${type_name} ${type === 1 ? 'выполнен' : 'выполнена'}`;
          if (new Date(is_done.replace(' ', 'T')) > new Date(end.replace(' ', 'T'))) {
            notice = `За это ты получаешь ${settings?.domashka_small_crystall} <img src="/images/crystal_2.svg" alt="" />`;
          } else {
            notice = `За это ты получаешь ${settings?.domashka_std_crystall} <img src="/images/crystal_2.svg" alt="" />`;
          }
          noticeType = 'success';
        } else if (oper_complete) {
          title = `Задание проверено, ждем подтверждения от Взрослого!`;
        } else if (is_rejected) {
          title = `${type_name} нужно исправить`;
          buttonText = 'Исправить'
        } else if (on_work && on_work.worked_time) {
          title = `${type_name} ${type === 1 ? 'отправлен' : 'отправлена'} на проверку`;
        } else if (is_complete) {
          title = `${type_name} ${type === 1 ? 'отправлен' : 'отправлена'} на проверку`;
        }

        return (
          <HomeworkItem
            index={index}
            info={info}
            type={type}
            title={title}
            notice={notice}
            noticeType={noticeType}
            buttonText={buttonText}
            key={type}
          />
        )
      })}
    </div>
  );
};

export default HomeworkPlayer;
