import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import Facts from 'components/Facts';
import { selectItem } from 'store/app/getters';
import useApiQuery from 'hooks/useApiQuery';

const FactsPage = () => {
  const user = useSelector(selectItem(['user']));

  const { data: day_phrase } = useApiQuery({
    name: ['day_phrase', user.uid],
    path: `/getdayphrase/${user.uid}/all/`,
    save_history: true,
    skip: !user || !user.uid
  });

  return (
    <MainLayout title="Факт дня" infoSlug="statistic">
      <Facts type="P" items={day_phrase} />
    </MainLayout>
  );
}

export default FactsPage;
