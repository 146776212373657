import { useSelector } from 'react-redux';
import useApiQuery from '../../hooks/useApiQuery';
import { MainLayout } from '../../components/Layouts';
import Quests from '../../components/quests/QuestsList';
import { selectItem } from '../../store/app/getters';

const QuestsList = () => {
  const user = useSelector(selectItem(['user']));

  const { data: quests } = useApiQuery({
    name: ['quests'],
    path: `/getQuestAll/?rank_max=${user.lvl}&random=1`, // fixme - добавить параметры
  });

  return (
    <MainLayout title="Каталог квестов">
      {quests &&
        <Quests quests={quests} />
      }
    </MainLayout>
  );
}

export default QuestsList;
