import { Link } from 'react-router-dom';
import classnames from 'classnames';
import NoItems from 'components/NoItems';
import { format } from 'date-fns';

import styles from './homework-list.module.css';

import { homeworks_bgs } from 'data/mock';
import { useSelector } from 'react-redux';
import { selectItem } from 'store/app/getters';

const Item = ({ index, date, is_done, end, now_status, reward, rewardSmall }) => {
  const cid = index+'';
  const bg = homeworks_bgs[cid.slice(-1)];

  let now_reward = '';
  if (is_done) {
    if (new Date(is_done.replace(' ', 'T')) > new Date(end.replace(' ', 'T')))
      now_reward = `Получено <span>${rewardSmall} <img src="/images/crystal_2.svg" alt="" /></span>`;
    else
      now_reward = `Получено <span>${reward} <img src="/images/crystal_2.svg" alt="" /></span>`
  } else {
    if (end && new Date() > new Date(end.replace(' ', 'T')))
      now_reward = `Будет получено <span>${rewardSmall} <img src="/images/crystal_2.svg" alt="" /></span>`;
    else
      now_reward = `Будет получено <span>${reward} <img src="/images/crystal_2.svg" alt="" /></span>`
  }

  if (!date) return (
    <div className={classnames(styles.item, 'skeleton-loader')} />
  );

  return (
    <Link to={`/homework/${date}`} className={styles.item} style={{ backgroundImage: `url(${bg})` }}>
      <div className={styles.date}>
        {format(new Date(date), 'dd.MM.yyyy')}
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Статус:</div>
        <div className={styles.value}>{now_status}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.label}>Награда:</div>
        <div className={styles.value} dangerouslySetInnerHTML={{ __html: now_reward }} />
      </div>
    </Link>
  );
};

const HomeworkList = ({ items = [1, 2, 3, 4, 5], emptyText }) => {
  const settings = useSelector(selectItem(['settings']));

  if (!Object.keys(items).length) return ( // no items
    <NoItems children={emptyText} />
  );

  return (
    <div className={styles.list}>
      {Object.keys(items).sort((a, b) => new Date(b) - new Date(a)).map((key, index) => {
        const { info, tasks } = items[key];
        return (
          <Item
            {...info}
            tasks={tasks}
            index={index}
            reward={settings?.domashka_std_crystall}
            rewardSmall={settings?.domashka_small_crystall}
            key={index}
          />
        );
      })}
    </div>
  );
};

export default HomeworkList;
