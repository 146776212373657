import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
import { backpack_items } from 'data/mock';

import styles from './about-blocks.module.css';

const AboutBlocks = () => {
  const dispatch = useDispatch();
  const visibleSettings = useSelector(selectItem(['visible_settings']));

  const handleClickItem = (slug) => {
    if (slug === 'about_feedback') dispatch(setItem(['common', 'feedback_popup', 'open'], true));
    else dispatch(setItem(['common', 'faq'], slug));
  };

  return (
    <div className={styles.list}>
      {backpack_items.map(({ id, name, slug, href, image, color, disabled }, index) => {
        if (id === 'bp_gifts' && !visibleSettings?.a_skillary_gifts_block) return null;
        if (id === 'bp_artefacts' && !visibleSettings?.a_game_artefacts_block) return null;
        if (slug) return (
          <button
            className={classNames(styles.item, disabled && styles.disabled)}
            onClick={() => handleClickItem(slug)}
            style={{ color: color, backgroundImage: `url(${image})` }}
            key={index}
          >
            <span className={styles.itemText}>
              {name}
            </span>
          </button>
        );
        return (
          <Link
            className={classNames(styles.item, disabled && styles.disabled)}
            to={href}
            style={{ color: color, backgroundImage: `url(${image})` }}
            key={index}
          >
            <span className={styles.itemText}>
              {name}
            </span>
          </Link>
        );
      })}
    </div>
  );
};

export default AboutBlocks;
