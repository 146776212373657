import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { setItem } from '../../../store/app/actions';
import { faq_items } from '../../../data/mock';
import { selectItem } from '../../../store/app/getters';

import styles from './about.module.css';

const About2 = ({ user }) => {
  const dispatch = useDispatch();
  const mobile_visible_payment = useSelector(selectItem(['settings', 'mobile_visible_payment']));

  if (!user) return null;

  const handleClickItem = (slug) => {
    if (slug === 'about_feedback') dispatch(setItem(['common', 'feedback_popup', 'open'], true));
    else dispatch(setItem(['common', 'faq'], slug));
  };

  const user_type = user.info ? user.info.type : user.type;

  return (
    <div className={classNames(styles.list, 'scroll-list')}>
      {faq_items.map(({ id, name, slug, href, image_mentor, image_child, color3, bg_color3, disabled, show_mentor, show_player }, index) => {
        if (
          (user_type === 'P' && show_player === false)
          || (user_type === 'M' && show_mentor === false)
          || (id === 'pay' && !mobile_visible_payment)
        ) return null;
        const Component = href ? Link : 'div';

        return (
          <Component
            className={classNames(styles.item, disabled && styles.disabled)}
            to={href}
            onClick={() => slug && handleClickItem(slug)}
            style={{ color: color3, backgroundColor: bg_color3, backgroundImage: `url(${user_type === 'M' ? image_mentor : image_child})` }}
            key={index}
          >
          </Component>
        );
      })}
    </div>
  );
};

export default About2;
