import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useApiQuery from 'hooks/useApiQuery';
import { MainLayout } from 'components/Layouts';
import Block from 'components/Block';
import QuestsList from 'components/quests/QuestsList';
import HpStories from 'components/homepage/HpStories';
import Button from 'components/Button';
import { selectItem } from 'store/app/getters';
import NewQuest from 'components/homepage/NewQuest';

import styles from './mentor.module.css';

const UserQuery = ({ uid }) => {
  useApiQuery({
    name: ['user_quests', uid],
    path: `/getUserQuest/${uid}/`,
    skip: !uid,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let questKey in data[key]) {
            const quest = data[key][questKey];
            next[key][quest.uid] = {...quest, position: key};
          }
        }
      }

      return next;
    }
  });

  useApiQuery({
    name: ['user_ambitions', uid],
    path: `/getPlayerAmbitions/${uid}/`,
    skip: !uid,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let ambKey in data[key]) {
            const amb = data[key][ambKey];
            next[key][amb.skill_uid] = amb;
          }
        }
      }

      return next;
    }
  });

  useApiQuery({
    name: ['grants', uid],
    path: `/getstepresult/${uid}/`
  });

  useApiQuery({
    name: ['quest_groups', uid, 'S'],
    path: `/getquestgroup/S/0/${uid}/`
  });

  useApiQuery({
    name: ['rewards_mentor', uid],
    path: `/playerrewardsfrommentor/${uid}/`,
    mapper: (data = {}) => {
      return Object.values(data).reduce((prev, current) => {
        if (current) return ([ ...prev, ...current ]);
        return prev;
      }, []);
    }
  });

  useApiQuery({
    name: ['homework_small_mentor', uid],
    path: `/api/getuserdomashka/${uid}/small/`
  });

  return null;
}

const QuestsPage = () => {
  const navigate = useNavigate();
  const visibleSettings = useSelector(selectItem(['visible_settings']));
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const uid = currentPlayer?.uid_gamer;

  const quests = useSelector(selectItem(['user_quests', uid]));
  const quest_groups = useSelector(selectItem(['quest_groups', uid]));

  useApiQuery({
    name: ['pin_code'],
    path: `/getpincode/${user.uid}/`,
    skip: !user
  });

  useEffect(() => {
    if (visibleSettings && !visibleSettings.a_quest_visible_main) navigate('/');
    // eslint-disable-next-line
  }, [visibleSettings]);

  const sortByComplete = (items) => {
    return items.sort((prev, current) => current.complete_sign ? 1 : -1);
  };

  const showAllOld = (
    <div className={styles.buttonQuests}>
      <Button className={styles.buttonAll} butStyle="text-tertiary" to="/user-quests/#complete">Смотреть все</Button>
    </div>
  );

  const showAllActiveQuests = (
    <div className={styles.buttonQuests}>
      <Button className={styles.buttonAll} butStyle="text-tertiary" to="/user-quests/#active">Смотреть все</Button>
    </div>
  );

  return (
    <MainLayout
      title="Квесты"
      subtitle="Выполняй квесты и получай награды"
      showBackButton={false}
    >
      {(user?.player) ? (
        <>
          {currentPlayer && currentPlayer.uid_gamer && <UserQuery uid={currentPlayer.uid_gamer} />}

          {visibleSettings?.a_quest_viget_active && (
            <Block
              title="Активные квесты"
              rightButton={showAllActiveQuests}
              className={styles.activeQuests}
              id="active_quests"
            >
              <QuestsList
                quests={quests && quests.active && sortByComplete(Object.values(quests.active))}
                type="small"
                direction="row"
                emptyText="Здесь будут квесты, выполнение которых нужно  подтвердить"
                showSign={true}
              />
            </Block>
          )}

          {visibleSettings?.a_quest_viget_roulett && (
            <Block>
              <NewQuest>Посмотрите квесты так, как увидит их ваш ребенок</NewQuest>
            </Block>
          )}

          {visibleSettings?.a_quest_viget_games && (
            <Block title="Игры" infoSlug="games">
              <HpStories items={quest_groups && quest_groups.S} />
            </Block>
          )}

          {visibleSettings?.a_quest_viget_complete && (
            <Block
              title="Пройденные квесты"
              rightButton={showAllOld}
            >
              <QuestsList
                quests={quests && quests.complete && sortByComplete(Object.values(quests.complete))}
                type="small"
                direction="row"
                emptyText="Здесь будут пройденные квесты"
              />
            </Block>
          )}
        </>
      ) : (
        <div className="text-center" style={{ marginTop: '60px' }}>Добавьте минимум 1 ребенка, чтобы он смог выполнять квесты</div>
      )}
    </MainLayout>
  );
}

export default QuestsPage;
