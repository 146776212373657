import Button from 'components/Button';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import HomeworkItem from './HomeworkItem';

import styles from './homework.module.css';

const HomeworkPlaceholder = ({ info, schedule, isSetted }) => {
  if (!schedule) return (
    <div className={classNames(styles.root, 'skeleton-loader')} />
  );

  if (!isSetted) return (
    <>
      <div
        className={classNames(styles.root, !schedule && 'skeleton-loader')}
        style={{
          backgroundColor: 'var(--color-green-primary)',
          backgroundImage: 'url(/images/homework_icon.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom right'
        }}
      >
        <Link to="/homework-update" className={styles.link} />
        <div className={styles.textEmpty}>
          Отслеживайте прогресс выполнения домашних заданий ребенка
        </div>
      </div>
      <div className={styles.bottomButtons}>
        <Button
          to="/homework-update"
          butStyle="primary"
          fullWidth={true}
        >
          Настроить домашку
        </Button>
      </div>
    </>
  );

  return (
    <div
      className={classNames(styles.root, !schedule && 'skeleton-loader')}
      style={{
        backgroundColor: 'var(--color-white-primary)',
        backgroundImage: 'url(/images/homework_bg.svg?v=1)',
        backgroundSize: 'cover',
        backgroundPosition: 'center center'
      }}
    >
      <Link to={info ? `/homework/${info.date}` : '/homework-update'} className={styles.link} />
      <Link className={styles.settings} to='/homework-update'>
        <img src="/icons/icon-settings.svg" className={styles.settingsIcon} alt="" />
      </Link>
      <div className={styles.text}>На сегодня домашних заданий нет</div>
    </div>
  );
};

const HomeworkMentor = ({ user, schedule, info, tasks, settings }) => {
  const isSetted = schedule && Object.values(schedule).findIndex(({ time }) => !!(time && time.uid)) !== -1;

  if (!isSetted || !info) return (
    <HomeworkPlaceholder info={info} schedule={schedule} isSetted={isSetted} />
  );

  return (
    <div>
      {tasks.map(({ type, type_name, is_done, is_complete, is_overdue, is_rejected, oper_complete, now_status, start, end, reward, on_work }, index) => {
        const name = user.current_player.info ? user.current_player.info.name : user.current_player.name;
        const gender = user.current_player.info ? user.current_player.info.gender : user.current_player.gender;
        const type_name_2 = type === 2 ? 'Математику' : type_name;

        let title = `Сегодня ${name} ${gender === 'M' ? 'должен' : 'должна'} выполнить ${type_name_2} ${start && `с ${start.split(' ')[1].slice(0, 5)}`} ${end && `до ${end.split(' ')[1].slice(0, 5)}`}`,
          notice = now_status,
          noticeType = is_overdue && 'overdue',
          buttonText = null;

        if (is_done) {
          title = `${name} выполнил ${type_name_2}!`;
          noticeType = 'success';
          if (new Date(is_done.replace(' ', 'T')) > new Date(end.replace(' ', 'T'))) {
            notice = `За это получено ${settings.domashka_small_crystall} <img src="/images/crystal_2.svg" alt="" />`;
          } else {
            notice = `За это получено ${settings.domashka_std_crystall} <img src="/images/crystal_2.svg" alt="" />`;
          }
        } else if (oper_complete) {
          title = `Задание проверено ${type === 1 ? 'Алисой' : 'Тимуром'}!`;
        } else if (is_rejected) {
          title = `Мы оповестим вас, когда ${name} обновит отчет о выполнении`;
        } else if (on_work && on_work.worked_time) {
          title = `${name} отправил ${type_name_2} на проверку`;
        } else if (is_complete) {
          title = `${name} просит проверить ${type_name_2}`;
          buttonText = 'Проверить'
        }

        return (
          <HomeworkItem
            index={index}
            info={info}
            type={type}
            title={title}
            notice={notice}
            noticeType={noticeType}
            buttonText={buttonText}
            showSettings={true}
            key={type}
          />
        )
      })}
    </div>
  );
};

export default HomeworkMentor;
