import styles from './crystals-balance.module.css';

const CrystalsBalance = ({ crystals, onClick }) => {
  return (
    <div className={styles.balance} onClick={onClick}>
      <img src="/images/crystal.svg" className={styles.balanceIcon} alt="" />
      {crystals} кристаллов
    </div>
  );
};

export default CrystalsBalance;
