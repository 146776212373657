import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectItem } from '../store/app/getters';

const useCache = () => {
  const user = useSelector(selectItem(['user'], {}));

  useEffect(() => {
    localStorage?.setItem('user', JSON.stringify(user));
    //eslint-disable-next-line
  }, [user]);

  const tokens = useSelector(selectItem(['tokens'], {}));

  useEffect(() => {
    localStorage?.setItem('tokens', JSON.stringify(tokens));
    //eslint-disable-next-line
  }, [tokens]);
}

export default useCache;
