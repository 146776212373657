import classNames from 'classnames';
import Checkbox from '../fields/Checkbox';

import styles from './interests-list.module.css';

const InterestsList = ({ items = [1,2,3,4,5,6], onChange }) => {
  return (
    <div className={styles.items}>
      {items.map(({ uid, name, interest, icon }, index) => {
        if (!uid) return (
          <div className={classNames(styles.item, 'skeleton-loader')} key={index} />
        );
        return (
          <div className={classNames(styles.item, interest && styles.active)} onClick={() => onChange(uid, !interest)} key={uid}>
            <div className={styles.name}>{name}</div>
            <div className={styles.imageContainer}>
              <img className={styles.image} src={icon} alt={name} />
            </div>
            <div className={styles.checkbox}>
              <Checkbox name={`interest_${uid}`} checked={interest} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default InterestsList;
