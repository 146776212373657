import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';
import { selectItem } from 'store/app/getters';

import styles from './account.module.css';

const RegisterSuccess = () => {
  const settings = useSelector(selectItem(['settings']));

  return (
    <MainLayout>
      <div className={styles.registerSuccess} dangerouslySetInnerHTML={{ __html: settings?.reg_first_step_text }} />
      <ButtonsContainer>
        <Button
          butStyle="primary"
          fullWidth={true}
          to="/select-city"
        >
          Далее
        </Button>
      </ButtonsContainer>
    </MainLayout>
  );
};

export default RegisterSuccess;