import classNames from 'classnames';
import PointsProgress from 'components/PointsProgress';

import styles from '../balance.module.css';

const Scores = ({ score, crystalls }) => {
  return (
    <>
      <div className={styles.row}>
        <span className={classNames(styles.label, styles.primary)}>Кристаллы:</span>
        <span className={classNames(styles.value, styles.primary, 'accent-text-orange')}>
          {crystalls || 0} <img src="/images/crystal_2.svg" alt="" />
        </span>
      </div>
      <div className={styles.row}>
        <span className={classNames(styles.label, styles.primary)}>Энергия:</span>
        <span className={classNames(styles.value, styles.primary, 'accent-text-orange')}>
          {score.now || 0} <img src="/images/energy.svg" alt="" />
        </span>
      </div>
      <div className={styles.row}>
        <span className={styles.label}>На этой неделе</span>
        <span className={classNames(styles.value, 'accent-text-orange')}>{score.week || 0} <img src="/images/energy.svg" alt="" /></span>
      </div>

      <div className={styles.progress}>
        <div className={styles.progressTitle}>
          Лимит <img src="/images/energy.svg" alt="" /> в день
        </div>
        <PointsProgress
          current={score.day || 0}
          total={score.total || 100}
          color="var(--color-orange-primary)"
          backgroundColor="var(--color-white-primary)"
        />
      </div>
    </>
  );
};

export default Scores;
