import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import styles from './tabs.module.css';

const Tabs = ({ children }) => {
  const { hash } = useLocation();
  const active = hash ? hash.substr(1, hash.length) : null;

  if (!children) return null;

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={classnames(styles.listWrapper, 'container')}>
        <ul className={styles.list}>
          {children.map((child, index) => {
            const name = child.key ? child.key : index;
            return (
              <li className={styles.item} key={name}>
                <Link
                  className={classnames(styles.link, (active === name) && styles.active)}
                  to={'#' + name}
                >
                  {child.props.label}
                </Link>
              </li>
            );
          })}
        </ul>
        </div>
      </div>
      <div className={styles.content}>
        {children.map((child, index) => {
          const name = child.key ? child.key : index;
          if (active !== name) return null;
          return child.props.children;
        })}
      </div>
    </div>
  );
};

export default Tabs;
