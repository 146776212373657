import { Link } from 'react-router-dom';
import classnames from 'classnames';
import getDuration from 'helpers/getDuration';

import styles from './quest-item-small.module.css';

import { categories_bgs_small } from 'data/mock';

const QuestItemSmall = ({ quest }) => {
  const { uid, info, name, categories, lifetime_end, reward, complete_sign, is_complete } = quest;
  const cid = categories ? categories[0]['cat_uid']+'' : '1';
  const bg = categories_bgs_small[cid.slice(-1)];

  if (!uid) return (
    <div className={styles.quest}>
      <div className={classnames(styles.container, 'skeleton-loader')} />
    </div>
  )

  return (
    <Link to={`/user-quest/${uid}`} className={styles.quest}>
      <div className={styles.container} style={{ backgroundImage: `url(${bg})` }}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {info ? info.name : name}
          </div>
        </div>
        <div className={styles.values}>
          {lifetime_end &&
            <div className={styles.value} dangerouslySetInnerHTML={{__html: getDuration(lifetime_end, 1)}} />
          }
          {reward &&
            <div className={styles.value}>
              <span>{reward}</span> <img className={styles.energy} src="/images/energy.svg" alt="" />
            </div>
          }
        </div>
      </div>
      <div className={styles.notice}>
        {(complete_sign && !is_complete) && 'Требует подтверждения'}
      </div>
    </Link>
  );
};

export default QuestItemSmall;
