import React from 'react';
import { Provider } from 'react-redux';
import Routing from './pages/Routing';
import getStore from './store';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';

const App = () => {
  const store = getStore();

  return (
    <Provider store={store}>
      <Routing />
    </Provider>
  );
};

export default App;
