const ruPlural = (value = 0, langs) => {
  let postfix = '_other';

  const a = (value % 10 === 1);
  const b = (value % 100 !== 11);
  if (a && b) postfix = langs[0];

  const c = [2, 3, 4].indexOf(value % 10)     !== -1;
  const d = [12, 13, 14].indexOf(value % 100) === -1;
  if (c && d) postfix = langs[1];

  const e = value % 10 === 0;
  const f = [5, 6, 7, 8, 9].indexOf(value % 10)   !== -1;
  const g = [11, 12, 13, 14].indexOf(value % 100) !== -1;
  if (e || f || g) postfix = langs[2];

  return postfix;
}

export default ruPlural;
