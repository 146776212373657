import { useState } from 'react';
import classnames from 'classnames';

import styles from './facts.module.css';

const max = 220;

const Item = ({ type, message, index }) => {
  const [ isShowAll, setShowAll ] = useState(false);
  const cid = index+1+'';
  const bg = cid.slice(-1);

  return (
    <div className={classnames(styles.item, isShowAll && styles.open)} style={{ backgroundImage: `url(/images/facts_bg/${type}_${bg}.png)` }}>
      <div className={styles.content}>
        {isShowAll ? message : `${message.substr(0, max)}${message.length > max ? '...' : ''}`}
      </div>
      {message.length > max && (
        <div className={styles.buttons}>
          <button className={styles.button} onClick={() => setShowAll(!isShowAll)}>
            {isShowAll ? 'Свернуть' : 'Читать все'}
          </button>
        </div>
      )}
    </div>
  );
};

const Facts = ({ type, items = [1, 2, 3, 4, 5] }) => {
  return (
    <div className={styles.list}>
      {items.map(({ message }, index) => {
        if (!message) return (
          <div className={classnames(styles.item, 'skeleton-loader')} key={index}></div>
        );

        return (
          <Item type={type} message={message} index={index} key={index} />
        );
      })}
    </div>
  );
};

export default Facts;
