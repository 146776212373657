import Button from '../../Button';

import styles from './file-input.module.css';

const FileInput = ({ id, name, text = 'Загрузить фото / видео', butStyle = 'primary', className, onChange }) => {
  return (
    <div className={styles.root}>
      <Button
        className={className}
        butStyle={butStyle}
        size="medium"
        fullWidth={true}
      >
        {text}
      </Button>
      <input
        id={id}
        className={styles.input}
        type="file"
        name={name}
        accept="image/*,video/*"
        onChange={onChange}
      />
    </div>
  );
};

export default FileInput;
