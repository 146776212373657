import { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import styles from './balance.module.css';

const BalanceItem = ({ once, id, title, link, onClick, children, isLoading, showSeeAll }) => {
  const childRef = useRef(null);
  const [ isIntersecting, setIntersecting ] = useState(false);

  useEffect(() => {
    const child = childRef.current;

    const observer = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
      if (once && entry.isIntersecting) observer.unobserve(child);
    }, {});

    observer.observe(child);

    return () => observer.unobserve(child);

    // eslint-disable-next-line
  }, []);

  if (once && isIntersecting) return children;

  return (
    <div ref={childRef} className={classnames(styles.item, (!isIntersecting || isLoading) && 'skeleton-loader')} onClick={onClick} id={id}>
      {!isLoading && (
        <>
          {link &&
            <Link className={styles.settings} to={link}>
              <img src="/icons/icon-settings.svg" className={styles.settingsIcon} alt="" />
            </Link>
          }
          {showSeeAll &&
            <span className={styles.seeAll}>Смотреть</span>
          }
          {title &&
            <div className={classnames(styles.row, styles.title)}>
              {title}
            </div>
          }
        </>
      )}

      {isIntersecting && children}
    </div>
  );
};

export default BalanceItem;
