import { forwardRef } from 'react';
import classnames from 'classnames';

import styles from './switcher.module.css';

const Switcher = forwardRef(({ id, checked, onClick, onBlur, onChange, value, name, title, label, icon, className, containerClassName, error }, ref) => {
  return (
    <div className={classnames(styles.container, containerClassName)}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.content}>
        {icon && <span className={styles.icon}>{icon}</span>}
        {label && <span className={styles.label}>{label}</span>}
        <label className={styles.switch} htmlFor={id}>
          <input
            type="checkbox"
            id={id}
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            onClick={onClick}
            onBlur={onBlur}
            className={classnames(styles.input, className)}
            ref={ref}
          />
          <span className={classnames(styles.switchIcon, checked && styles.checked)}></span>
        </label>
        {error &&
          <span className={styles.errorText}>{ error }</span>
        }
      </div>
    </div>
  );
});

export default Switcher;
