import { ReactSVG } from 'react-svg';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import styles from './users-select.module.css';

const Users = ({ users, current, showProfile }) => {
  return (
    <ul className={classnames(styles.list)}>
      {Object.values(users).map(({ uid, uid_gamer, name, surname, gender, avatar, relation, info }) => {

        if (uid_gamer) uid = uid_gamer;
        if (info && info.uid) uid = info.uid;
        if (info && info.uid_gamer) uid = info.uid_gamer;
        if (info && info.name) name = info.name;
        if (info && info.surname) surname = info.surname;
        if (info && info.gender) gender = info.gender;
        if (info && info.avatar) avatar = info.avatar;

        const photo = avatar ? avatar : (`/icons/icon-child-${gender ? gender.toLowerCase() : 'm'}.svg`);
        const Component = showProfile ? Link : 'div';

        return (
          <li key={uid} className={styles.item}>
            <Component
              to={`/update-player/${uid}`}
              className={classnames(styles.button, (current && String(current) === String(uid)) && styles.active)}
            >
              <img src={photo} className={styles.icon} alt="" />
              <div>
                {(name || surname) ? (
                  <>{name && name} {surname && surname}</>
                ) : (
                  <>Без имени</>
                )}
                {(relation && gender) &&
                  <div className={styles.relation}>({relation['player_' + gender.toLowerCase()]})</div>
                }
              </div>
              {showProfile &&
                <span className={styles.profile}>
                  Профиль
                  {/* <span className={classnames(styles.profileIcon, (current && String(current) === String(uid)) && styles.active)}>
                    {(current && String(current) === String(uid)) &&
                      <ReactSVG src="/icons/icon-checkmark.svg" />
                    }
                  </span> */}
                </span>
              }
            </Component>
          </li>
        )
      })}
    </ul>
  );
};

const UsersSelect = ({ label, users, current, viewStyle, disabled, butState, showAddButton = true }) => {
  return (
    <div className={classnames(styles.main, viewStyle && styles[viewStyle])}>
      {label &&
        <div className={styles.label}>{label}</div>
      }
      {users &&
        <Users users={users} current={current} showProfile={viewStyle !== 'register'} />
      }
      {showAddButton &&
        <Link
          to="/add-player"
          className={classnames(styles.button, styles.add, disabled && styles.disabled)}
          state={butState}
        >
          <ReactSVG src="/icons/icon-plus.svg" className={styles.icon} />
          Добавить ребенка
        </Link>
      }
    </div>
  );
};

export default UsersSelect;
