import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import useApiQuery from 'hooks/useApiQuery';
import { MainLayout } from 'components/Layouts';
import { QuestDetails, QuestDescription, QuestSkills } from 'components/quests/Quest';
import Block from 'components/Block';
import InfoBlock from 'components/InfoBlock';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import Gallery from 'components/Gallery';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';

import styles from './Quests.module.css';

const QuestMentorButtons = ({ player, quest }) => {
  return (
    <>
      <ButtonsContainer>
        {quest.info.link &&
          <Button butStyle="secondary" size="small" fullWidth="true" href={quest.info.link}>Дополнительно</Button>
        }
        {(player && (player.score_day >= player.reward_limit)) &&
          <InfoBlock color="orange">Игрок набрал максимальное количество баллов за сегодня и сможет завершить данный квест только завтра</InfoBlock>
        }
        {!quest.complete_sign &&
          <InfoBlock color="orange">Невозможно подтвердить квест, так как Игрок не обозначил, что выполнил квест</InfoBlock>
        }

        {(quest.start && !quest.is_cancel && !quest.is_complete) &&
          <Button
            fullWidth={true}
            butStyle="primary"
            to={`/approve-quest/${quest.aproove && quest.aproove.token}`}
            disabled={(player && (player.score_day >= player.reward_limit)) || !quest.complete_sign}
          >
            Подтвердить
          </Button>
        }
      </ButtonsContainer>
    </>
  )
}

const UserQuest = () => {
  const navigate = useNavigate();
  let { qid } = useParams();
  const dispatch = useDispatch();

  const user = useSelector(selectItem(['user']));
  const uid_gamer = user.current_player?.uid_gamer;

  const { data: player } = useApiQuery({
    name: ['players', uid_gamer],
    path: `/getUserProfile/${uid_gamer}/`,
    skip: !uid_gamer,
    mapper: (data = {}, prev) => {
      return {...prev, ...data.main};
    }
  });

  const { data: quest, error } = useApiQuery({
    name: ['user_quests', uid_gamer, 'query', qid],
    path: `/getUserQuest/${uid_gamer}/${qid}/`,
    mapper: (data = {}, prev) => {
      if (!data.query) return null;

      let next = {};

      for (let key in data) {
        if (data[key] && data[key].length) {
          for (let questKey in data[key]) { next = data[key][questKey]; }
        }
      }

      return {...prev, ...next};
    }
  });

  useEffect(() => {
    if (error) {
      dispatch(setItem(['notifications', 'request'], { type: 'error', content: error }));
      navigate('/', { replace: true });
    }
    if (quest === null) {
      dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Квест не найден или у Вас нет доступа' }));
      navigate('/', { replace: true });
    }
    // eslint-disable-next-line
  }, [quest, error]);

  const approveGallery = (quest && quest.aproove && quest.aproove.send_aproove) ?
    Object.values(quest.aproove.send_aproove).reduce((prev, { photo, uid }) => {
      if (photo) return [ ...prev, { link: photo, uid: uid } ];
      return prev;
    }, []) : null;

  return (
    <MainLayout title={quest && (quest.info ? quest.info.name : quest.name)}>
      <QuestDetails user={user} quest={quest} />
      <QuestSkills quest={quest} />

      <div style={{ marginTop: '20px' }} />
      {quest && (
        <Block title="Задание">
          <QuestDescription quest={quest} />
          {(approveGallery && approveGallery.length) ? (
            <div className={styles.gallery}>
              <Gallery items={approveGallery.reverse()} />
            </div>
            ) : null
          }
          {(quest.galery && quest.galery.length) ? (
            <div className={styles.gallery}>
              <Gallery items={[...quest.galery].reverse()} />
            </div>
            ) : null
          }
          <QuestMentorButtons player={player} quest={quest} uid_gamer={uid_gamer} />
        </Block>
      )}
    </MainLayout>
  );
};

export default UserQuest;
