export const player_menu = [
  {
    id: 'menu_home',
    name: 'Главная',
    href: '/',
    icon: '/images/menu/home2.svg',
    routes: ['/quest', '/invite-mentor', '/get-quest-approve', '/user-quest', '/invited', '/player', '/homework']
  },
  {
    id: 'menu_map',
    name: 'Карта',
    href: '/game',
    action: 'close-chat',
    icon: '/images/menu/map.svg',
    routes: ['/game']
  },
  {
    id: 'menu_shop',
    name: 'Магазин',
    href: '/store',
    icon: '/images/menu/bag.svg',
    routes: ['/store']
  },
  {
    id: 'menu_card',
    name: 'Сервисы',
    href: '/services',
    icon: '/images/menu/app2.svg',
    routes: ['/card', '/invite-player', '/approve', '/update', '/create-avatar', '/wallet', '/backpack', '/facts']
  },
  // {
  //   id: 'scan',
  //   name: 'Сканировать',
  //   href: '#code-scaner-start',
  //   icon: '/images/menu/scan2.svg',
  //   routes: [],
  //   hidden: true
  // }
];

export const mentor_menu = [
  {
    id: 'menu_home',
    name: 'Главная',
    href: '/',
    icon: '/images/menu/home2.svg'
  },
  {
    id: 'menu_settings',
    name: 'Настройки',
    href: '/update',
    icon: '/images/menu/settings.svg',
    routes: []
  },
  // {
  //   id: 'scan',
  //   name: 'Сканировать',
  //   href: '#code-scaner-start',
  //   icon: '/images/menu/scan2.svg',
  //   routes: [],
  //   hidden: true
  // }
];

export const mentor_player_menu = [
  {
    id: 'menu_home',
    name: 'Главная',
    href: '/player',
    icon: '/images/menu/home2.svg',
    routes: ['/invite-mentor', '/invited', '/player', '/homework']
  },
  {
    id: 'menu_quests',
    name: 'Квесты',
    href: '/quests-wheel',
    icon: '/images/menu/star2.svg',
    routes: ['/quest', '/get-quest-approve', '/user-quest']
  },
  {
    id: 'menu_shop',
    name: 'Магазин',
    href: '/store',
    icon: '/images/menu/bag.svg',
    routes: ['/store']
  },
  {
    id: 'menu_card',
    name: 'Сервисы',
    href: '/services',
    icon: '/images/menu/app2.svg',
    routes: ['/card', '/invite-player', '/approve', '/update', '/create-avatar', '/wallet', '/backpack', '/facts']
  },
  // {
  //   id: 'scan',
  //   name: 'Сканировать',
  //   href: '#code-scaner-start',
  //   icon: '/images/menu/scan2.svg',
  //   routes: [],
  //   hidden: true
  // }
];

