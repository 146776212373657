import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { MainLayout } from 'components/Layouts';
import Homework from 'components/homework/Homework';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';
import { pushItem, setItem, sliceItem } from 'store/app/actions';
import * as api from 'helpers/api';

const HomeworkPage = () => {
  const dispatch = useDispatch();
  const { date } = useParams();
  const { hash } = useLocation();
  const user = useSelector(selectItem(['user']));
  const settings = useSelector(selectItem(['settings']));
  const max_file_size = settings?.max_file_size;

  const { data: homeworkData } = useApiQuery({
    name: ['homework', date],
    path: `/api/getuserdomashka/${user.uid}/${date}/`,
    skip: !user || !date,
    update_param: hash
  });

  const homework = homeworkData?.tasks;

  const sendRequest = (task_uid, status, value) => {
    const fd = new FormData();
    fd.append('task_uid', task_uid);
    fd.append('user_uid', user.uid);
    if (value) fd.append('value', value);
    if (status) fd.append('param', status);

    api
      .post(`/getuserdomashka/`, fd)
      .then(response => {
        const result = response.data.response;

        if (result) {
          const index = homework.tasks.findIndex(({ uid }) => uid === task_uid);
          if (index !== -1) {
            dispatch(setItem(['homework', date, 'tasks', 'tasks', index], result.tasks, { isHard: true }));
          }

          dispatch(setItem(['notifications', 'updateTask'], { type: 'success', content: 'Домашка отправлена на проверку' }));
        }
        if (response.data.error) {
          dispatch(setItem(['notifications', 'updateTask'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  const handleClickSubmit = (task_uid) => {
    sendRequest(task_uid, 'is_complete', 1);
  };

  const handleChangeFile = (file, task_uid, task_type, old_file) => {
    if (!file) return null;
    const isVideo = !!(file.type.match(/video*/));
    if (file.size > max_file_size) {
      dispatch(setItem(['notifications', 'upload'], { type: 'error', title: 'Ошибка', content: 'Макс. размер файла 20Мб' }));
      return null;
    }

    const fd = new FormData();
    fd.append('file', file, file.name);
    fd.append('user_uid', user.uid);
    fd.append('is_video', isVideo ? 1 : 0);
    fd.append('task_type', task_type);
    fd.append('task_uid', task_uid);
    if (old_file) fd.append('old_file', old_file);

    api
      .post(`/uploaddomashkafiles/`, fd, { mimeType: 'multipart/form-data', contentType: false })
      .then(response => {
        const result = response.data.response;

        if (result && result.file) {
          dispatch(setItem(['notifications', 'upload'], { type: 'success', content: 'Файл успешно загружен' }));

          const index = homeworkData.tasks.tasks.findIndex(({ uid }) => uid === task_uid);
          if (index !== -1) {
            if (!old_file) dispatch(pushItem(['homework', date, 'tasks', 'tasks', index, 'files'], { file_url: result.url }));
            else {
              const fileIndex = homeworkData.tasks.tasks[index].files.findIndex(({ file_url }) => old_file === file_url);
              if (fileIndex !== -1) {
                dispatch(sliceItem(['homework', date, 'tasks', 'tasks', index, 'files'], fileIndex));
                dispatch(pushItem(['homework', date, 'tasks', 'tasks', index, 'files'], { file_url: result.url }));
              }
            }
          }
        } else {
          const error = response.data.error ? response.data.error : 'Ошибка загрузки файла';
          dispatch(setItem(['notifications', 'upload'], { type: 'error', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  return (
    <MainLayout title={`Домашка ${(homework && homework.info) ? format(new Date(homework.info.date), 'dd.MM.yyyy') : ''}`}>
      <Homework
        user={user}
        content={homeworkData?.html}
        info={homework?.info}
        tasks={homework?.tasks}
        onClickSubmit={handleClickSubmit}
        onChangeFile={handleChangeFile}
        settings={settings}
      />
    </MainLayout>
  );
};

export default HomeworkPage;