import { MainLayout } from '../../components/Layouts';

const NotFound = () => {
  return (
    <MainLayout>
      <h1>404</h1>
      <p>Страница не найдена</p>
    </MainLayout>
  );
};

export default NotFound;
