import { useState, forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MainLayout } from 'components/Layouts';
import ShopUpdate from 'components/ShopUpdate';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';
import Input from 'components/fields/Input';
import Modal from 'components/Modal';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';
import { setItem, pushItem } from 'store/app/actions';
import * as api from 'helpers/api';

import styles from './Store.module.css';

const Store = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const user = useSelector(selectItem(['user']));
  const crystall_shop_settings_parent = useSelector(selectItem(['settings', 'crystall_shop_settings_parent']));
  const currentPlayer = user.current_player;
  const [ customName, setCustomName ] = useState('');
  const [ customPrice, setCustomPrice ] = useState('');

  const { data: shop } = useApiQuery({
    name: ['shop', currentPlayer.uid_gamer],
    path: `/getshop/${currentPlayer.uid_gamer}/`,
    skip: !currentPlayer || !currentPlayer.uid_gamer
  });

  const handleCheckItem = (item_uid, checked) => {
    const fd = new FormData();
    fd.append('check_item[user_uid]', currentPlayer.uid_gamer);
    fd.append('check_item[item_uid]', item_uid);

    api
      .post(`/getshop/`, fd)
      .then(response => {
        const result = response.data.response;
        dispatch(setItem(['shop', currentPlayer.uid_gamer], result));

        if (result.success_mess)
          dispatch(setItem(['notifications', 'getshop'], { type: 'success', content: result.success_mess }));
        else
          dispatch(setItem(['notifications', 'getshop'], { type: 'error', content: result.error }));
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleSaveCustom = () => {
    const fd = new FormData();
    fd.append('player_uid', currentPlayer.uid_gamer);
    fd.append('mentor_uid', user.uid);
    fd.append('name', customName);
    fd.append('price', customPrice);
    fd.append('add_shop_item', true);

    api
      .post(`/getshop/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success_mess) {
          dispatch(setItem(['shop', currentPlayer.uid_gamer], result));
          dispatch(setItem(['notifications', 'getshop'], { type: 'success', content: result.success_mess }));
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'getshop'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickNext = () => {
    dispatch(pushItem(['onboarding'], {
      key: 'qr',
      image: <img style={{ position: 'absolute', top: '130%', left: '-20px' }} src="/images/onboarding/qr.svg" alt="" />,
      selector: '#sign_as_player'
    }));
  };

  return (
    <MainLayout title="Магазин" description={crystall_shop_settings_parent}>
      <ShopUpdate
        items={shop && shop.shop}
        onCheckItem={handleCheckItem}
      />
      <ButtonsContainer>
        <Modal
          title="Создать свою награду"
          viewStyle="bottom"
          control={forwardRef(({ onClick }, ref) => (<Button butStyle="primary" fullWidth={true} onClick={onClick} ref={ref}>Добавить свой вариант</Button>))}
          onClose={() => { setCustomName(''); setCustomPrice(''); }}
          buttons={[
            {
              action: 'close',
              text: 'Сохранить',
              style: 'primary',
              disabled: customName.length < 3 || +customPrice <= 0,
              onClick: handleSaveCustom
            }
          ]}
        >
          <Input label="Введите название награды" onChange={e => setCustomName(e.target.value)} />
          <br />
          <Input type="number" label="Введите цену в кристаллах" onChange={e => setCustomPrice(e.target.value)} />
        </Modal>
      </ButtonsContainer>

      {state?.onboarding && (
        <div className={styles.onboardButtons}>
          <ButtonsContainer className="container">
            <Button
              butStyle="secondary"
              to="/update-rewards"
              state={{ onboarding: true }}
            >
              Назад
            </Button>
            <Button
              butStyle="primary"
              to="/"
              onClick={handleClickNext}
            >
              Далее
            </Button>
          </ButtonsContainer>
        </div>
      )}
    </MainLayout>
  );
};

export default Store;
