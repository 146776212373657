import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';
import { selectItem } from 'store/app/getters';

import styles from './account.module.css';

const SelectSuccess = () => {
  const settings = useSelector(selectItem(['settings']));

  return (
    <MainLayout>
      <div className={styles.registerSuccess} dangerouslySetInnerHTML={{ __html: settings?.reg_last_step_text }} />
      <ButtonsContainer>
        <Button
          butStyle="primary"
          fullWidth={true}
          href={settings?.reg_last_step_button_href}
          state={{ onboarding: true }}
        >
          {settings?.reg_last_step_button}
        </Button>
      </ButtonsContainer>
    </MainLayout>
  );
};

export default SelectSuccess;