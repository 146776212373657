import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import NoItems from 'components/NoItems';
import Checkbox from 'components/fields/Checkbox';

import styles from './shop-update.module.css';

const ShopUpdate = ({ items = [1, 2, 3, 4], onCheckItem }) => {
  if (!Object.keys(items).length) return ( // no items
    <NoItems children="Нет назначенных наград" />
  );

  return (
    <div className={styles.root}>
      <div className={styles.list}>
        {items.map(({ uid, name, price, url, is_checked }, index) => {
          if (!uid) return (
            <div className={styles.item} key={index}>
              <div className={classNames(styles.imageWrapper, 'skeleton-loader')} />
              <div className={styles.content}>
                <div className={classNames(styles.name, 'skeleton-loader')} />
                <div className={classNames(styles.price, 'skeleton-loader')} />
              </div>
            </div>
          );
          return (
            <label
              htmlFor={`checkbox_${uid}`}
              className={classNames(styles.item, !is_checked && styles.inactive)}
              key={uid}
            >
              <img className={styles.image} src={url} alt={name} />
              <div className={styles.content}>
                <div className={styles.name}>
                  {name}
                </div>
                <div className={styles.price}>
                  <ReactSVG src="/images/crystal.svg" className={styles.priceIcon} wrapper="span" />
                  <div className={styles.priceValue}>
                    {price}
                  </div>
                </div>
              </div>
              <Checkbox
                id={`checkbox_${uid}`}
                name={`checkbox_${uid}`}
                containerClass={styles.checkbox}
                checked={is_checked}
                onChange={() => onCheckItem(uid)}
              />
            </label>
          );
        })}
      </div>
    </div>
  );
}

export default ShopUpdate;