import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import { selectItem } from 'store/app/getters';

import styles from './user-header.module.css';

const UserHeader = ({ user, showEdit }) => {
  const visibleSettings = useSelector(selectItem(['visible_settings']));

  if (!user) return (
    <div className={styles.root}>
      <div className={styles.image}>
        <div className={classnames(styles.icon, 'skeleton-loader')} alt="" />
      </div>
      <div className={styles.header}>
        <h1 className={classnames(styles.title, 'skeleton-loader')}>&nbsp;</h1>
        <span className={classnames(styles.subtitle, 'skeleton-loader')}>&nbsp;</span>
      </div>
    </div>
  );

  let { name, nickname, gender, type, avatar, info } = user;

  if (info && info.name) name = info.name;
  if (info && info.gender) gender = info.gender;
  if (info && info.type) type = info.type;
  if (info && info.avatar) avatar = info.avatar;
  if (info && info.nickname) nickname = info.nickname;

  const photo = avatar ? avatar : (`/icons/icon-${(type && type === 'M') ? 'mentor' : 'child'}-${gender ? gender.toLowerCase() : 'm'}.svg`);
  if (!avatar) {
    avatar = `/icons/icon-${(user.type && user.type === 'M') ? 'mentor' : 'child'}-${user.gender ? user.gender.toLowerCase() : 'm'}.svg`;
  }

  return (
    <div className={styles.root}>
      <div className={styles.image}>
        <img src={photo} className={styles.icon} alt="" />
        {(showEdit && type === 'P' && visibleSettings?.a_avatar_edit_icon) &&
          <Link to="/create-avatar">
            <ReactSVG src="/icons/icon-edit.svg" className={styles.edit} wrapper="span" />
          </Link>
        }
      </div>
      <div className={styles.header}>
        <h1 className={styles.title}>{name}</h1>
        {(nickname && nickname !== 'undefined') && <span className={styles.subtitle}>{nickname}</span>}
      </div>
    </div>
  );
}

export default UserHeader;
