import { MainLayout } from '../../../components/Layouts';
import ButtonsContainer from '../../../components/ButtonsContainer';
import Button from '../../../components/Button';

import styles from './Tariffs.module.css';

const PayTariffSuccess = () => {
  return (
    <MainLayout>
      <div className={styles.finalStatus}>
        <h2 className={styles.finalStatusTitle}>Оплата прошла успешно</h2>
        <ButtonsContainer className={styles.finalStatusButtons}>
          <Button butStyle="primary" to="/">Вернуться на главную</Button>
        </ButtonsContainer>
      </div>
    </MainLayout>
  );
};

export default PayTariffSuccess;