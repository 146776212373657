import classnames from 'classnames';
import NoItems from '../NoItems';

import styles from './bonuses-list.module.css';

const BonusesList = ({ items }) => {
  if (!items) return null;

  return (
    <div className={styles.list}>
      <div className={styles.header}>
        <div className={styles.col}>
          Название
        </div>
        <div className={styles.col}>
          Дата/время
        </div>
        <div className={styles.col}>
          Баллы
        </div>
      </div>
      {items.length ? (
        items.map(({ name, timestamp, value, type }, index) => (
          <div className={classnames(styles.item, type === 'q' ? styles.success : styles.fail)} key={index}>
            <div className={styles.col}>
              {name}
            </div>
            <div className={styles.col}>
              {timestamp ? timestamp.replace(/-/g, '.') : null}
            </div>
            <div className={styles.col}>
              {value}
            </div>
          </div>
        ))
      ) : (
        <NoItems>Начислений не найдено</NoItems>
      )}
    </div>
  );
};

export default BonusesList;
