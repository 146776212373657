import classnames from 'classnames';

import styles from './inputs-container.module.css';

const InputsContainer = ({ className = null, children }) => {
  return (
    <div className={classnames(styles.main, className)}>
      { children }
    </div>
  );
};

export default InputsContainer;