import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import ArtefactsList from 'components/ArtefactsList';
import { selectItem } from 'store/app/getters';
import useApiQuery from 'hooks/useApiQuery';

const Artefacts = () => {
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;

  const { data: artefacts } = useApiQuery({
    name: ['artefacts', currentPlayer?.uid_gamer],
    path: `/playerrartefacts/${currentPlayer?.uid_gamer}/`,
    skip: !user || !currentPlayer
  });

  if (!user) return null;

  return (
    <MainLayout title="Артефакты">
      <ArtefactsList items={artefacts} />
    </MainLayout>
  );
};

export default Artefacts;
