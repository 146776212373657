const stopScroll = () => {
  const scrollTop = document.documentElement.scrollTop;

  document.documentElement.style.overflow = 'hidden';
  document.documentElement.style.maxHeight = '100vh';
  document.documentElement.style.position = 'fixed';
  document.documentElement.style.top = -scrollTop + 'px';
};

const startScroll = () => {
  const scrollTop = document.documentElement.style.top;

  document.documentElement.style = '';
  document.documentElement.scrollTop = scrollTop.replace('-', '').replace('px', '');
};

const scrollToX = (elem, pos, time = 500) => {
  const currentPos = elem.scrollLeft;
  let start = null;
  pos = +pos;
  time = +time;
  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    const progress = currentTime - start;

    if (currentPos < pos) elem.scrollTo(((pos - currentPos) * progress / time) + currentPos, 0);
    else elem.scrollTo(currentPos - ((currentPos - pos) * progress / time), 0);

    if (progress < time) window.requestAnimationFrame(step);
    else elem.scrollTo(pos, 0);
  });
};

export { stopScroll, startScroll, scrollToX };
