import { useSelector } from 'react-redux';
import RewardsList from 'components/RewardsList';
import { MainLayout } from 'components/Layouts';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';

const Rewards = () => {
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;

  const { data: rewards } = useApiQuery({
    name: ['rewards_mentor_player', currentPlayer.uid_gamer],
    path: `/playerrewardsfrommentor/${currentPlayer.uid_gamer}/player/`,
    skip: !user || !currentPlayer,
    mapper: (data = {}) => {
      return Object.values(data).reduce((prev, current) => {
        if (current) return ([ ...prev, ...current ]);
        return prev;
      }, []);
    }
  });

  if (!user) return null;

  return (
    <MainLayout title="Награды для игрока">
      <RewardsList items={rewards} />
    </MainLayout>
  );
};

export default Rewards;
