import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from 'components/Layouts';
import Button from 'components/Button';
import Form from 'components/Form';
import Select from 'components/fields/Select';
import ProgressBar from 'components/ProgressBar';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import * as api from 'helpers/api';

import styles from './account.module.css';

import { grades, letters } from 'data/mock';

const SelectSchool = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const [ isSubmitActive, setSubmitActive ] = useState(false);

  const player = user.current_player;
  let selectedSchool = [];
  if (player.info && player.info.school && player.info.school_name) {
    selectedSchool.push({
      label: player.info.school_name,
      value: player.info.school
    });
  }
  const inputs = [
    { id: 'school', name: 'school', component: Select, validation: 'select',
      props: {
        label: 'Выберите школу', placeholder: '№12 г. Балаково', fullWidth: true, isSearchable: true,
        value: player.info.school || '',
        options: selectedSchool,
        loadUrl: `/getschool/${player.info.location}`, mapper: (data) => data.map(({ name, uid }) => ({ label: name, value: uid })),
        customParams: {
          user_uid: player.uid_gamer,
          city: player.info.location,
          buttonUrl: '/newschool/',
          buttonText: 'Школы нет в списке',
          inputText: 'Введите название школы',
        }
      }
    },
    { id: 'class', name: 'class', component: Select, validation: 'select',
      props: {
        label: 'Выберите класс',
        placeholder: '8',
        options: grades,
        fullWidth: true,
        value: player.info.class || ''
      }
    },
    { id: 'class_sign', name: 'class_sign', component: Select, validation: 'select',
      props: {
        label: 'Выберите букву',
        placeholder: 'А',
        options: letters,
        fullWidth: true,
        value: player.info.class_sign || ''
      }
    },
  ];

  const onSubmit = (data) => {
    const fd = new FormData();
    for (let key in data) {
      fd.append(`params[${key}]`, data[key]);
    }

    api
      .post(`/updateUserProfile/${player.uid_gamer}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          let playerKey = user.player.findIndex(({ uid_gamer }) => (String(player.uid_gamer) === String(uid_gamer)));

          if (playerKey !== -1) {
            dispatch(setItem(['user', 'player', playerKey, 'info'], data));
            dispatch(setItem(['players', player.uid_gamer], data));
            navigate('/select-success');
          } else {
            dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: 'Ошибка. Обновите страницу и попробуйте снова' }));
          }
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  return (
    <MainLayout>
      <div className={styles.steps}>
        <ProgressBar total={100} value={66} />
      </div>
      <div className={styles.stepsTitle}>
        Выберите школу и класс, в котором учится ваш ребёнок
      </div>
      <div className={styles.stepsContent}>
        <Form
          inputs={inputs}
          onSubmit={onSubmit}
          onValidChange={setSubmitActive}
          buttons={
            <>
              <Button
                type="submit"
                butStyle="secondary"
                to="/select-city"
              >
                Назад
              </Button>
              <Button
                type="submit"
                butStyle="primary"
                disabled={!isSubmitActive}
              >
                Далее
              </Button>
            </>
          }
        />
      </div>
    </MainLayout>
  );
};

export default SelectSchool;