import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MainLayout } from 'components/Layouts';
import Button from 'components/Button';
import Form from 'components/Form';
import Select from 'components/fields/Select';
import ProgressBar from 'components/ProgressBar';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import * as api from 'helpers/api';

import styles from './account.module.css';

const SelectCity = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const [ isSubmitActive, setSubmitActive ] = useState(false);

  const player = user.current_player;
  let locationOpts = [];
  if (player.info && player.info.location) {
    locationOpts.push({
      label: player.info.location,
      value: player.info.location
    });
  }

  const inputs = [
    { id: 'location', name: 'location', component: Select, validation: 'select',
      props: {
        modalTitle: 'Город проживания', label: 'Название города', searchPlaceholder: 'Новоуральск',
        value: player.info.location, query: player.info.location, options: locationOpts,
        placeholder: 'Новоуральск', fullWidth: true, isSearchable: true,
        loadUrl: '/getcity', mapper: (data) => data.map(({ city }) => ({ label: city, value: city }))
      }
    },
  ];

  const onSubmit = (data) => {
    const fd = new FormData();
    for (let key in data) {
      fd.append(`params[${key}]`, data[key]);
    }

    // api
    //   .post(`/updateUserProfile/${user.uid}/`, fd)
    //   .then(response => {
    //     const result = response.data.response;
    //     if (result.success) {
    //       dispatch(setItem(['user'], result.user));
    //     } else {
    //       const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
    //       dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', title: 'Ошибка', content: error }));
    //     }
    //   })
    //   .catch(error => {
    //     dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
    //   });

    api
      .post(`/updateUserProfile/${player.uid_gamer}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          let playerKey = user.player.findIndex(({ uid_gamer }) => (String(player.uid_gamer) === String(uid_gamer)));

          if (playerKey !== -1) {
            dispatch(setItem(['user', 'player', playerKey, 'info'], data));
            dispatch(setItem(['players', player.uid_gamer], data));
            navigate('/select-school');
          } else {
            dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: 'Ошибка. Обновите страницу и попробуйте снова' }));
          }
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  return (
    <MainLayout>
      <div className={styles.steps}>
        <ProgressBar total={100} value={33} />
      </div>
      <div className={styles.stepsTitle}>
        Выберите город, в котором вы живёте
      </div>
      <div className={styles.stepsContent}>
        <Form
          inputs={inputs}
          onSubmit={onSubmit}
          onValidChange={setSubmitActive}
          buttons={
            <Button
              type="submit"
              fullWidth={true}
              butStyle="primary"
              disabled={!isSubmitActive}
            >
              Далее
            </Button>
          }
        />
      </div>
    </MainLayout>
  );
};

export default SelectCity;