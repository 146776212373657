// import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { MainLayout } from 'components/Layouts';
import HeaderPlayer from 'components/header/HeaderPlayer';
import Block from 'components/Block';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';
import Balance from 'components/Balance';
// import TariffNotification from 'components/tariffs/TariffNotification';
import About from 'components/homepage/About';
// import Facts from 'components/homepage/Facts';
import { HomeworkPlayer } from 'components/homepage/Homework';
import QuestsList from 'components/quests/QuestsList';
import HpStories from 'components/homepage/HpStories';
import SidebarPlayer from 'components/header/HeaderPlayer/SidebarPlayer';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
import useApiQuery from 'hooks/useApiQuery';

import styles from './player.module.css';

const UserQuery = ({ uid, visibleSettings }) => {
  useApiQuery({
    name: ['user_quests', uid],
    path: `/getUserQuest/${uid}/`,
    skip: !(visibleSettings.a_vidget_active_quests || visibleSettings.a_vidget_old_quests),
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let questKey in data[key]) {
            const quest = data[key][questKey];
            next[key][quest.uid] = {...quest, position: key};
          }
        }
      }

      return next;
    }
  });

  useApiQuery({
    name: ['user_ambitions'],
    path: `/getPlayerAmbitions/${uid}/`,
    skip: !visibleSettings.a_vidget_ambitions,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let ambKey in data[key]) {
            const amb = data[key][ambKey];
            next[key][amb.skill_uid] = amb;
          }
        }
      }

      return next;
    }
  });

  useApiQuery({
    name: ['grants'],
    path: `/getstepresult/${uid}/`
  });

  useApiQuery({
    name: ['quest_groups', 'S'],
    path: `/getquestgroup/S/0/${uid}/`,
    skip: !visibleSettings.a_vidget_games
  });

  // useApiQuery({
  //   name: ['courses_groups', 'P'],
  //   path: `/getCoursesAll/${uid}/P/`
  // });

  // useApiQuery({
  //   name: ['courses_groups', 'T'],
  //   path: `/getCoursesAll/${uid}/T/`
  // });

  // useApiQuery({
  //   name: ['courses'],
  //   path: `/getCoursesAll/${uid}/`,
  //   mapper: (data = {}) => {
  //     const next = data.reduce((prev, current) => ({ ...prev , [current.uid]: current }), {});
  //     return next;
  //   }
  // });

  // useApiQuery({
  //   name: ['user_courses'],
  //   path: `/getUserCourses/${uid}/`,
  //   isHard: true,
  //   mapper: (data = {}) => {
  //     const next = data.reduce((prev, current) => {
  //       return { ...prev , [current.course_uid]: current };
  //       // return (current.length && current[0].course_uid) ? { ...prev , [current[0].course_uid]: course } : prev;
  //     }, {});
  //     return next;
  //   }
  // });

  useApiQuery({
    name: ['rewards_mentor', uid],
    path: `/playerrewardsfrommentor/${uid}/`,
    mapper: (data = {}) => {
      return Object.values(data).reduce((prev, current) => {
        if (current) return ([ ...prev, ...current ]);
        return prev;
      }, []);
    }
  });

  // useApiQuery({
  //   name: ['day_phrase_small', uid],
  //   path: `/getdayphrase/${uid}/small/`,
  //   save_history: true
  // });

  useApiQuery({
    name: ['homework_small'],
    path: `/api/getuserdomashka/${uid}/small/`,
    skip: !visibleSettings.a_vidget_homework
  });

  return null;
};

const Home = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const quests = useSelector(selectItem(['user_quests', user.uid]));
  const settings = useSelector(selectItem(['settings']));
  const visibleSettings = useSelector(selectItem(['visible_settings']));
  const ambitions = useSelector(selectItem(['user_ambitions']));
  const grants = useSelector(selectItem(['grants']));
  const rewards_mentor = useSelector(selectItem(['rewards_mentor', user.uid]));
  // const courses = useSelector(selectItem(['courses']));
  // const courses_groups = useSelector(selectItem(['courses_groups']));
  const quest_groups = useSelector(selectItem(['quest_groups']));
  // const user_courses = useSelector(selectItem(['user_courses']));
  // const notification = useSelector(selectItem(['settings', 'notification']));
  // const day_phrase = useSelector(selectItem(['day_phrase_small', user.uid]));
  const homework = useSelector(selectItem(['homework_small']));
  // const onboarding = useSelector(selectItem(['onboarding']));
  // const hw_ob_showed = localStorage?.getItem(`hw_ob_showed_${user.uid}`);

  // useEffect(() => {
  //   if (homework
  //     && !hw_ob_showed
  //     && (!homework.main || !Object.values(homework.main).find(({ time }) => !!time.uid))
  //     && (!onboarding || onboarding.findIndex(({ key }) => (key === 'homework_small')) === -1)
  //   ) {
  //     dispatch(pushItem(['onboarding'], {
  //       key: 'homework_small',
  //       selector: '#ob_homework',
  //       image: <img style={{ position: 'absolute', top: '-184px', right: '-41px' }} src="/images/onboarding/homework_child.svg" alt="" />,
  //       onClick: () => localStorage?.setItem(`hw_ob_showed_${user.uid}`, true),
  //       onClose: () => localStorage?.setItem(`hw_ob_showed_${user.uid}`, true)
  //     }));
  //   }
  //   // eslint-disable-next-line
  // }, [homework]);

  const stipendion = grants ? (
    (grants.stipendion && grants.stipendion.length) ? [...grants.stipendion].sort((item) => (Number(item.mentor_uid) === Number(user.uid) ? -1 : 0)) : []
  ) : null;

  const sortByComplete = (items) => {
    return items.sort((prev, current) => current.complete_sign ? 1 : -1);
  };

  const showAllOld = (
    <div className={styles.buttonQuests}>
      <Button butStyle="text-primary" to="/user-quests/#complete">Смотреть все</Button>
    </div>
  );

  const showAllActive = (
    <div className={styles.buttonQuests}>
      <Button butStyle="text-primary" to="/user-quests/#active">Смотреть все</Button>
    </div>
  );

  // const showAllPhrases = (
  //   <div className={styles.buttonQuests}>
  //     <Button butStyle="text-primary" to="/facts">Смотреть все</Button>
  //   </div>
  // );

  const showAllHomework = (
    <div className={styles.buttonQuests}>
      <Button className={styles.buttonAll} butStyle="text-tertiary" to="/homework">Смотреть все</Button>
    </div>
  );

  // const showAllActiveCourses = (
  //   <div className={styles.buttonQuests}>
  //     <Button butStyle="text-primary" to="/user-courses">Смотреть все</Button>
  //   </div>
  // );

  const openSidebar = () => {
    dispatch(setItem(['common', 'side_menu', 'open'], true));
  };

  return (
    <MainLayout>
      {(user && user.uid && visibleSettings) && <UserQuery uid={user.uid} visibleSettings={visibleSettings} />}
      <HeaderPlayer user={user} />

      <Button
        size="small"
        fullWidth={true}
        className={styles.signIn}
        butStyle="secondary"
        onClick={openSidebar}
        icon={<ReactSVG className={styles.signInIcon} src="/icons/icon-sign-in.svg" wrapper="span" />}
        id="sign_as_player"
      >
        Войти как взрослый
      </Button>

      {visibleSettings?.a_vidget_about && (
        <Block title="О сервисе">
          {user && <About user={user} />}
        </Block>
      )}

      {visibleSettings?.a_vidget_homework && (
        <Block title="Школьная домашка" rightButton={showAllHomework} id="ob_homework">
          <HomeworkPlayer
            user={user}
            schedule={homework?.main}
            info={homework?.tasks?.info}
            tasks={homework?.tasks?.tasks}
            settings={settings}
          />
        </Block>
      )}

      {visibleSettings?.a_vidget_blobal_vidgets && (
        <Block>
          <Balance
            user={user}
            score={{ now: user.score_now, day: user.score_day, week: user.score_week, prevWeek: user.score_week_prev, total: user.reward_limit }}
            ambitions={ambitions && ambitions.active}
            stipendion={stipendion}
            rewards={rewards_mentor}
            crystalls={user?.crystalls}
            showSeeAll
          />
        </Block>
      )}

      {visibleSettings?.a_vidget_quests_button && (
        <Block>
          <ButtonsContainer>
            <Button
              fullWidth={true}
              butStyle="primary"
              to="/quests-wheel"
            >
              Выбрать новый квест
            </Button>
          </ButtonsContainer>
        </Block>
      )}

      {visibleSettings?.a_vidget_active_quests && (
        <Block title="Мои квесты" rightButton={showAllActive}>
          <QuestsList
            quests={quests && quests.active && sortByComplete(Object.values(quests.active))}
            type="small"
            direction="row"
            emptyText="Нет активных квестов"
          />
        </Block>
      )}

      {/* <Block
        title="Активные курсы"
        rightButton={showAllActiveCourses}
      >
        <UserCourses items={user_courses} courses={courses} emptyText="Нет активных курсов" />
      </Block> */}

      {visibleSettings?.a_vidget_games && (
        <Block title="Игры" infoSlug="games">
          <HpStories items={quest_groups && quest_groups.S} />
        </Block>
      )}

      {visibleSettings?.a_vidget_old_quests && (
        <Block title="Пройденные квесты" rightButton={showAllOld}>
          <QuestsList
            quests={quests && quests.complete && sortByComplete(Object.values(quests.complete))}
            type="small"
            direction="row"
            emptyText="Нет пройденных квестов"
            showRate={true}
          />
        </Block>
      )}
      <SidebarPlayer user={user} mentors={user.mentor} />
    </MainLayout>
  );
}

export default Home;
