import { Link } from 'react-router-dom';
import classnames from 'classnames';

import styles from './buttons-grid.module.css';
import { useSelector } from 'react-redux';
import { selectItem } from 'store/app/getters';

const ButtonsGrid = ({ buttons }) => {
  const visibleSettings = useSelector(selectItem(['visible_settings']));
  return (
    <div className={styles.list}>
      {buttons.map(({ id, name, link, icon, disabled }, index) => {
        if (id === 's_store' && !visibleSettings?.a_services_shop) return null;
        if (id === 's_vict' && !visibleSettings?.a_services_vict) return null;
        if (id === 's_settings' && !visibleSettings?.a_services_settings) return null;
        if (id === 's_friends' && !visibleSettings?.a_services_friends) return null;
        if (id === 's_rating' && !visibleSettings?.a_services_rating) return null;
        if (id === 's_statistic' && !visibleSettings?.a_services_skills) return null;
        if (id === 's_backpack' && !visibleSettings?.a_services_rukzak) return null;
        if (id === 's_profile' && !visibleSettings?.a_services_profile) return null;
        if (id === 's_guide' && !visibleSettings?.a_services_guide) return null;

        return (
          <div className={styles.item} key={index}>
            <Link className={classnames(styles.button, disabled && styles.disabled)} to={link}>
              <span className={styles.buttonImage}>
                <img className={styles.buttonIcon} src={icon} alt="name" />
              </span>
              <span className={styles.buttonText}>{name}</span>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default ButtonsGrid;
