import { QuestItem, QuestItemLocked, QuestItemEnded } from '../QuestItem';
import NoItems from '../../NoItems';

import styles from './courses-quests.module.css';

const Course = ({ item, prevName, checkLock }) => {
  if (!checkLock) return <QuestItem quest={item} />;
  if (!item.start) return <QuestItem quest={item} lock={true} />;

  if (item.is_canceled || item.end) return <QuestItemEnded quest={item} lock={true} />;
  if (item.status === 'E') return <QuestItem quest={item} />;
  return <QuestItemLocked quest={item} prevName={prevName} lock={true} />;
};

const CoursesQuests = ({ items  = [1, 2, 3, 4, 5], emptyText, checkLock }) => {
  if (!Object.keys(items).length) return ( // no items
    <NoItems children={emptyText} />
  );

  return (
    <div className={styles.list}>
      {Object.values(items).map((item, index) => (
        <div className={styles.item} key={index}>
          <Course item={item} prevName={index > 0 && items[index - 1].name} checkLock={checkLock} />
        </div>
      ))}
    </div>
  );
};

export default CoursesQuests;