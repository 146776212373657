import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import Balance from 'components/Balance';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';

const Wallet = () => {
  const user = useSelector(selectItem(['user']));

  const { data: ambitions } = useApiQuery({
    name: ['user_ambitions'],
    path: `/getPlayerAmbitions/${user.uid}/`,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let ambKey in data[key]) {
            const amb = data[key][ambKey];
            next[key][amb.skill_uid] = amb;
          }
        }
      }

      return next;
    }
  });

  const { data: grants } = useApiQuery({
    name: ['grants'],
    path: `/getstepresult/${user.uid}/`
  });

  const stipendion = grants ? (
    (grants.stipendion && grants.stipendion.length) ? [...grants.stipendion].sort((item) => (Number(item.mentor_uid) === Number(user.uid) ? -1 : 0)) : []
  ) : null;

  return (
    <MainLayout title="Хранилище" infoSlug="wallet">
      <Balance stipendion={stipendion} ambitions={ambitions && ambitions.active} size="small" />
      <ButtonsContainer>
        <Button
          to="/bonuses#A"
          butStyle="primary"
          fullWidth={true}
          size="medium"
        >
          Мои начисления
        </Button>
      </ButtonsContainer>
    </MainLayout>
  );
};

export default Wallet;
