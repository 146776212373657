import { useState } from 'react';
import Modal from '../../../Modal';
import classnames from 'classnames';

import styles from '../../fields.module.css';

const EmptyPopup = ({ isShow, title, onSubmit, onClose }) => {
  const [ value, setValue ] = useState(null);

  return (
    <Modal
      title={title}
      viewStyle="bottom"
      open={isShow}
      onClose={() => onClose()}
      buttons={[{
        text: 'Записать',
        action: 'click',
        style: 'primary',
        onClick: () => onSubmit(value)
      }]}
    >
      <input type="text" className={classnames(styles.input, 'dark')} onChange={e => setValue(e.target.value)} />
    </Modal>
  )
};

export default EmptyPopup;
