import { forwardRef, useState } from 'react';
import classnames from 'classnames';

import baseStyles from '../fields.module.css';
import styles from './switch.module.css';

const Switch = forwardRef(({
    id, view='radio', onClick, onBlur, onChange, value, name, options, title, label, error, color, inputClass, containerClass, className
  }, ref) => {
  const [ current, setCurrent ] = useState(value);

  if (!options) return null;

  //defaultValue = value ???

  const onRadioChange = (e) => {
    setCurrent(e.target.value);
    if (onChange) onChange(e);
  }

  return (
    <div className={classnames(styles.container, containerClass)}>
      {title && <div className={styles.title}>{title}</div>}
      {label && <label className={baseStyles.label}>{label}</label>}
      <div className={classnames(styles[view])}>
        {options.map(item =>
          <label
            className={classnames(
              styles[`${view}Option`],
              String(current) === String(item.value) && styles.checked,
              color && styles[color],
              inputClass && styles[inputClass],
              className
            )}
            htmlFor={`${id}_${item.value}`}
            key={item.value}
          >
            <input
              type="radio"
              id={`${id}_${item.value}`}
              name={name}
              value={item.value}
              onChange={(e) => onRadioChange(e)}
              onClick={onClick}
              onBlur={onBlur}
              className={styles.input}
              defaultChecked={String(current) === String(item.value)}
              ref={ref}
            />
            <span dangerouslySetInnerHTML={{ __html: item.label }} />
          </label>
        )}
      </div>
      {error &&
        <span className={baseStyles.errorText}>{ error }</span>
      }
    </div>
  );
});

export default Switch;
