import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import useApiQuery from 'hooks/useApiQuery';
import { MainLayout } from 'components/Layouts';
import Block from 'components/Block';
import Balance from 'components/Balance';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import HeaderMentor from 'components/header/HeaderMentor';
import TariffNotification from 'components/tariffs/TariffNotification';
import { HomeworkMentor } from 'components/homepage/Homework';
import LoginModal from 'components/modals/LoginModal';
import LoginPlayer from 'components/modals/LoginPlayer';
// import HpStories from 'components/homepage/HpStories';
// import Facts from 'components/homepage/Facts';
import About from 'components/homepage/About';
import Rewards from 'components/homepage/Rewards';
import QuestsList from 'components/quests/QuestsList';
import HpStories from 'components/homepage/HpStories';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
import getCurrentHost from 'helpers/getCurrentHost';
import copyToClipboard from 'helpers/copyToClipboard';

import styles from './mentor.module.css';

const UserQuery = ({ uid, visibleSettings }) => {
  useApiQuery({
    name: ['user_quests', uid],
    path: `/getUserQuest/${uid}/`,
    skip: !(visibleSettings.a_vidget_active_quests || visibleSettings.a_vidget_old_quests),
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let questKey in data[key]) {
            const quest = data[key][questKey];
            next[key][quest.uid] = {...quest, position: key};
          }
        }
      }

      return next;
    }
  });

  useApiQuery({
    name: ['user_ambitions', uid],
    path: `/getPlayerAmbitions/${uid}/`,
    skip: !visibleSettings.a_vidget_ambitions,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        next[key] = (prev && prev[key]) ? {...prev[key]} : {};

        if (data[key] && data[key].length) {
          for (let ambKey in data[key]) {
            const amb = data[key][ambKey];
            next[key][amb.skill_uid] = amb;
          }
        }
      }

      return next;
    }
  });

  useApiQuery({
    name: ['grants', uid],
    path: `/getstepresult/${uid}/`
  });

  useApiQuery({
    name: ['quest_groups', uid, 'S'],
    path: `/getquestgroup/S/0/${uid}/`,
    skip: !visibleSettings.a_vidget_games
  });

  // useApiQuery({
  //   name: ['courses_groups', uid, 'P'],
  //   path: `/getCoursesAll/${uid}/P/`
  // });

  // useApiQuery({
  //   name: ['courses_groups', uid, 'T'],
  //   path: `/getCoursesAll/${uid}/T/`
  // });

  // useApiQuery({
  //   name: ['courses', uid],
  //   path: `/getCoursesAll/${uid}/`,
  //   isHard: true,
  //   mapper: (data = {}) => {
  //     const next = data.reduce((prev, current) => ({ ...prev , [current.uid]: current }), {});
  //     return next;
  //   }
  // });

  // useApiQuery({
  //   name: ['user_courses', uid],
  //   path: `/getUserCourses/${uid}/`,
  //   mapper: (data = {}) => {
  //     const next = data.reduce((prev, current) => {
  //       return { ...prev , [current.course_uid]: current };
  //       // return (current.length && current[0].course_uid) ? { ...prev , [current[0].course_uid]: course } : prev;
  //     }, {});
  //     return next;
  //   }
  // });

  useApiQuery({
    name: ['rewards_mentor', uid],
    path: `/playerrewardsfrommentor/${uid}/`,
    mapper: (data = {}) => {
      return Object.values(data).reduce((prev, current) => {
        if (current) return ([ ...prev, ...current ]);
        return prev;
      }, []);
    }
  });

  useApiQuery({
    name: ['homework_small_mentor', uid],
    path: `/api/getuserdomashka/${uid}/small/`,
    skip: !visibleSettings.a_vidget_homework
  });

  // useApiQuery({
  //   name: ['day_phrase_small', uid],
  //   path: `/getdayphrase/${uid}/small/`,
  //   save_history: true
  // });

  return null;
}

const Home = () => {
  const loginRef = useRef(null);
  const dispatch = useDispatch();
  const visibleSettings = useSelector(selectItem(['visible_settings']));
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const uid = currentPlayer?.uid_gamer;
  const info = currentPlayer?.info;

  const quests = useSelector(selectItem(['user_quests', uid]));
  const rewards_mentor = useSelector(selectItem(['rewards_mentor', uid]));
  const ambitions = useSelector(selectItem(['user_ambitions', uid]));
  const grants = useSelector(selectItem(['grants', uid]));
  // const courses = useSelector(selectItem(['courses', uid]));
  // const courses_groups = useSelector(selectItem(['courses_groups', uid]));
  const quest_groups = useSelector(selectItem(['quest_groups', uid]));
  // const user_courses = useSelector(selectItem(['user_courses', uid]));
  const pin_code = useSelector(selectItem(['pin_code']));
  // const day_phrase = useSelector(selectItem(['day_phrase_small', uid]));
  const homework = useSelector(selectItem(['homework_small_mentor', uid]));
  const settings = useSelector(selectItem(['settings']));

  const stipendion = grants ? (
    (grants.stipendion && grants.stipendion.length) ? [...grants.stipendion].sort((item) => (Number(item.mentor_uid) === Number(user.uid) ? -1 : 0)) : []
  ) : null;

  useApiQuery({
    name: ['pin_code'],
    path: `/getpincode/${user.uid}/`,
    skip: !user
  });

  const sortByComplete = (items) => {
    return items.sort((prev, current) => current.complete_sign ? 1 : -1);
  };

  const showAllOld = (
    <div className={styles.buttonQuests}>
      <Button butStyle="text-primary" to="/user-quests/#complete">Смотреть все</Button>
    </div>
  );

  const showAllActiveQuests = (
    <div className={styles.buttonQuests}>
      <Button butStyle="text-primary" to="/user-quests/#active">Смотреть все</Button>
    </div>
  );

  const showAllHomework = (
    <div className={styles.buttonQuests}>
      <Button className={styles.buttonAll} butStyle="text-primary" to="/homework">Смотреть все</Button>
    </div>
  );

  // const showAllPhrases = (
  //   <div className={styles.buttonQuests}>
  //     <Button butStyle="text-primary" to="/player-facts">Смотреть все</Button>
  //   </div>
  // );

  // const showAllActiveCourses = (
  //   <div className={styles.buttonQuests}>
  //     <Button butStyle="text-primary" to="/user-courses">Смотреть все</Button>
  //   </div>
  // );

  const showAllRewards = (
    <div className={styles.buttonQuests}>
      <Button butStyle="text-primary" to="/rewards">Смотреть все</Button>
    </div>
  );

  const [ isShowModal, setShowModal ] = useState(false);

  const handleClickCopy = async (key) => {
    const url = `${getCurrentHost()}/auth/${key}`;

    if (settings?.user_agent === 'skillary-mobile') {
      copyToClipboard(url);
    } else {
      try {
        await navigator.share({
          title: 'Skillary: Войти в приложение',
          url: url
        });
      } catch (err) {
        const result = copyToClipboard(url);
        if (result) {
          dispatch(setItem(['notifications', 'copy_link'], { type: 'success', content: 'Ссылка для входа скопирована' }));
        } else {
          dispatch(setItem(['notifications', 'copy_link'], { type: 'error', content: 'Ошибка копирования' }));
        }
      }
    }

    setShowModal(false);
  };

  const handleClickSignIn = () => {
    setShowModal(true);
  };

  const handleClickLogin = (uid_gamer) => {
    setShowModal(false);
    loginRef.current.login(uid_gamer)
  };

  return (
    <MainLayout>
      <HeaderMentor user={user} pin_code={pin_code} />
      {(user?.player) ? (
        <>
          {(currentPlayer && currentPlayer.uid_gamer && visibleSettings) && (
            <UserQuery uid={currentPlayer.uid_gamer} visibleSettings={visibleSettings} />
          )}

          <Block className={styles.mainInfo}>
            {(currentPlayer && info && info.tariff && info.tariff.message && info.tariff.is_visible && settings?.mobile_visible_payment) &&
              <TariffNotification tariff={info.tariff} user_type={user.type} uid_gamer={currentPlayer.uid_gamer} />
            }

            <Button
              size="small"
              fullWidth={true}
              className={styles.signIn}
              butStyle="secondary"
              onClick={() => handleClickSignIn(user.current_player.uid_gamer)}
              icon={<ReactSVG className={styles.signInIcon} src="/icons/icon-sign-in.svg" wrapper="span" />}
              id="sign_as_player"
            >
              Передать ребенку
            </Button>
          </Block>

          {visibleSettings?.a_vidget_about && (
            <Block title="О сервисе">
              {user && <About user={currentPlayer} />}
            </Block>
          )}

          {visibleSettings?.a_vidget_rewards_list && (
            <Block title="Награды для игрока" rightButton={showAllRewards}>
              {user && <Rewards user={currentPlayer} items={rewards_mentor} emptyText="Нет полученных наград" />}
            </Block>
          )}
          {visibleSettings?.a_vidget_games && (
            <Block title="Игры" infoSlug="games">
              <HpStories items={quest_groups && quest_groups.S} />
            </Block>
          )}

          {/* <Block title="Факт дня" rightButton={showAllPhrases}>
            <Facts type="P" items={day_phrase} />
          </Block> */}

          {visibleSettings?.a_vidget_homework && (
            <Block
              id="homework"
              title="Школьная домашка"
              rightButton={showAllHomework}
            >
              <HomeworkMentor
                user={user}
                schedule={homework?.main}
                info={homework?.tasks?.info}
                tasks={homework?.tasks?.tasks}
                settings={settings}
              />
            </Block>
          )}

          {visibleSettings?.a_vidget_blobal_vidgets && (
            <Block title="Достижения ребенка" id="player_balance">
              <Balance
                user={user}
                score={info && { now: info.score_now, day: info.score_day, week: info.score_week, prevWeek: info.score_week_prev, total: info.reward_limit }}
                ambitions={ambitions && ambitions.active}
                stipendion={stipendion}
                rewards={rewards_mentor}
                crystalls={info?.crystalls}
              />
            </Block>
          )}

          {visibleSettings?.a_vidget_quests_button && (
            <Block>
              <ButtonsContainer>
                <Button
                  fullWidth={true}
                  butStyle="primary"
                  to="/quests-wheel"
                >
                  Посмотреть квесты
                </Button>
              </ButtonsContainer>
            </Block>
          )}

          {visibleSettings?.a_vidget_active_quests && (
            <Block
              title="Активные квесты"
              rightButton={showAllActiveQuests}
              className={styles.activeQuests}
              id="active_quests"
            >
              <QuestsList
                quests={quests && quests.active && sortByComplete(Object.values(quests.active))}
                type="small"
                direction="row"
                emptyText="Нет активных квестов"
                showSign={true}
              />
            </Block>
          )}

          {visibleSettings?.a_vidget_old_quests && (
            <Block title="Пройденные квесты" rightButton={showAllOld}>
              <QuestsList
                quests={quests && quests.complete && sortByComplete(Object.values(quests.complete))}
                type="small"
                direction="row"
                emptyText="Нет пройденных квестов"
              />
            </Block>
          )}
        </>
      ) : (
        <div className="text-center" style={{ marginTop: '60px' }}>Добавьте минимум 1 ребенка, чтобы он смог выполнять квесты</div>
      )}

      <LoginPlayer
        user={user}
        player={user.current_player}
        players={user.player}
        pin_code={pin_code}
        ref={loginRef}
      />
      {user?.current_player &&
        <LoginModal
          open={isShowModal}
          player={user.current_player}
          onClose={() => setShowModal(false)}
          onClickLogin={handleClickLogin}
          onClickCopy={handleClickCopy}
          user_agent={settings?.user_agent}
        />
      }
    </MainLayout>
  );
}

export default Home;
