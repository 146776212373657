import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import ruPlural from '../../../helpers/ruPlural';

import { categories_bgs } from '../../../data/mock';

import styles from './quest-item.module.css';

const QuestItemLocked = ({ quest, prevName, lock }) => {
  const { quest_uid, name, reward_std } = quest;
  let { reward } = quest;
  const cid = quest_uid + '';
  const bg = categories_bgs[cid.slice(-1)];

  if (!reward) reward = reward_std;

  if (!quest_uid) return (
    <div className={classnames(styles.quest, 'skeleton-loader')} />
  );

  return (
    <div className={styles.quest} style={{ backgroundImage: `url(${bg}` }}>
      <div className={styles.title} dangerouslySetInnerHTML={{ __html: name }} />
      {lock && <div className={styles.overlay} />}
      <div className={styles.reward}>
        <div className={styles.rewardLabel}>
          Награда:
        </div>
        <div className={styles.rewardValue}>
          <span className={styles.rewardVal}>{reward ? reward : 0}</span> {ruPlural(reward, ['Балл', 'Балла', 'Баллов'])}
        </div>
      </div>
      <div className={styles.lockWrapper}>
        <div className={styles.lockContent}>
          <ReactSVG src="/icons/icon-lock-rounded.svg" className={styles.lockImage} />
          <div className={styles.lockNotice} dangerouslySetInnerHTML={{ __html: `Сначала нужно пройти “${prevName}”` }} />
        </div>
      </div>
    </div>
  );
};

export default QuestItemLocked;
