import { useSelector, useDispatch } from 'react-redux';
import Modal from '../../Modal';
import Video from '../../Video';
import useApiQuery from '../../../hooks/useApiQuery';
import { selectItem } from '../../../store/app/getters';
import { setItem } from '../../../store/app/actions';

import styles from './bottom-faq.module.css';

const BottomFaq = () => {
  const dispatch = useDispatch();
  const slug = useSelector(selectItem(['common', 'faq']));

  const handleHideModal = () => {
    dispatch(setItem(['common', 'faq'], null));
  };

  const { data: faq } = useApiQuery({
    name: ['faq', slug],
    path: `/faq/${slug}/`,
    skip: !slug,
  });

  return (
    <>
      {(faq && (faq.video || faq.description)) &&
        <Modal
          open={!!slug}
          viewStyle="bottom"
          onClose={handleHideModal}
          title={faq && faq.title}
        >
          {faq &&
            <div className={styles.content}>
              <div className={styles.text} dangerouslySetInnerHTML={{ __html: faq.description }} />
              {faq.video &&
                <div className={styles.media}>
                  <Video src={faq.video} controls={true} />
                </div>
              }
            </div>
          }
        </Modal>
      }
    </>
  );
};

export default BottomFaq;
