import { forwardRef, useState } from 'react';
import DatePicker from 'react-mobile-datepicker';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';

import './date-input.css';
import Input from '../Input';

const monthMap = [ 'Янв.', 'Фев.', 'Март', 'Апр.', 'Май', 'Июнь', 'Июль', 'Авг.', 'Сен.', 'Окт.', 'Нояб.', 'Дек.' ];

const DateInput = forwardRef(({ name, onChange, value, minDate, maxDate, ...props }, ref) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const handleChangeDate = (date) => {
    if (!date) return;
    const val = format(new Date(date), 'yyyy-MM-dd');
    onChange && onChange({target: { name: name, value: val }});
    setIsOpen(false);
  };

  return (
    <>
      <Input
        {...props}
        onClick={(e) => { setIsOpen(true); e.target.blur(); }}
        onFocus={(e) => { setIsOpen(true); e.target.blur(); }}
        name={name}
        value={value && format(new Date(value), 'dd MMMM yyyy', { locale: ru })}
        readOnly={true}
        autoComplete="off"
        type="text"
        ref={ref}
      />
      <DatePicker
        isOpen={isOpen}
        onSelect={handleChangeDate}
        onCancel={() => setIsOpen(false)}
        value={value ? new Date(value) : new Date(maxDate)}
        min={minDate}
        max={maxDate}
        dateConfig={{
          'date': {
            format: 'D',
            caption: 'Day',
            step: 1,
          },
          'month': {
            format: value => monthMap[value.getMonth()],
            caption: 'Mon',
            step: 1,
          },
          'year': {
            format: 'YYYY',
            caption: 'Year',
            step: 1,
          },
        }}
        confirmText="Ок"
        cancelText="Отмена"
      />
    </>
  );
});

export default DateInput;