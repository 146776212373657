import classNames from 'classnames';
import styles from './register-layout.module.css';

const RegisterLayout = ({ children, buttons, showLogo, className, style }) => {
  return (
    <div className={classNames(styles.container, className)} style={style}>
      <div className={classNames(styles.header, 'container')}>
        {showLogo && (
          <img src="/images/logo.svg" className={styles.logo} alt="Skillary" />
        )}
      </div>
      <div className={classNames(styles.content, 'container')}>
        {children}
      </div>
      {buttons &&
        <div className={classNames(styles.buttons, 'container')}>
          {buttons}
        </div>
      }
    </div>
  );
}

export default RegisterLayout;