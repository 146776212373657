import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { RegisterLayout } from '../../components/Layouts';
import PlayerLogin from '../../components/PlayerLogin';
import { selectItem } from '../../store/app/getters';

import styles from './account.module.css';

const CreatePlayerSuccess = () => {
  const navigate = useNavigate();
  const user = useSelector(selectItem(['user']));

  if (!user) return null;
  if (!user.player) navigate('/');

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '20px'
  };

  return (
    <RegisterLayout>
      <div className={styles.successPage}>
        <Slider {...settings}>
          {Object.values(user.created_players).map((uid, index) => {
            const player = user.player.find(({ uid_gamer }) => (Number(uid) === Number(uid_gamer)));
            const name = player.info ? player.info.name : player.name;
            return (
              <div key={uid}>
                <h1 className={styles.title}>Здесь находится аккаунт ребёнка {name}</h1>
                <PlayerLogin player={player} showStartButton={index === Object.values(user.created_players).length-1} />
              </div>
            )
          })}
        </Slider>
      </div>
      <div style={{ marginBottom: '40px' }} />
    </RegisterLayout>
  );
}

export default CreatePlayerSuccess;
