import classNames from 'classnames';
import Gallery from 'components/Gallery';

import styles from './homework.module.css';

const HomeworkComments = ({ user_type, is_rejected, is_done, oper_complete, type, type_name, aproove }) => {
  if (!(aproove.length || (!is_rejected && (oper_complete || is_done)))) return null;

  return (
    <div className={styles.messages}>
      <div className={classNames(styles.messagesTitle, (!is_rejected && (oper_complete || is_done)) && styles.success)}>
        {(!is_rejected && (oper_complete || is_done)) ? (is_done ? 'Выполнено!' : 'Проверено!') : `Найдено ${aproove.length} ошибок`}
      </div>
      <div className={styles.messagesList}>
        {(oper_complete || is_done) ? (
          <div className={classNames(styles.messageItem, styles[`type-${type}`] )}>
            <div className={styles.messageImages}>
              <img
                src={type === 2 ? 'https://play.skillary.club/person/101-3.svg' : 'https://play.skillary.club/person/201-3.svg'}
                className={styles.messageAvatar}
                alt=""
              />
            </div>
            <div className={styles.messageContent}>
              <b>{type_name} {type === 2 ? 'выполнена' : 'выполнен'} без ошибок!</b>
              {user_type === 'P' && <><br /> Теперь ждем подтверждение от родителя</>}
            </div>
          </div>
        ) : (
          aproove.map(({ uid, comment, emotion, file }) => (
            <div className={styles.messageItem} key={uid}>
              <div className={styles.messageImages}>
                <img src={emotion} className={styles.messageAvatar} alt="" />
                <div className={styles.messageGallery}>
                  <Gallery items={[ { link: file } ]} />
                </div>
              </div>
              <div className={styles.messageContent}>
                {comment}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default HomeworkComments;
