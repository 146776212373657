import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './onboarding.module.css';

const Onboarding = ({ items, onClose }) => {
  const navigate = useNavigate();
  const [ containerHeight, setContainerHeight ] = useState(0);
  const [ itemProps, setItemProps ] = useState({});

  useEffect(() => {
    if (items && items.length) {
      const item = items[0];
      const elem = item.selector && document.querySelector(item.selector);

      let params = {
        key: item.key,
        children: item.children,
        image: item.image,
        link: item.link,
        onClick: item.onClick,
        onClose: item.onClose
      };

      if ((item.selector && elem) || !item.selector) {
        if (elem) {
          const styles = elem && window.getComputedStyle(elem);
          const positions = elem && elem.getBoundingClientRect();

          const top = positions.top + document.documentElement.scrollTop;
          let left = positions.left + document.documentElement.scrollLeft;

          const scrollTop = top + positions.height - document.documentElement.clientHeight + 90;
          window.scrollTo(0, scrollTop);
          const list = elem.closest('.scroll-list');
          const scrollToLeft = positions.left + document.documentElement.scrollLeft - 20;

          if (list) {
            list.scrollTo(scrollToLeft, 0);
            left = 20;
          }

          params.style = {
            position: 'absolute',
            top: top,
            left: left,
            height: positions.height,
            width: positions.width,
            borderRadius: styles.borderRadius,
          };
        } else {
          params.style = {
            position: 'fixed',
            top: item.style.top,
            left: item.style.left,
            right: item.style.right,
            bottom: item.style.bottom
          }
        }

        setItemProps(params);
        setContainerHeight(document.documentElement.scrollHeight);
      }
    }
  }, [items]);

  const handleClickSpotlight = () => {
    if (itemProps.onClick) itemProps.onClick();
    if (itemProps.link) navigate(itemProps.link);
  };

  const handleClickOverlay = () => {
    setContainerHeight(0);
    if (itemProps.onClose) itemProps.onClose();
    setItemProps({});
    onClose(itemProps.key);
  };

  const handleClickPromt = () => {
    handleClickSpotlight();
    handleClickOverlay();
  };

  return (
    <>
      <div className={styles.overlay} style={{ height: containerHeight + 'px' }} onClick={handleClickOverlay}>
        <div className={styles.spotlight} style={itemProps.style} onClick={handleClickSpotlight} />
      </div>
      <div className={styles.container} style={{ height: containerHeight + 'px' }} onClick={handleClickPromt}>
        {itemProps.image && <div className={styles.forImage} style={itemProps.style}>{itemProps.image}</div>}
        {itemProps.children}
      </div>
    </>
  );
};

export default Onboarding;
