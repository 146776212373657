import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import Block from 'components/Block';
import UserHeader from 'components/UserHeader';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import { Skills } from 'components/Skills';
import { selectItem } from 'store/app/getters';

import styles from './player.module.css';

const Statistic = () => {
  const user = useSelector(selectItem(['user']));

  if (!user) return null;

  return (
    <MainLayout title="Навыки" infoSlug="statistic">
      <UserHeader user={user} />
      <ButtonsContainer className={styles.staticticButton}>
        <Button
          fullWidth={true}
          butStyle="primary"
          to="/ambitions"
        >
          Мои цели
        </Button>
      </ButtonsContainer>
      <div style={{ marginTop: '20px' }} />

      <Block title="Мои навыки">
        {user.skills && <Skills skills={user.skills} showDivider={true} />}
      </Block>
    </MainLayout>
  );
}

export default Statistic;
