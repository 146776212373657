import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import Modal from '../../Modal';
import Button from '../../Button';
import ButtonsContainer from '../../ButtonsContainer';
import * as api from '../../../helpers/api';
import { setItem } from '../../../store/app/actions';
import { selectItem } from '../../../store/app/getters';

const FeedbackPopup = () => {
  const dispatch = useDispatch();
  const reviewInput = useRef(null);
  const isShowReview = useSelector(selectItem(['common', 'feedback_popup', 'open']));

  const setShowReview = (open) => {
    dispatch(setItem(['common', 'feedback_popup', 'open'], open));
  };

  const handleClickSendReview = () => {
    if (reviewInput.current.value.length < 3) {
      reviewInput.current.classList.add('error')
    } else {
      reviewInput.current.classList.remove('error')

      const fd = new FormData();
      fd.append(`feedback`, reviewInput.current.value);

      api
        .post(`/feedback/`, fd)
        .then(response => {
          const result = response.data.response;
          if (result) {
            dispatch(setItem(['notifications', 'feedback'], { type: 'success', content: result }));
          } else {
            const error = response.data.error ? response.data.error : 'Ошибка отправки отзыва';
            dispatch(setItem(['notifications', 'feedback'], { type: 'error', content: error }));
          }

          setShowReview(false);
        })
        .catch(error => {
          dispatch(setItem(['notifications', 'feedback'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
        });
    }
  }

  return (
    <Modal
      viewStyle="bottom"
      open={isShowReview}
      onClose={() => setShowReview(false)}
    >
      <textarea
        id="feedback"
        name="feedback"
        rows="4"
        placeholder="Введите текст"
        maxLength="255"
        ref={reviewInput}
      />

      <ButtonsContainer>
        <Button
          butStyle="primary"
          fullWidth={true}
          onClick={handleClickSendReview}
        >
          Оставить отзыв
        </Button>
      </ButtonsContainer>
    </Modal>
  );
};

export default FeedbackPopup;
