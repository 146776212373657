import classnames from 'classnames';
import Button from '../../Button';
import ruPlural from '../../../helpers/ruPlural';

import styles from './tariffs.module.css';

const Tariffs = ({ tariffs, players = [], onSelect, selected }) => {
  return (
    <ul className={classnames(styles.list, 'scroll-list')}>
      {tariffs?.map(({ month, per_month, oldprice }) => (
        <li className={styles.item} key={month} style={{ backgroundImage: `url(/images/tariffs_bg/${month}.png)` }}>
          <div className={styles.title}>
            {month} {ruPlural(month, ['месяц', 'месяца', 'месяцев'])}
          </div>
          <div className={styles.descr}>
            {(players && players.length) ? (
              <>{per_month} х {players.length} = <span className="nowrap">{(per_month * players.length).toFixed(2)} ₽/месяц</span></>
            ) : (
              <>{per_month}</>
            )}
          </div>
          <div className={styles.descr}>
            <span className={styles.oldPrice}>{oldprice} ₽/месяц</span>
          </div>
          <div className={styles.button}>
            <Button
              butStyle={selected === month ? 'primary' : 'secondary'}
              size="small"
              fullWidth={true}
              onClick={() => onSelect(month)}
              disabled={!(players && players.length)}
            >
              {selected === month ? 'Выбрано' : 'Выбрать'}
            </Button>
          </div>
          {(players && players.length) &&
            <div className={styles.notice}>
              К оплате: {(per_month * players.length * month).toFixed(2)} ₽
            </div>
          }
        </li>
      ))}
    </ul>
  );
};

export default Tariffs;
