import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import copy from 'copy-to-clipboard';
import useApiQuery from 'hooks/useApiQuery';
import { MainLayout } from 'components/Layouts';
import { QuestDetails, QuestDescription, QuestSkills } from 'components/quests/Quest';
import Block from 'components/Block';
import * as api from 'helpers/api';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import Radio from 'components/fields/Radio';
import FileInput from 'components/fields/FileInput';
import Modal from 'components/Modal';
import Gallery from 'components/Gallery';
import InfoBlock from 'components/InfoBlock';
import { setItem, deleteItem, pushItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';

import styles from './Quests.module.css';

const baseUrl = process.env.REACT_APP_PUCLIC_URL;

const QuestPlayerButtons = ({ user, quest }) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const max_file_size = useSelector(selectItem(['settings', 'max_file_size']));
  const [ isPopupCancelShow, setPopupCancelShow ] = useState(false);
  const [ canceQuestReasons, setCanceQuestReasons ] = useState(null);
  const [ canceReason, setCanceReason ] = useState(null);

  const handleClickCancel = () => {
    if (!canceQuestReasons) {
      api
        .get('/getQuestCancelReason/')
        .then(response => {
          const result = response.data.response;
          if (result) {
            const reasons = Object.keys(result).map((key) => ({ label: result[key], value: key }));
            setCanceReason(Object.keys(result)[0]);
            setCanceQuestReasons(reasons);
            setPopupCancelShow(true);
          } else {
            const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
            dispatch(setItem(['notifications', 'getQuestCancelReason'], { type: 'error', title: 'Ошибка', content: error }));
          }
        })
        .catch(error => {
          dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
        });
    } else {
      setPopupCancelShow(true);
    }
  };

  const handleClickCancelQuest = () => {
    if (!canceReason) return;
    api
      .get(`/cancelUserQuest/${user.uid}/${quest.uid}/${canceReason}/`)
      .then(response => {
        const result = response.data.response;

        if (result.sucess) {
          dispatch(setItem(['user', 'active_quest_count'], user.active_quest_count-1));
          dispatch(setItem(['notifications', 'cancelUserQuest'], { type: 'success', content: result.sucess }));

          dispatch(deleteItem(['user_quests', user.uid, 'active'], quest.uid));
          dispatch(deleteItem(['user_quests', user.uid, 'query'], quest.uid));
          dispatch(deleteItem(['api'], `/getUserQuest/${user.uid}/${quest.uid}/`));

          navigate('/');
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'cancelUserQuest'], { type: 'error', title: 'Ошибка', content: error }));
        }

        setPopupCancelShow(false);
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const onChangeFile = (e) => {
    const file = e.target.files[0];

    if (!file) return null;
    const isVideo = !!(file.type.match(/video*/));
    if (file.size > max_file_size) {
      dispatch(setItem(['notifications', 'upload'], { type: 'error', title: 'Ошибка', content: 'Макс. размер файла 20Мб' }));
      return null;
    }

    const fd = new FormData();
    fd.append('file', file, file.name);
    fd.append('quest', quest.uid);
    fd.append('is_video', isVideo ? 1 : 0);

    api
      .post(`/uploadUserFile/`, fd, { mimeType: 'multipart/form-data', contentType: false })
      .then(response => {
        const result = response.data.response;

        if (result && result.file) {
          dispatch(setItem(['notifications', 'upload'], { type: 'success', content: 'Файл успешно загружен' }));
          dispatch(pushItem(['user_quests', user.uid, 'query', quest.uid, 'galery'], { ...result, link: result.url }));
        } else {
          const error = response.data.error ? response.data.error : 'Ошибка загрузки файла';
          dispatch(setItem(['notifications', 'upload'], { type: 'error', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickDisabledSubmit = () => {
    dispatch(setItem(['notifications', 'submit'], { type: 'error', content: 'Загрузи фото выполненного квеста' }));
  };

  return (
    <>
      <ButtonsContainer>
        <FileInput
          id="photo"
          name="photo"
          onChange={e => onChangeFile(e)}
        />
        {/* {(quest.start && !quest.is_cancel && !quest.is_complete) &&
          <Button
            butStyle="secondary"
            size="medium"
            fullWidth={true}
            to={quest.rating ? `/get-quest-approve/${quest.uid}` : `/rate-quest/${quest.uid}`}
            disabled={user.score_day >= user.reward_limit}
          >
            Фотографию загрузит Подтверждающий
          </Button>
        } */}

        {(quest.is_complete && (!quest.rating || quest.rating === 0)) &&
          <Button
            fullWidth={true}
            butStyle="primary"
            to={`/rate-quest/${quest.uid}`}
          >
            Оценить квест
          </Button>
        }

        {(quest.start && !quest.is_cancel && !quest.is_complete) &&
          <>
            {user.score_day >= user.reward_limit &&
              <InfoBlock color="orange">Вы набрали максимальное количество баллов за сегодня и сможете выполнить данный квест только завтра</InfoBlock>
            }
            <Button
              butStyle="secondary"
              size="medium"
              onClick={() => handleClickCancel()}
            >
              Отменить квест
            </Button>
            {(quest.galery && quest.galery.length) ? (
              <Button
                butStyle="primary"
                size="medium"
                to={quest.rating ? `/get-quest-approve/${quest.uid}` : `/rate-quest/${quest.uid}`}
                disabled={user.score_day >= user.reward_limit}
              >
                Я выполнил квест
              </Button>
            ) : (
              <Button
                butStyle="primary"
                size="medium"
                onClick={handleClickDisabledSubmit}
                className={styles.disabled}
              >
                Я выполнил квест
              </Button>
            )}
          </>
        }
      </ButtonsContainer>
      <Modal
        title="Причина отмены квеста"
        viewStyle="bottom"
        open={isPopupCancelShow}
        onClose={() => setPopupCancelShow(false)}
        buttons={[
          { text: 'Отменить квест', action: 'click', style: 'primary', onClick: () => handleClickCancelQuest() }
        ]}
      >
        <Radio
          id="cancel_reason"
          name="cancel_reason"
          options={canceQuestReasons}
          value={canceReason}
          onChange={e => setCanceReason(e.target.value)}
        />
      </Modal>
    </>
  );
};

const UserQuest = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { qid } = useParams();
  const user_agent = useSelector(selectItem(['settings', 'user_agent']));
  const user = useSelector(selectItem(['user']));
  const uid = user.type === 'M' ? user.current_player?.uid_gamer : user.uid;
  const [ isShareShow, setShareShow ] = useState(false);

  const { data: quest, error } = useApiQuery({
    name: ['user_quests', uid, 'query', qid],
    path: `/getUserQuest/${uid}/${qid}/`,
    mapper: (data = {}, prev) => {
      if (!data.query) return null;

      let next = {};

      for (let key in data) {
        if (data[key] && data[key].length) {
          for (let questKey in data[key]) { next = data[key][questKey]; }
        }
      }

      return {...prev, ...next};
    }
  });

  useEffect(() => {
    if (error) {
      dispatch(setItem(['notifications', 'request'], { type: 'error', content: error }));
      navigate('/', { replace: true });
    }
    if (quest === null) {
      dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Квест не найден или у Вас нет доступа' }));
      navigate('/', { replace: true });
    }
    // eslint-disable-next-line
  }, [quest, error]);

  const approveGallery = (quest && quest.aproove && quest.aproove.send_aproove) ?
    Object.values(quest.aproove.send_aproove).reduce((prev, { photo, uid }) => {
      if (photo) return [ ...prev, { link: photo, uid: uid } ];
      return prev;
    }, []) : null;

  const handleClickShare = () => {
    if (user_agent === 'skillary-mobile') handleClickInvite();
    else setShareShow(true);
  }

  const handleClickInvite = () => {
    const res = copy(`${baseUrl}/quest/${quest.quest_uid}`);
    if (res) dispatch(setItem(['notifications', 'copyInvite'], { type: 'success', content: 'Ссылка на квест скопирована!' }));
    else dispatch(setItem(['notifications', 'copyInvite'], { type: 'error', content: 'Невозможно скопировать ссылку' }));
  };

  return (
    <MainLayout title={quest && (quest.info ? quest.info.name : quest.name)}>
      <QuestDetails user={user} quest={quest} />
      <QuestSkills quest={quest} />

      {quest && (
        <ButtonsContainer>
          <Button
            fullWidth={true}
            butStyle="tertiary"
            onClick={handleClickShare}
            to="#share-link"
            id="#share-link"
          >
            Поделиться квестом!
          </Button>
        </ButtonsContainer>
      )}

      <Modal
        open={isShareShow}
        onClose={() => setShareShow(false)}
        title="Поделись квестом с другом, чтобы вместе выполнить его!"
        viewStyle="bottom"
        buttons={[
          { text: 'Скопировать ссылку', action: 'close', style: 'primary', onClick: () => handleClickInvite() }
        ]}
      >
        Чтобы поделиться квестом с другом, отправь ему ссылку любым удобным способом
      </Modal>
      <div style={{ marginTop: '20px' }} />

      {quest && (
        <Block title="Задание">
          <QuestDescription user={user} quest={quest} />
          {quest.info.link &&
            <ButtonsContainer>
              <Button butStyle="secondary" size="small" fullWidth="true" href={quest.info.link}>Дополнительно</Button>
            </ButtonsContainer>
          }
          {(approveGallery && approveGallery.length) ? (
            <div className={styles.gallery}>
              <Gallery items={approveGallery.reverse()} />
            </div>
            ) : null
          }
          {(quest.galery && quest.galery.length) ? (
            <div className={styles.gallery}>
              <Gallery items={[...quest.galery].reverse()} />
            </div>
            ) : null
          }
          <QuestPlayerButtons user={user} quest={quest} />
        </Block>
      )}
    </MainLayout>
  );
};

export default UserQuest;
