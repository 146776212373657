import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { selectItem } from 'store/app/getters';
import { scrollToX } from 'helpers/scroll';
import BalanceItem from './BalanceItem';
import Scores from './blocks/Scores';
import Grants from './blocks/Grants';
import Ambitions from './blocks/Ambitions';
import Rewards from './blocks/Rewards';

import styles from './balance.module.css';

const Balance = ({ user, crystalls, score, ambitions, stipendion, size, rewards, showSeeAll }) => {
  const navigate = useNavigate();
  const list = useRef(null);
  const loaded = !!rewards && !!ambitions && !!stipendion;
  const visibleSettings = useSelector(selectItem(['visible_settings']));

  useEffect(() => {
    if (loaded && user && user.type === 'M') {
      // scroll to placeholders
      const childs = list.current.children;
      if (childs.length) {
        const left = childs[childs.length - 1].getBoundingClientRect().left;

        setTimeout(() => {
          scrollToX(list.current, left, 800);
          setTimeout(() => scrollToX(list.current, 0, 800), 1300);
        }, 1000);
      }
    }

    // eslint-disable-next-line
  }, [loaded]);

  return (
    <div className={classnames(styles.list, 'scroll-list', size && styles[size])} ref={list}>
      {(score && visibleSettings?.a_vidget_balance) &&
        <BalanceItem>
          <Scores score={score} crystalls={crystalls} />
        </BalanceItem>
      }
      {(user && visibleSettings?.a_vidget_rewards) && (
        <BalanceItem
          title="Награды"
          isLoading={!rewards}
          id="ob_rewards"
          link={user.type === 'M' && '/update-rewards'}
          onClick={() => user.type === 'P' && navigate('/rewards-from-mentor')}
          showSeeAll={showSeeAll}
        >
          {rewards && <Rewards user={user} items={rewards} />}
        </BalanceItem>
      )}
      {visibleSettings?.a_vidget_ambitions && (
        <Ambitions user={user} items={ambitions} />
      )}
      {visibleSettings?.a_vidget_stipendy && (
        ((stipendion && stipendion.length) || (user && user.type === 'M')) ? <Grants user={user} items={stipendion} /> : null
      )}
    </div>
  );
};

export default Balance;
