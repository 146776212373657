import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { useParams, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { MainLayout } from 'components/Layouts';
import Homework from 'components/homework/Homework';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';
import { pushItem, setItem, sliceItem } from 'store/app/actions';
import * as api from 'helpers/api';

const HomeworkPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { date } = useParams();
  const { hash } = useLocation();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const settings = useSelector(selectItem(['settings']));
  const max_file_size = settings?.max_file_size;

  const { data: homeworkData } = useApiQuery({
    name: ['homework', currentPlayer.uid_gamer, date],
    path: `/api/getuserdomashka/${currentPlayer.uid_gamer}/${date}/`,
    skip: !user || !currentPlayer || !date,
    update_param: hash
  });

  const homework = homeworkData?.tasks;

  const sendRequest = (task_uid, status, value) => {
    const fd = new FormData();
    fd.append('task_uid', task_uid);
    fd.append('user_uid', currentPlayer.uid_gamer);
    if (value) fd.append('value', value);
    if (status) fd.append('param', status);

    api
      .post(`/getuserdomashka/`, fd)
      .then(response => {
        const result = response.data.response;

        if (result) {
          const index = homework.tasks.findIndex(({ uid }) => uid === task_uid);
          if (index !== -1) {
            dispatch(setItem(['homework', currentPlayer.uid_gamer, date, 'tasks', 'tasks', index], result.tasks, { isHard: true }));
          }

          let notification = '';
          if (status === 'is_rejected') notification = 'Домашка отклонена';
          else if (status === 'is_done') notification = 'Домашка выполнена';
          else if (status === 'comment') notification = 'Комментарий сохранен';
          else notification = 'Сохранено';

          dispatch(setItem(['notifications', 'updateTask'], { type: 'success', content: notification }));
        }
        if (response.data.error) {
          dispatch(setItem(['notifications', 'updateTask'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  const handleClickReject = (task_uid) => {
    sendRequest(task_uid, 'is_rejected', 1);
  };

  const handleClickSubmit = (task_uid, withBonus) => {
    if (currentPlayer && currentPlayer.info.tariff && currentPlayer.info.tariff.end && new Date(currentPlayer.info.tariff.end) < new Date()) {
      navigate(`/select-tariff?from=homework&player=${currentPlayer.uid_gamer}`);
    } else {
      sendRequest(task_uid, 'is_done', withBonus ? 777 : 1);
    }
  };
console.log(homeworkData)
  const handleChangeFile = (file, task_uid, task_type, old_file) => {
    if (!file) return null;
    const isVideo = !!(file.type.match(/video*/));
    if (file.size > max_file_size) {
      dispatch(setItem(['notifications', 'upload'], { type: 'error', title: 'Ошибка', content: 'Макс. размер файла 20Мб' }));
      return null;
    }

    const fd = new FormData();
    fd.append('file', file, file.name);
    fd.append('user_uid', user.uid);
    fd.append('is_video', isVideo ? 1 : 0);
    fd.append('task_type', task_type);
    fd.append('task_uid', task_uid);
    if (old_file) fd.append('old_file', old_file);

    dispatch(setItem(['show_loader'], true));

    api
      .post(`/uploaddomashkafiles/`, fd, { mimeType: 'multipart/form-data', contentType: false })
      .then(response => {
        const result = response.data.response;

        if (result && result.file) {
          dispatch(setItem(['notifications', 'upload'], { type: 'success', content: 'Файл успешно загружен' }));

          const index = homeworkData.tasks.tasks.findIndex(({ uid }) => uid === task_uid);
          if (index !== -1) {
            if (!old_file) dispatch(pushItem(['homework', currentPlayer.uid_gamer, date, 'tasks', 'tasks', index, 'files'], { file_url: result.url }));
            else {
              const fileIndex = homeworkData.tasks.tasks[index].files.findIndex(({ file_url }) => old_file === file_url);
              if (fileIndex !== -1) {
                dispatch(sliceItem(['homework', currentPlayer.uid_gamer, date, 'tasks', 'tasks', index, 'files'], fileIndex));
                dispatch(pushItem(['homework', currentPlayer.uid_gamer, date, 'tasks', 'tasks', index, 'files'], { file_url: result.url }));
              }
            }
          }
        } else {
          const error = response.data.error ? response.data.error : 'Ошибка загрузки файла';
          dispatch(setItem(['notifications', 'upload'], { type: 'error', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      })
      .then(() => {
        dispatch(setItem(['show_loader'], false));
      });
  };

  const handleSendComment = (task_uid, text) => {
    sendRequest(task_uid, 'comment', text);
  };

  return (
    <MainLayout title={`Домашка ${(homework && homework.info) ? format(new Date(homework.info.date), 'dd.MM.yyyy') : ''}`} showBackButton={true}>
      <Homework
        user={user}
        content={homeworkData?.html}
        info={homework?.info}
        tasks={homework?.tasks}
        onClickReject={handleClickReject}
        onClickSubmit={handleClickSubmit}
        onSendComment={handleSendComment}
        onChangeFile={handleChangeFile}
        settings={settings}
      />
    </MainLayout>
  );
};

export default HomeworkPage;