import { useParams, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FSSlider from '../../components/FSSlider';
import { MainLayout } from '../../components/Layouts';
import useApiQuery from '../../hooks/useApiQuery';
import { selectItem } from '../../store/app/getters';

const AboutVideo = () => {
  const { slug } = useParams();
  const user = useSelector(selectItem(['user']));

  const { data, isFail } = useApiQuery({
    name: ['stories', user.type, slug],
    path: `/stories/${user.type}/${slug}/`,
    skip: !user,
    save_history: true,
    mapper: (data = []) => {
      return data.map(item => ({ video: item }));
    }
  });

  if (isFail) return <Navigate replace to="/404" />;

  return (
    <MainLayout contentValign="stretch">
      {data && <FSSlider items={data} isVideo={true} withMenu={true} />}
    </MainLayout>
  );
}

export default AboutVideo;
