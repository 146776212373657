import { useEffect, useState, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import Button from '../../Button';

import baseStyles from '../fields.module.css';
import styles from './quantity.module.css';

const Quantity = ({ id, onClick, onChange, onBlur, name, placeholder, autoFocus, disabled, hidden, defaultValue = 0, min = 0, max = 10000, step = 1,
  containerClass, title, label, fullWidth, error, icon, notice, pattern }) => {
  const [ timeout, setDelay ] = useState(null);
  const [ interval, setUpdate ] = useState(null);
  const [ value, setValue ] = useState(defaultValue);
  const ref = useRef(null);

  const handleMouseDown = (action, value) => {
    let val = value;

    if (action === 'inc') {
      if (val+step > max) return null;
      setValue(val += step);

      setDelay(setTimeout(() => {
        setUpdate(setInterval(() => {
          if (val+step >= max) return null;

          if (val <= 0) val = 0;
          else val += step;

          setValue(val);
        }, 10));
      }, 500));
    } else {
      if (val-step < min) return null;
      val = val <= 0 ? 0 : val-step;
      setValue(val);

      setDelay(setTimeout(() => {
        setUpdate(setInterval(() => {
          if (val-step < min) return null;

          if (val <= 0) val = 0;
          else val -= step;

          setValue(val);
        }, 10));
      }, 500));
    }
  };

  const handleMouseUp = () => {
    clear();
  };

  const clear = () => {
    clearTimeout(timeout);
    clearInterval(interval);
  }

  useEffect(() => {
    if (!ref.current || !ref.current.state || !onChange) return null;
    onChange({ target: { value: value } });
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    return clear();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classnames(
      baseStyles.container,
      styles.container,
      hidden && baseStyles.hidden,
      fullWidth && baseStyles.fullWidth,
      containerClass && baseStyles[containerClass]
    )}>
      <div className={styles.wrap}>
        {title && <div className={classnames(baseStyles.title, styles.title)}>{title}</div>}
        {label && <label className={baseStyles.label} htmlFor={id}>{label}</label>}
        <div className={classnames(baseStyles.inputContainer, styles.inputContainer)}>
          {disabled ? (
            <input
              type="text"
              defaultValue={value}
              className={classnames(baseStyles.input, styles.input)}
              disabled={disabled}
            />
          ) : (
            <>
              <Button
                butStyle="secondary"
                butClass={styles.button}
                className={styles.button}
                onMouseDown={() => handleMouseDown('dec', value)}
                onMouseUp={() => handleMouseUp()}
                onMouseLeave={() => handleMouseUp()}
                onClick={(e) => { e.preventDefault(); }}
                disabled={!!(value <= min)}
              >
                <ReactSVG src="/icons/icon-minus.svg" className={styles.buttonIcon} wrapper="span" />
              </Button>
              <NumberFormat
                id={id}
                name={name}
                placeholder={placeholder}
                onChange={e => setValue(e.target.value)}
                onClick={onClick}
                onBlur={onBlur}
                type="text"
                className={classnames(baseStyles.input, styles.input, icon && baseStyles.withIcon, error && 'error', value && 'filled')}
                autoFocus={autoFocus}
                disabled={disabled}
                defaultValue={defaultValue}
                value={value}
                pattern={pattern}
                ref={ref}
                mask={'#'}
              />
              <Button
                butStyle="secondary"
                butClass={styles.button}
                className={styles.button}
                onMouseDown={() => handleMouseDown('inc', value)}
                onMouseUp={() => handleMouseUp()}
                onMouseLeave={() => handleMouseUp()}
                onClick={(e) => { e.preventDefault(); }}
                disabled={!!(value >= max)}
              >
                <ReactSVG src="/icons/icon-plus.svg" className={styles.buttonIcon} wrapper="span" />
              </Button>
            </>
          )}

          {icon &&
            <span className={baseStyles.icon}>{ icon }</span>
          }
        </div>
      </div>
      {error &&
        <span className={baseStyles.errorText}>{ error }</span>
      }
      {notice &&
        <span className={baseStyles.notice}>{ notice }</span>
      }
    </div>
  );
};

export default Quantity;