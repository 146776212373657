import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import FSSlider from 'components/FSSlider';
import { MainLayout } from 'components/Layouts';
// import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
import { comics } from 'data/mock';

const Comics = () => {
  // const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));

  useEffect(() => {
    localStorage?.setItem(`guide_showed_${user.uid}`, true);
    // eslint-disable-next-line
  }, []);

  return (
    <MainLayout contentValign="stretch">
      <FSSlider
        items={comics}
        exitLink={'/game'}
        // onExit={() => dispatch(setItem(['service', 'chat_open'], true))}
      />
    </MainLayout>
  );
};

export default Comics;