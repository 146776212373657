import { useState, forwardRef, useEffect, memo } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import * as api from 'helpers/api';
import useDebounce from 'hooks/useDebounce';
import { setItem } from 'store/app/actions';

import SelectPopup from './components/SelectPopup';
import EmptyPopup from './components/EmptyPopup';

import selectStyles from './drop-select.module.css';
import styles from '../fields.module.css';

const findLabel = (options, value) => {
  if (options.length && value) return options.find(item => String(item.value) === String(value))?.label;
  return null;
};

const Select = forwardRef(({ id, onChange, name, value, placeholder, options: _options = [], isSearchable = false, searchPlaceholder, className,
  title, label, icon, submitText, loadUrl, mapper = (data) => data, modalTitle,
  disabled, error, inputClass, customParams }, ref) => {
    const dispatch = useDispatch();
    const [ options, setOptions ] = useState(_options);
    const [ popupOptions, setPopupOptions ] = useState(options);
    const [ tempValue, setTempValue ] = useState(value);
    const [ isLoading, setLoading ] = useState(false);
    const [ isPopupShow, setPopupShow ] = useState(false);
    const [ errorText, setErrorText ] = useState(null);

    // for custom value
    const [ isCustomShow, setCustomShow ] = useState(false);
    const [ isEnterShow, setEnterShow ] = useState(false);

    useEffect(() => {
      if (loadUrl && !isLoading && !popupOptions.length && !isSearchable) loadOptions();
      // eslint-disable-next-line
    }, []);

    useEffect(() => {
      if (!isSearchable && !loadUrl && JSON.stringify(_options) !== JSON.stringify(options)) {
        setOptions(_options);
        setPopupOptions(_options);
      }
      // eslint-disable-next-line
    }, [_options]);

    const handleSearchChange = useDebounce((val) => loadOptions(val), 500, true);

    const loadOptions = (query) => {
      if (isSearchable && !query) return null;
      const url = isSearchable ? `${loadUrl}/${query}/` : `${loadUrl}/`;
      setLoading(true);
      dispatch(setItem(['show_loader'], true));

      api
        .get(url)
        .then(response => {
          const result = response.data;

          if (result.response) {
            const data = mapper(result.response);
            if (!isSearchable) setOptions(data);
            setPopupOptions(data);
            setCustomShow(false);
            setErrorText(null);
          }

          if (result.error) {
            if (customParams && customParams.buttonText) setCustomShow(true);
            setErrorText(result.error);
            setPopupOptions([]);
          }
        })
        .catch(error => {
          setErrorText('Ошибка загрузки результатов');
          setPopupOptions([]);
          setCustomShow(false);
        })
        .then(() => {
          setLoading(false);
          dispatch(setItem(['show_loader'], false));
        });
    };

    const setNewValue = (value) => {
      if (!value) return null;

      const fd = new FormData();
      fd.append('name', value);
      fd.append('city', customParams.city);
      fd.append('user', customParams.user_uid);
      api
        .post(customParams.buttonUrl, fd)
        .then(response => {
          const result = response.data.response;
          if (result.main) {
            const res_option = { label: result.main.name, value: result.main.school };
            setOptions([res_option]);
            setPopupOptions([res_option]);
            setTempValue(result.main.school);
            onChange(res_option);
            onCloseEmptyPopup();
          } else {
            dispatch(setItem(['notifications', customParams.buttonUrl], { type: 'error', content: result.error }));
          }
        })
        .catch(error => {
          dispatch(setItem(['notifications', customParams.buttonUrl], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
        });
    };

    const buttons = (isCustomShow) => {
      if (isCustomShow && customParams)
        return [{
          text: customParams.buttonText,
          action: 'click',
          style: 'primary',
          onClick: () => {
            setPopupShow(false);
            setEnterShow(true);
          }
        }];

      else
        return [{
          text: submitText ? submitText : 'Выбрать',
          action: 'click',
          style: 'primary',
          disabled: !tempValue,
          onClick: () => {
            setPopupShow(false);
            if (popupOptions.length) {
              setOptions(popupOptions);
              onChange({ label: findLabel(popupOptions, tempValue), value: tempValue });
            }
          }
        }];
    };

    const onCloseEmptyPopup = () => {
      setEnterShow(false);
      setCustomShow(false);
    };

    const onCloseSelectPopup = () => {
      setPopupShow(false);
      setErrorText(false);
      setCustomShow(false);
    };

    return (
      <div>
        {customParams &&
          <EmptyPopup
            isShow={isEnterShow}
            title={customParams.inputText}
            buttonUrl={customParams.buttonUrl}
            onSubmit={value => setNewValue(value)}
            onClose={() => onCloseEmptyPopup()}
          />
        }
        <SelectPopup
          label={label}
          title={title}
          modalTitle={modalTitle}
          isPopupShow={isPopupShow}
          setPopupShow={setPopupShow}
          buttons={buttons(isCustomShow)}
          isSearchable={isSearchable}
          searchPlaceholder={searchPlaceholder}
          onChange={handleSearchChange}
          id={id}
          name={name}
          options={popupOptions}
          tempValue={tempValue}
          errorText={errorText}
          setTempValue={setTempValue}
          onClose={() => onCloseSelectPopup()}
        />
        <input
          type="hidden"
          id={id}
          name={name}
          value={value}
          readOnly={true}
          ref={ref}
        />
        <div
          onClick={() => {
            setPopupShow(true);
            if (!isLoading && loadUrl) loadOptions();
          }}
          onFocus={() => {
            setPopupShow(true);
            if (!isLoading && loadUrl) loadOptions();
          }}
          className={classnames('input', styles.input, selectStyles.input, icon && styles.withIcon, inputClass, className, error && 'error', value && 'filled', disabled && 'disabled')}
        >{value ? findLabel(options, value) : <span className="input-placeholder">{placeholder}</span>}</div>
      </div>
    )
  }
);

const DropSelect = forwardRef(({ id, control, onChange, name, title, label, icon, fullWidth, value, modalTitle,
  error, hidden, notice, containerClass, iconClassName, ...props }, ref) => {
  return (
    <div className={classnames(styles.container, fullWidth && styles.fullWidth, containerClass && styles[containerClass], hidden && styles.hidden)}>
      {title && <div className={styles.title}>{title}</div>}
      {label && <label className={styles.label} htmlFor={id}>{label}</label>}
      <div className={styles.inputContainer}>
        <Select
          {...props}
          id={id}
          name={name}
          value={value}
          title={title}
          label={label}
          icon={icon}
          error={error}
          modalTitle={modalTitle}
          // onChange={option => { onChange && onChange(option.value); }}
          onChange={option => { onChange({ target: { name: name, value: option.value, label: option.label } }); } }
          ref={ref}
        />
        {icon &&
          <span className={classnames(styles.icon, selectStyles.icon, iconClassName)}>{ icon }</span>
        }
      </div>
      {error &&
        <span className={styles.errorText}>{ error }</span>
      }
      {notice &&
        <span className={styles.notice}>{ notice }</span>
      }
    </div>
  );
});

export default memo(DropSelect);