import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { Navigate } from 'react-router-dom';
import { GameLayout } from '../../components/Layouts';
import { selectItem } from '../../store/app/getters';
import useApiQuery from '../../hooks/useApiQuery';
import GameChat from '../../components/GameChat';
import * as api from '../../helpers/api';
import { setItem } from '../../store/app/actions';

const Chat = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const gameParams = useSelector(selectItem(['game_params'], {}));
  const [ update, setUpdate ] = useState(null);

  useEffect(() => {
    setUpdate(new Date());
  }, []);

  const { data: game, isSuccess } = useApiQuery({
    name: ['game', user.uid],
    path: `/gameprogress/${user.uid}/`,
    skip: !user,
    save_history: true,
    update_param: gameParams && gameParams.city + update
  });

  const handleClickAnswer = (dialog_id, message_key, ask_uid, variant) => {
    api
      .get(`/gameaskanswer/${user.uid}/${ask_uid}/${variant}/`)
      .then(response => {
        const result = response.data.response;

        if (result) {
          dispatch(setItem(['game', user.uid, 'dialogs', dialog_id, 'dialog', message_key, 'ask'], result, { isHard: true }));
        }
      });
  };

  if (!game && !isSuccess) return null;

  const currentPoint = game && Object.values(game.points).find(({ is_active }) => !!is_active);

  // if (!currentPoint || !game.dialogs[currentPoint.uid].dialog  || !game.dialogs[currentPoint.uid].dialog.length)
  //   return <Navigate to="/game" replace={true} />

  return (
    <GameLayout game={game}>
      {user && <GameChat user={user} chat={game.dialogs} current={currentPoint.uid} onAnswer={handleClickAnswer} />}
    </GameLayout>
  );
};

export default Chat;