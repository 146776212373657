export function drawing(options) {
  let canvas = null;
  let canvasBg = null;

  const def = {
    selectedTool: 'brush',
    brushSize: 4,
    color: '#000',
    angle: 0,
    canvasSize: [],
    points: []
  };

  const state = { ...def, ...options };

  this.init = () => {
    if (!state.container) return;

    canvasBg = state.container.appendChild(document.createElement('canvas'));
    canvasBg.style.position = 'absolute';
    canvasBg.width = options.canvasSize[0];
    canvasBg.height = options.canvasSize[1];

    canvas = state.container.appendChild(document.createElement('canvas'));
    canvas.style.position = 'relative';
    canvas.width = options.canvasSize[0];
    canvas.height = options.canvasSize[1];

    this.addEvents();
  };

  this.setBackground = (color) => {
    const ctx = canvasBg.getContext('2d');
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, canvasBg.width, canvasBg.height);
    ctx.fillStyle = state.color;
    state.backgroundColor = color;
  };

  this.setBackgroundImage = (image) => {
    const ctx = canvasBg.getContext('2d');
    ctx.drawImage(image, 0, 0, options.canvasSize[0], options.canvasSize[1]);
    state.backgroundImage = image;
  };

  this.setStyleSizes = (width, height) => {
    canvasBg.style.width = width + 'px';
    canvasBg.style.height = height + 'px';

    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';
  };

  this.getTouchPositions = (e) => {
    const screenX = e.clientX;
    const screenY = e.clientY;
    const canvasPositions = canvas.getBoundingClientRect();
    const scale = canvas.width / canvasPositions.width;

    const x = canvasPositions.x < 0 ? (screenX + Math.abs(canvasPositions.x)) * scale : (screenX - Math.abs(canvasPositions.x)) * scale;
    const y = canvasPositions.y < 0 ? (screenY + Math.abs(canvasPositions.y)) * scale : (screenY - Math.abs(canvasPositions.y)) * scale;

    return [ x, y ];
  }

  this.addEvents = () => {
    canvas.addEventListener('touchstart', this.touchStart);
    canvas.addEventListener('touchmove', this.touchMove);
    canvas.addEventListener('touchscancel', this.touchEnd);
    canvas.addEventListener('touchend', this.touchEnd);

    canvas.addEventListener('mousedown', this.touchStart);
    canvas.addEventListener('mousemove', this.touchMove);
    canvas.addEventListener('mouseout', this.touchEnd);
    canvas.addEventListener('mouseup', this.touchEnd);
  };

  this.removeEvents = () => {
    canvas.removeEventListener('touchstart', this.touchStart);
    canvas.removeEventListener('touchmove', this.touchMove);
    canvas.removeEventListener('touchscancel', this.touchEnd);
    canvas.removeEventListener('touchend', this.touchEnd);

    canvas.removeEventListener('mousedown', this.touchStart);
    canvas.removeEventListener('mousemove', this.touchMove);
    canvas.removeEventListener('mouseout', this.touchEnd);
    canvas.removeEventListener('mouseup', this.touchEnd);
  };

  this.touchStart = (e) => this.startDraw(...this.getTouchPositions(e.changedTouches ? e.changedTouches[0] : e), { isLog: true });
  this.touchMove = (e) => this.drawing(...this.getTouchPositions(e.changedTouches ? e.changedTouches[0] : e), { isLog: true });
  this.touchEnd = (e) => this.endDraw(...this.getTouchPositions(e.changedTouches ? e.changedTouches[0] : e), { isLog: true });
  this.touchStart = (e) => this.startDraw(...this.getTouchPositions(e.changedTouches ? e.changedTouches[0] : e), { isLog: true });

  this.startDraw = (x, y, options = {}) => {
    const ctx = canvas.getContext('2d');
    const { isLog = false, size = state.brushSize, color = state.color } = options;

    state.isDrawing = true;
    state.prevMouseX = x;
    state.prevMouseY = y;
    ctx.beginPath();
    ctx.lineCap = 'round';
    ctx.lineWidth = size;
    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    ctx.moveTo(x+0.5,y+0.5);

    if (isLog) state.points.push({ x, y, size, color, mode: 'begin' });
    if (isLog) this.drawing(x, y, isLog);
  };

  this.drawing = (x, y, options = {}) => {
    if (!state.isDrawing) return;
    const { isLog = false, size = state.brushSize, color = state.color, tool = state.selectedTool } = options;
    const ctx = canvas.getContext('2d');

    ctx.globalCompositeOperation = tool === 'brush' ? 'source-over' : 'destination-out';
    ctx.strokeStyle = color;
    ctx.lineTo(x+0.5, y+0.5);
    ctx.stroke();
    if (isLog) state.points.push({ x, y, size, color, tool, mode: 'draw' });
  };

  this.endDraw = (x, y, options = {}) => {
    if (!state.isDrawing) return;
    const { isLog = false } = options;

    state.isDrawing = false;
    if (isLog) state.points.push({ x, y, mode: 'end' });
  };

  this.redrawAll = () => {
    if (state.points.length === 0) return;

    for (let i=0; i<state.points.length; i++) {
      const pt = state.points[i];

      let begin = false;
      // if ((pt.brushSize && ctx.lineWidth !== pt.brushSize) || (pt.color && ctx.strokeStyle !== pt.color)) begin = true;

      if (pt.mode === 'end' || (i === state.points.length-1))
        this.endDraw(pt.x, pt.y);
      else if (pt.mode === 'begin' || begin)
        this.startDraw(pt.x, pt.y, { color: pt.color, size: pt.size, tool: pt.tool });
      else
        this.drawing(pt.x, pt.y, { color: pt.color, size: pt.size, tool: pt.tool });
    }
  }

  this.undoLast = () => {
    const ctx = canvas.getContext('2d');
    const lastStartIndex = state.points.findLastIndex(({ mode }) => mode === 'begin');
    state.points.splice(lastStartIndex);
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    this.redrawAll();
  };

  this.setSelectedTool = (tool) => {
    state.selectedTool = tool;
  };

  this.setBrushSize = (size) => {
    state.brushSize = size;
  };

  this.setColor = (color) => {
    state.color = color;
    state.selectedTool = 'brush';
  };

  this.rotateLeft = () => {
    const angle = state.angle === -360 ? -90 : state.angle - 90;
    this.rotateBg(angle);
    state.angle = angle;
    return this.rotate(angle);
  };

  this.rotateRight = () => {
    const angle = state.angle === 360 ? 90 : state.angle + 90;
    this.rotateBg(angle);
    state.angle = angle;
    return this.rotate(angle);
  };

  this.rotateBg = async (angle) => {
    const ctx = canvasBg.getContext('2d');

    // setNewCanvasSize
    const oldWidth = canvasBg.width;
    const oldHeight = canvasBg.height;
    ctx.canvas.width = oldHeight;
    ctx.canvas.height = oldWidth;
    this.setStyleSizes(window.innerWidth, canvasBg.parentElement.clientHeight);

    // clear
    ctx.clearRect(0, 0, canvasBg.width, canvasBg.height);
    ctx.save();
    // translate view to center
    ctx.translate(canvasBg.width/2, canvasBg.height/2);
    // rotate view
    ctx.rotate(angle*(Math.PI/180));
    // translate view to 0
    if (Math.abs(angle) === 90 || Math.abs(angle) === 270)
      ctx.translate(-canvasBg.height/2, -canvasBg.width/2);
    else
      ctx.translate(-canvasBg.width/2, -canvasBg.height/2);

    // redraw by history
    // if (state.backgroundColor) this.setBackground(state.backgroundColor);
    // if (state.backgroundImage) this.setBackgroundImage(state.backgroundImage);

    ctx.restore();
  };

  this.rotate = (angle) => {
    const ctx = canvas.getContext('2d');

    // setNewCanvasSize
    const oldWidth = canvas.width;
    const oldHeight = canvas.height;
    ctx.canvas.width = oldHeight;
    ctx.canvas.height = oldWidth;
    this.setStyleSizes(window.innerWidth, canvas.parentElement.clientHeight);

    // clear
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.save();
    // translate view to center
    ctx.translate(canvas.width/2, canvas.height/2);
    // rotate view
    ctx.rotate(angle*(Math.PI/180));
    // translate view to 0
    if (Math.abs(angle) === 90 || Math.abs(angle) === 270)
      ctx.translate(-canvas.height/2, -canvas.width/2);
    else
      ctx.translate(-canvas.width/2, -canvas.height/2);

    // redraw by history
    this.redrawAll();

    ctx.restore();

    return [ canvas.width, canvas.height ];
  };

  this.getImage = () => {
    const ctx = canvas.getContext('2d');

    if (state.backgroundColor || state.backgroundImage) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      if (state.backgroundColor) {
        ctx.fillStyle = state.backgroundColor;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        ctx.fillStyle = state.color;
      }

      if (state.backgroundImage) {
        ctx.drawImage(state.backgroundImage, 0, 0, options.canvasSize[0], options.canvasSize[1]);
      }

      this.redrawAll();
    }

    return canvas.toDataURL('image/jpeg', 0.92);
  };

  this.saveImage = () => {
    const link = document.createElement('a');
    link.download = `${Date.now()}.jpg`;
    link.href = this.getImage();
    link.click();
  };

  this.getRootElement = () => {
    return canvas;
  };

  this.destroy = () => {
    canvas.remove();
    canvasBg.remove();
  };

  this.disableDraw = () => {
    this.removeEvents();
  };

  this.enableDraw = () => {
    this.removeEvents();
    this.addEvents();
  };

  this.init();
}
