import { useSelector, useDispatch } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import InterestsList from 'components/InterestsList';
import * as api from 'helpers/api';

import styles from './interests.module.css';

const Interests = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));

  const { data: interests } = useApiQuery({
    name: ['playerinterest'],
    path: `/playerinterest/${user.uid}/`,
    skip: !user
  });

  const handleChangeInterests = (uid, checked) => {
    if (interests.filter(({ interest }) => !!interest).length <= 5 && !checked) {
      dispatch(setItem(['notifications', 'playerinterest'], { type: 'error', content: 'Нельзя выбрать менее 5 интересов' }));
      return null;
    };

    dispatch(setItem(['show_loader'], true));
    api
      .get(`/playerinterest/${user.uid}/${uid}/${checked ? 'add' : 'delete'}/`)
      .then(response => {
        const result = response.data.response;

        dispatch(setItem(['show_loader'], false));
        if (result) {
          dispatch(setItem(['playerinterest'], result, { isHard: true }));
        }
      })
      .catch(error => {
        dispatch(setItem(['show_loader'], false));
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  return (
    <MainLayout title="Мои интересы">
      <div className={styles.subtitle}>
        {interests ? (
          <>Выбрано интересов: <span className="accent-text">{interests.filter(({ interest }) => !!interest).length} из {interests.length}</span></>
        ) : (
          <div className={'skeleton-loader'}>&nbsp;</div>
        )}
      </div>
      <InterestsList
        items={interests}
        onChange={handleChangeInterests}
      />
    </MainLayout>
  );
};

export default Interests;
