import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';
import { MainLayout } from 'components/Layouts';
import Block from 'components/Block';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';
import * as api from 'helpers/api';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';
import { setItem, deleteItem } from 'store/app/actions';

import styles from './Grants.module.css';

const Item = ({ name, value, onChange, placeholder, className, readOnly }) => {
  return (
    <div className={classnames(styles.item, className, !name && 'skeleton-loader')}>
      {name &&
        <>
          <div className={styles.itemTitle}>{name}</div>
          <NumberFormat
            type="tel"
            className={classnames(styles.itemInput, (!value || Number(value) === 0) && 'error')}
            value={value}
            onValueChange={values => onChange && onChange(values.value)}
            placeholder={placeholder}
            readOnly={readOnly}
            mask="#####"
          />
          {(!value || Number(value) === 0) && <span className={styles.error}>введите значение</span>}
        </>
      }
    </div>
  );
};

const Form = ({ user, player, grants, onSubmit }) => {
  const [ skills, setXp ] = useState(100);
  // const [ skills, setXp ] = useState(grants.from_skills);
  const [ p, setP ] = useState(grants.to_cash);
  const [ max, setMax ] = useState(grants.week_limit_cash);

  const handleChangeP = (value) => {
    if (!value) value = null;
    setP(value);
  };

  const handleChangeXp = (value) => {
    if (!value) value = null;
    setXp(value);
  };

  const handleChangeMax = (value) => {
    if (!value) value = null;
    setMax(value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Item name="Баллы опыта" value={skills} onChange={handleChangeXp} placeholder={100} readOnly={true} />
        <img src="/icons/icon-equal.svg" className={styles.equal} alt="=" />
        <Item name="₽" value={p} onChange={handleChangeP} placeholder={10} className={styles.currency} />
      </div>
      <div className={styles.description}>Максимальная выплата в неделю не должна привышать</div>
      <div className={styles.row}>
        <Item name="₽" value={max} onChange={handleChangeMax} placeholder={700} className={styles.currency} />
      </div>
      <ButtonsContainer>
        <Button
          butStyle="primary"
          onClick={() => onSubmit(p, skills, max)}
          disabled={!(user && player && p && skills && max && Number(p) !== 0 && Number(skills) !== 0 && Number(max) !== 0)}
        >
          Сохранить
        </Button>
      </ButtonsContainer>
    </div>
  );
};

const SkeletForm = () => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <Item />
        <img src="/icons/icon-equal.svg" className={styles.equal} alt="=" />
        <Item />
      </div>
      <div className={styles.description}>Максимальная выплата в неделю не должна привышать</div>
      <div className={styles.row}>
        <Item />
      </div>
      <ButtonsContainer>
        <Button butStyle="primary" disabled={true}>
          Сохранить
        </Button>
      </ButtonsContainer>
    </div>
  );
};

const Grants = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player ? user.current_player : null;
  const grants = useSelector(selectItem(['user_grants', currentPlayer?.uid_gamer]));

  useApiQuery({
    name: ['user_grants', currentPlayer?.uid_gamer],
    path: `/getuserstep/${currentPlayer?.uid_gamer}/${user.uid}/`,
    skip: !user || !currentPlayer,
    mapper: (data = {}, prev) => {
      if (data.length) return data[0];
      else return {};
    }
  });

  const handleSubmit = (p, skills, max) => {
    if (!user || !currentPlayer) return null;

    const fd = new FormData();
    fd.append('update[player_uid]', currentPlayer.uid_gamer);
    fd.append('update[mentor_uid]', user.uid);
    fd.append('update[from_skills]', skills);
    fd.append('update[to_cash]', p);
    fd.append('update[week_limit_cash]', max);
    // fd.append("update[delete]", "delete");

    api
      .post('/stepupdate/', fd)
      .then(response => {
        const result = response.data.response;

        if (result.stipendion) {
          if (result.message)dispatch(setItem(['notifications', 'stepupdate'], { type: 'success', content: result.message }));
          dispatch(deleteItem(['user_grants'], currentPlayer.uid_gamer));
          navigate('/');
        } else {
          if (response.data.error) dispatch(setItem(['notifications', 'stepupdate'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'stepupdate'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }

  return (
    <MainLayout
      title="Стипендия"
      infoSlug="stipendy"
      description={
        <div>
          Ваше денежное вознаграждение стимулирует ребенка заниматься саморазвитием и осваивать новые навыки <br /><br />
          Стипендия основывается на баллах опыта. В неделю ребенок может заработать более 7000 баллов
        </div>
      }
    >
      <Block>
        <div className={styles.exampleTitle}>
          Пример:
        </div>
        <div className={styles.example}>
          <img className={styles.exampleImage} src="/images/stipendion_1.svg" alt="" />
          <div className={styles.exampleText}>ребенок за неделю заработал 7000 баллов,</div>
          <img className={styles.exampleImage2} src="/images/stipendion_2.svg" alt="" />
          <div className={styles.exampleText2}>в понедельник вы выдаете ему 700 рублей</div>
        </div>
      </Block>
      {user.player ? (
        <>
          <Block>
            {grants ? (
              <Form
                user={user}
                player={currentPlayer}
                grants={grants}
                onSubmit={handleSubmit}
              />
            ) : (
              <SkeletForm />
            )}
          </Block>
        </>
      ) : (
        <>
          <div className="text-center" style={{ marginTop: '60px' }}>Добавьте минимум 1 ребенка чтобы назначать стипендию</div>
          <ButtonsContainer>
            <Button
              to="/add-player"
              fullWidth={true}
              butStyle="primary"
              state={{ from: '/create' }}
              size="medium"
            >
              {/* <ReactSVG src="/icons/icon-plus.svg" className={styles.icon} /> */}
              Добавить ребенка
            </Button>
          </ButtonsContainer>
        </>
      )}
    </MainLayout>
  );
};

export default Grants;