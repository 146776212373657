import { useState } from 'react';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import Modal from 'components/Modal';

import styles from './custom-reward.module.css';

const CustomReward = ({ uid, onSave }) => {
  const [ isShowCustom, setShowCustom ] = useState(false);
  const [ value, setValue ] = useState(null);

  return (
    <>
      <ButtonsContainer className={styles.buttons} key={uid}>
        <Button
          fullWidth={true}
          butStyle="secondary"
          onClick={() => setShowCustom(true)}
        >
          Своя награда
        </Button>

        <Modal
          title="Своя награда"
          viewStyle="bottom"
          open={isShowCustom}
          onClose={() => setShowCustom(false)}
        >
          <textarea
            id="reward_name"
            name="reward_name"
            rows="4"
            placeholder="Введите название награды"
            maxLength="255"
            onChange={e => setValue(e.target.value)}
          />

          <ButtonsContainer>
            <Button
              butStyle="primary"
              fullWidth={true}
              onClick={() => {
                onSave(value);
                setShowCustom(false);
              }}
            >
              Сохранить
            </Button>
          </ButtonsContainer>
        </Modal>
      </ButtonsContainer>
    </>
  );
};

export default CustomReward;
