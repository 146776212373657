import classnames from 'classnames';
import { ReactSVG } from 'react-svg';
import NoItems from '../../NoItems';

import styles from './rewards.module.css';

import { categories_colors_home } from '../../../data/mock';

const medalColors = {
  M: '#F48DF6',
  Y: '#6FD9F0',
  W: '#DF9B5D',
  Q: '#C0C0C0',
};

const Rewards = ({ items = [1, 2, 3, 4, 5], emptyText }) => {
  if (!Object.keys(items).length) return ( // no items
    <NoItems children={emptyText} />
  );

  return (
    <div className={classnames(styles.list, 'scroll-list')}>
      {Object.values(items).map(({ uid, type, reward_name }, index) => {
        const cid = uid ? `${uid}` : '1';
        const colors = categories_colors_home[cid.slice(-1)];

        if (!uid) return (
          <div className={classnames(styles.item, 'skeleton-loader')} key={index}></div>
        );

        return (
          <div className={styles.item} key={uid}>
            <div className={styles.titleContainer}>
              <div className={styles.title}>
                {reward_name}
              </div>
            </div>
            <div className={styles.values}>
              <div className={styles.value} style={{ backgroundColor: colors[1] }}>
                <ReactSVG className={styles.icon} src="/images/medal-2.svg" wrapper="span" style={{ color: medalColors[type] }} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Rewards;
