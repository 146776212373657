import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import { QuestsWheel as Wheel } from 'components/quests/QuestsWheel';
import Modal from 'components/Modal';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import * as api from 'helpers/api';

const QuestsWheel = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const [ showNotify, setShowNotify ] = useState(false);
  const [ isDisabled, setDisabled ] = useState(false);

  useEffect(() => {
    if (user.reward_limit - user.score_day <= 20) setShowNotify(true);
    setDisabled(user.active_quest_count && user.quest_active_limit && user.active_quest_count >= user.quest_active_limit);
  }, [user]);

  const handleSetHardLevel = (difficult) => {
    const diff = (difficult && difficult.length) ? difficult.join('-') : null;
    api
      .get(`/setquesthardlevel/${user.uid}/${diff}/`)
      .then(response => {
        if (response.data.response && !response.data.response.error) {
          dispatch(setItem(['user', 'quest_hard_level'], difficult, { isHard: true }));
        }
      });
  };

  return (
    <MainLayout title="Выбрать квест" infoSlug="quests-wheel">
      <div className="error-bg">Все задания выполняются в реальном мире</div>
      {user &&
        <Wheel
          user={user}
          wheel={user.wheel}
          quest_hard_level={user.quest_hard_level}
          wheelTitle={user.wheelTitle}
          wheelTime={user.wheelTime}
          allowClick={!isDisabled}
          onSetHardLvl={handleSetHardLevel}
        />
      }

      {(user && user.reward_limit && user.reward_limit > 0) ?
        <Modal
          title="Оповещение"
          viewStyle="bottom"
          open={showNotify}
          onClose={() => setShowNotify(false)}
          buttons={[
            { style: 'primary', text: 'Понятно', action: 'close' }
          ]}
        >
          {user.reward_limit <= user.score_day ? (
            <>Получено {user.score_day} баллов из {user.reward_limit} за день, за новый квест вы получите 0 баллов</>
          ) : (
            <>Получено {user.score_day} баллов из {user.reward_limit} за день, если ваш квест будет из {user.reward_limit - user.score_day + 10} баллов, вы получите за него только {user.reward_limit - user.score_day} баллов</>
          )}
        </Modal>
      : ''}
    </MainLayout>
  );
}

export default QuestsWheel;
