import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Message from './Message';
import HeaderMessage from './HeaderMessage';
import Reward from './Reward';
import { setItem } from '../../store/app/actions';
import { selectItem } from '../../store/app/getters';

import { childGame } from '../../scripts/game';

import styles from './game-map.module.css';
import FSSlider from 'components/FSSlider';

const GameMap = ({ game, gameParams, onAddNewPoint, onChangeCity, afterInit, onCityOut }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const anim = useRef(null);
  const [ reward, setReward ] = useState(null);
  const [ monolog, setMonolog ] = useState(null);
  const [ comics, setComics ] = useState(null);
  const [ headerMessage, setHeaderMessage ] = useState(null);
  const isChatOpen = useSelector(selectItem(['service', 'chat_open']));

  let gameRef = useRef(null);

  useEffect(() => {
    if (ref.current && game && !gameRef.current) {
      dispatch(setItem(['game_params', 'energy_prev'], game.energy));

      gameRef.current = new childGame({
        game,
        container: ref.current,
        animationContainer: anim.current,
        addNewPoint: onAddNewPoint,
        changeCity: onChangeCity,
        afterInit: afterInit,
        onCityOut: onCityOut,
        addMonolog: setMonolog,
        addHeaderMessage: setHeaderMessage,
        addComics: setComics
      });
    }

    // eslint-disable-next-line
  }, [game, ref]);

  useEffect(() => {
    if (isChatOpen === false && gameRef.current) {
      gameRef.current.addGates(true);
      gameRef.current.setActiveIsland();
    }
  }, [gameRef, isChatOpen]);

  useEffect(() => {
    if (game && gameParams.energy_prev && (game.energy - gameParams.energy_prev > 0)) {
      const next = Object.values(game.points).reduce((prev, current) => prev.is_active ? prev : current, {});

      if (next) {
        setReward({
          points: game.energy - gameParams.energy_prev,
          need: next.need
        });

        setTimeout(() => {
          setReward(null);
        }, [3000]);
      }
    }

    // eslint-disable-next-line
  }, [game, gameParams]);

  return (
    <div className={styles.root}>
      <div className={styles.image} ref={ref} />
      <div className={styles.animation} ref={anim} />
      {reward &&
        <Reward
          points={reward.points}
          need={reward.need}
        />
      }
      {monolog &&
        <Message
          {...monolog}
          onExit={() => { monolog.onExit(); setMonolog(null); }}
          onClose={() => { monolog.onClose(); setMonolog(null); }}
        />
      }
      {headerMessage &&
        <HeaderMessage
          {...headerMessage}
          onClose={() => { headerMessage.onClose(); setHeaderMessage(null); }}
        />
      }
      {comics &&
        <FSSlider items={comics.items} onExit={() => { comics.onClose(); setComics(null); }} />
      }
    </div>
  );
};

export default GameMap;