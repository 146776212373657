import { ReactSVG } from 'react-svg';
import ruPlural from '../../helpers/ruPlural';
import classnames from 'classnames';

import styles from './players-list.module.css';

const Position = ({ position }) => {
  if (position > 3) {
    return <>{position}</>;
  } else if (position === 3) {
    return <ReactSVG src="/icons/icon-position-third.svg" />;
  } else if (position === 2) {
    return <ReactSVG src="/icons/icon-position-second.svg" />;
  } else if (position === 1) {
    return <ReactSVG src="/icons/icon-position-first.svg" />;
  };

  return null;
}

const PlayerItem = ({ player, rank }) => {
  const { gender, name, surname, sum_bonus, prises, avatar } = player;

  const photo = avatar ? avatar : (`/icons/icon-child-${gender ? gender.toLowerCase() : 'm'}.svg`);

  return (
    <div className={styles.player}>
      <div className={styles.position}>
        <Position position={rank} />
      </div>
      <div className={styles.image}>
        <img src={photo} alt="" />
      </div>
      <div className={styles.content}>
        <div className={styles.name}> {name} {surname} </div>
        <div className={styles.rating}>
          {sum_bonus &&
            <span className={styles.points}> {sum_bonus} {ruPlural(sum_bonus, ['балл', 'балла', 'баллов'])} </span>
          }
          {prises &&
            <span className={styles.prises}> {prises} {ruPlural(prises, ['награда', 'награды', 'наград'])} </span>
          }
        </div>
      </div>
    </div>
  );
};

const PlayersList = ({ players, current, type }) => {
  if (!players) return null;

  return (
    <div className={styles.list}>
      {Object.values(players).map((player) =>
        <div className={classnames(styles.listItem, Number(player.uid) === Number(current) && styles.active)} key={player.uid}>
          <PlayerItem player={player} rank={player['rank_' + type]} />
        </div>
      )}
    </div>
  );
};

export default PlayersList;