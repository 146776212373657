import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import useInterval from 'use-interval';
import QRCode from 'react-qr-code';
import * as api from 'helpers/api';
import { MainLayout } from 'components/Layouts';
import Block from 'components/Block';
import ModalApprove from 'components/modals/ModalApprove';
import getCurrentHost from 'helpers/getCurrentHost';
import { selectItem } from 'store/app/getters';
import { setItem, deleteItem } from 'store/app/actions';
import useApiQuery from 'hooks/useApiQuery';
import getDuration from 'helpers/getDuration';

const GetQuestApprove = () => {
  let { qid } = useParams();
  const dispatch = useDispatch();

  const user = useSelector(selectItem(['user']));
  const quest = useSelector(selectItem(['user_quests', user.uid, 'query', qid]));

  const [ isShowApproves, setShowApprove ] = useState(false);
  const [ isAnimated, setAnimated ] = useState(false);
  const [ oldApproves, setOldApproves ] = useState(null);
  const [ newApproves, setNewApproves ] = useState(0);

  useInterval(() => {
    api
      .get(`/getUserQuest/${user.uid}/${qid}/`)
      .then(response => {
        const result = response.data.response;
        if (result && result.query && result.query[0]) {
          if (result.query[0].aproove.score !== oldApproves) {
            dispatch(setItem(['user_quests', user.uid, 'query', qid], result.query[0]));
            dispatch(setItem(['user', 'score_all'], result.score_all));
            dispatch(setItem(['user', 'score_day'], result.score_day));
            dispatch(setItem(['user', 'score_now'], result.score_now));
            dispatch(setItem(['user', 'score_week'], result.score_week));
          }
        } else {
          const error = response.data.error ? response.data.error : 'Не удалось проверить подтвержения';
          dispatch(setItem(['notifications', 'addUserQuest'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }, 5000);

  useEffect(() => {
    if (quest && quest.score && quest.score.now !== oldApproves) {
      setOldApproves(quest.score.now);

      if (oldApproves !== null) {
        setNewApproves(quest.score.now - oldApproves);

        if (quest.score.now - oldApproves > 0) {
          setShowApprove(true);
          setTimeout(() => { setAnimated(true); }, 200);
          setTimeout(() => { setShowApprove(false); }, 3000);
          setTimeout(() => { setAnimated(false); }, 3500);
          setTimeout(() => { setNewApproves(0); }, 3500);
        }
      }
    }

    if (quest && quest.end) {
      dispatch(setItem(['user_quests', user.uid, 'complete', quest.uid], quest));
      dispatch(setItem(['user', 'active_quest_count'], user.active_quest_count-1));

      dispatch(deleteItem(['user_quests', user.uid, 'active'], quest.uid));
      dispatch(deleteItem(['api'], `/getUserQuest/${user.uid}/${quest.uid}/`));
    }

    // eslint-disable-next-line
  }, [quest]);

  useEffect(() => {
    if (quest && quest.complete_sign) return null;
    api
      .get(`/playerQuestComplete/${qid}/`)
      .then(response => {
        const result = response.data.response;

        if (result.error) dispatch(setItem(['notifications', 'quest_complete'], { type: 'error', content: result.error }));
        else {
          dispatch(setItem(['user_quests', user.uid, 'query', qid, 'complete_sign'], 1));
          dispatch(setItem(['user_quests', user.uid, 'active', qid, 'complete_sign'], 1));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });

    //eslint-disable-next-line
  }, []);

  const { error } = useApiQuery({
    name: ['user_quests', 'query', qid],
    path: `/getUserQuest/${user.uid}/${qid}/`,
    mapper: (data = {}, prev) => {
      let next = {};

      for (let key in data) {
        if (data[key] && data[key].length) {
          for (let questKey in data[key]) { next = data[key][questKey]; }
        }
      }

      return {...prev, ...next};
    }
  });

  if (error) return <Navigate replace to="/quests-wheel" />;
  if (!quest || !quest.aproove) return null;
  if (!quest.start || quest.is_complete || quest.end) return <Navigate replace to={`/quest-success/${qid}`} />;
  if (quest.is_cancel) return <Navigate replace to="/" />;

  console.log(`${getCurrentHost()}/approve-quest/${quest.aproove.token}`);

  const durationText = `Осталось ${quest.lifetime_end ? getDuration(quest.lifetime_end, 3) : 'X минут'} для подтверждения`;

  return (
    <MainLayout title="Подтверждение квеста" showBackButton={false}>
      <div style={{ marginTop: '20px', marginBottom: '30px' }}>
        <QRCode value={`${getCurrentHost()}/approve-quest/${quest.aproove.token}`} size={120} />
      </div>
      {/* `${quest.aproove.score} из ${quest.need_score} подтверждений получено` */}
      <Block title={<>Необходимо <br /> подтверждение взрослого</>}>
        <p style={{ fontSize: '14px', lineHeight: '1.2' }} className="accent-text" dangerouslySetInnerHTML={{__html: durationText}} />
        <p style={{ fontSize: '14px', lineHeight: '1.2' }} className="gray-text">Попроси родителя подтвердить задание. Для этого он может зайти в свой аккаунт в раздел Активные квесты или сканировать QR-код.</p>
      </Block>

      {(isAnimated || isShowApproves) &&
        <ModalApprove approves={newApproves} isAnimated={isAnimated} isOpen={isShowApproves} />
      }
    </MainLayout>
  );
};

export default GetQuestApprove;
