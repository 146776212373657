import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { GameLayout } from '../../components/Layouts';
import GameMap from '../../components/GameMap';
import GameChat from '../../components/GameChat';
import useApiQuery from '../../hooks/useApiQuery';
import * as api from '../../helpers/api';
import { selectItem } from '../../store/app/getters';
import { setItem } from '../../store/app/actions';

import styles from './game.module.css';

const Game = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const gameParams = useSelector(selectItem(['game_params'], {}));
  const [ update, setUpdate ] = useState(null);
  const [ isInited, setInited ] = useState(false);

  let uid = null;
  if (user) uid = (user.type === 'M') ? user.current_player.uid_gamer : user.uid;

  useEffect(() => {
    setUpdate(new Date());
  }, []);

  const { data: game, isSuccess } = useApiQuery({
    name: ['game', uid],
    path: `/gameprogress/${uid}/`,
    skip: !uid,
    save_history: true,
    update_param: gameParams && gameParams.city + update
  });

  const handleClickAnswer = (dialog_id, message_key, ask_uid, variant) => {
    api
      .get(`/gameaskanswer/${uid}/${ask_uid}/${variant}/`)
      .then(response => {
        const result = response.data.response;

        if (result) {
          let dialog = [ ...game.dialogs[dialog_id].dialog ];
          if (dialog[message_key]) {
            dialog[message_key] = { ...dialog[message_key] };
            if (dialog[message_key].ask) {
              dialog[message_key].ask = result;
              dispatch(setItem(['game', uid, 'dialogs', dialog_id, 'dialog'], dialog));
            }
          }
        }
      });
  };

  const addNewPoint = async (point_uid) => {
    const response = await api.get(`/gameaddnewpoint/${point_uid}/${uid}/`);
    const result = response.data.response;

    if (response.data.error) return null;
    if (result) {
      dispatch(setItem(['game', uid, 'energy'], result.energy));
      dispatch(setItem(['game', uid, 'dialogs', point_uid], result.dialogs));
      return result;
    }
  };

  const changeCity = async () => {
    const response = await api.get(`/gamecitygate/${uid}/`);
    const result = response.data;

    if (result.error) return null;
    return result;
  };

  const handleCityOut = async (new_point_uid) => {
    dispatch(setItem(['game_params', 'city'], new_point_uid));
  }

  if (!game && !isSuccess) return null;

  const isLocalhost = (window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost');

  const addEnergy = () => api.get(`/game_edit_player/add_ball/${uid}/1000/`);
  const removeProgress = (point_uid) => api.get(`/gameaddnewpoint/${point_uid}/${uid}/remove/`);

  return (
    <GameLayout game={game}>
      <div key="game">
        {isLocalhost &&
          <>
            <button style={{ position: 'absolute', left: 0, top: '110px', zIndex: 100 }} onClick={addEnergy} href="#">Add energy</button>
            <button style={{ position: 'absolute', left: 0, top: '150px', zIndex: 100 }} onClick={() => removeProgress(1004)} href="#">Remove progress</button>
          </>
        }

        {isSuccess && (
          <GameMap
            game={game}
            gameParams={gameParams}
            onAddNewPoint={addNewPoint}
            onChangeCity={changeCity}
            afterInit={() => setInited(true)}
            onCityOut={handleCityOut}
          />
        )}
        {!isInited && (
          <div className={styles.loader}>
            <div className="container">
              <ReactSVG src="/images/game/loader.svg" className={styles.loaderImage} />
            </div>
          </div>
        )}

      </div>
      <div key="chat">
        {(isSuccess && user && game) &&
          <GameChat
            user={(user && user.type === 'M') ? user.current_player : user}
            chat={game.dialogs}
            onAnswer={handleClickAnswer}
          />
        }
      </div>
    </GameLayout>
  );
};

export default Game;