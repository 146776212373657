import classnames from 'classnames';
import InfoButton from './InfoButton';

import styles from './block.module.css';

const Block = ({ title, onClickTitle, children, headerAlign, align, color, showBackButton, backButtonUrl, className, titleClassName,
  rightButton, infoSlug, style, id }) => {

  if (!title && !children) return null;

  return (
    <div className={classnames(styles.root, color && styles[color], className)} style={style} id={id}>
      {(title || showBackButton || rightButton || infoSlug) && (
        <div className={classnames(styles.header, headerAlign && styles[headerAlign])}>
          <h2 className={classnames(styles.title, titleClassName)} onClick={onClickTitle}>
            {title && title}
          </h2>
          {(rightButton || infoSlug) &&
            <span className={styles.rightButton}>
              {rightButton && rightButton}
              {infoSlug && <InfoButton slug={infoSlug} />}
            </span>
          }
        </div>
      )}
      {children && <div className={classnames(styles.content, align && styles[align])}> {children} </div>}
    </div>
  );
};

export default Block;