import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import QRCode from 'react-qr-code';
import useInterval from 'use-interval';
import classnames from 'classnames';
import { MainLayout } from 'components/Layouts';
import Button from 'components/Button';
import Form from 'components/Form';
import Input from 'components/fields/Input';
import Select from 'components/fields/Select';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
import * as api from 'helpers/api';
import getCurrentHost from 'helpers/getCurrentHost';
import standartPhone from 'helpers/standartPhone';

import styles from './player.module.css';

const InviteMentor = () => {
  const { phone } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [ token, setToken ] = useState(null);
  const [ mentorUid, setMentorUid ] = useState(null);
  const [ isSubmitActive, setSubmitActive ] = useState(false);
  const user = useSelector(selectItem(['user']));

  const mentor = user.mentor?.find((mentor) => standartPhone(mentor.info.phone) === standartPhone(phone));

  const inputs = [
    { id: 'phone', name: 'phone', component: Input, validation: 'phone',
      props: {
        label: 'Введите номер телефона', placeholder: '+7', value: standartPhone(phone),
        fullWidth: true, mask: '+7 ### ### ## ##', type: 'tel', autoFocus: true, disabled: !!phone || !!token
      }
    },
    { id: 'relation', name: 'relation', component: Select, validation: 'select',
      props: {
        label: 'Тип родства', placeholder: 'Выберите тип родства', fullWidth: true,
        disabled: (mentor && mentor.relation && mentor.relation.uid), value: mentor ? mentor.relation.uid : '',
        loadUrl: `/getmentoringstructure`, mapper: (data) => Object.values(data.for_uid).map(({ name, uid }) => ({ label: name, value: uid }))
      }
    }
  ];

  useInterval(() => {
    if (!token) return;

    api
      .get(`/getUserProfile/${user.uid}/`)
      .then(response => {
        const result = response.data.response;
        if (result && result.mentors) {
          dispatch(setItem(['user', 'mentor'], result.mentors));
          const mentor = result.mentors.find((user, index) => mentorUid === user.uid);
          if (mentor && mentor.verify_mentor) {
            dispatch(setItem(['notifications', 'createMentoring'], { type: 'success', content: 'Поздравляем, связь с метором подтверждена' }));
            navigate('/');
          }
        } else {
          const error = response.data.error ? response.data.error : 'Не удалось проверить подтвержение';
          dispatch(setItem(['notifications', 'addUserQuest'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  }, 5000);

  const onFormChange = data => {
    if (data.relation) setSubmitActive(true);
  };

  const onFormSubmit = data => {
    api
      .get(`/createMentoring/${data.phone.replace(/\s+/g, '').replace('+', '')}/${data.relation}/`)
      .then(response => {
        const result = response.data.response;

        if (result.bind && result.bind.response && result.bind.response.verify_mentor) {
          dispatch(setItem(['notifications', 'createMentoring'], { type: 'success', content: 'Поздравляем, связь с метором подтверждена' }));
          navigate('/');
        } else if (result.bind && result.bind.response && result.bind.response.token) {
          setToken(result.bind.response.token);
          setMentorUid(result.bind.response.uid);
          console.log(`${getCurrentHost()}/add-mentoring/${result.bind.response.token}`);
        } else {
          const error = result.error ? result.error : 'Невозможно создать связь';
          dispatch(setItem(['notifications', 'createMentoring'], { type: 'error', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const inviteButton = (
    <>
      <Button
        type="submit"
        fullWidth={true}
        butStyle="primary"
        disabled={!isSubmitActive}
      >
        Пригласить взрослого
      </Button>
      <div className={styles.buttonsNotice}>
        Введи номер телефона своего родителя <br />
        и попроси перейти по ссылке из СМС для регистрации в системе. Если СМС долго не приходит, можно отсканировать QR код с экрана.
      </div>
    </>
  );

  const submitButton = (
    <Button
      fullWidth={true}
      butStyle="primary"
      to="/"
      disabled={!isSubmitActive}
    >
      Далее
    </Button>
  );

  return (
    <MainLayout title="Пригласить взрослого">
      {token ? (
        <>
          <div className="text-center">
            <QRCode value={`${getCurrentHost()}/add-mentoring/${token}`} size={200} />
          </div>
          <div className={styles.message}>
            <p>Твоему Наставнику по указанному номеру телефона была отправлена СМС со ссылкой для входа в систему!</p>
            <p>После того, как он заполнит свой профиль, тебе будут доступны квесты</p>
          </div>
        </>
      ) : (
        <div className={classnames(styles.message, styles.messageLarge)}>
          Добавь аккаунт <span className="accent-text">Взрослого</span>, чтобы можно было начать прохождение квестов.
        </div>
      )}

      <Form inputs={inputs} onChange={onFormChange} onSubmit={onFormSubmit} buttons={token ? submitButton : inviteButton} inputClass="dark" />
    </MainLayout>
  );
}

export default InviteMentor;
