import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../helpers/api';
import { selectItem } from '../store/app/getters';
import { setItem, deleteItem } from '../store/app/actions';

const def = {
  isPending: false,
  isSuccess: false,
  isFail: false,
};

const useApiQuery = ({ name = [], path, skip, save_history, mapper = (data) => data, update_param, isHard = false, show_loader = false }) => {
  const dispatch = useDispatch();
  const apiPath = ['api', update_param ? path + update_param : path];
  const info = useSelector(selectItem(apiPath, { ...def }));
  const settings = useSelector(selectItem('settings'));

  const { isPending, isSuccess, isFail, error, status } = info;
  const data = useSelector(selectItem(name));

  useEffect(() => {
    if (!(isSuccess || isPending || isFail || skip)) {
      // dispatch(setItem(apiPath, Object.assign({}, info, { isPending: true })));
      if (show_loader) dispatch(setItem(['show_loader'], true));

      api.get(path)
        .then(res => {
          const { response, error } = res.data;
          if (error) {
            dispatch(setItem(apiPath, Object.assign({}, info, { isPending: false, isFail: true, error: error })));
          }
          // } else {
            dispatch(setItem(name, mapper(response, data), { isHard: isHard }));
            if (!settings) dispatch(setItem(['settings'], res.data.settings));

            if (!save_history) dispatch(deleteItem(apiPath));
            else dispatch(setItem(apiPath, Object.assign({}, info, { isPending: false, isSuccess: true })));
          // }

          if (show_loader) dispatch(setItem(['show_loader'], false));
        })
        .catch(error => {
          const result = error && error.toJSON();
          // const result = error && JSON.stringify(error);
          dispatch(setItem(apiPath, Object.assign({}, info, { isPending: false, isFail: true, error: result.message, status: result.status })));
          if (show_loader) dispatch(setItem(['show_loader'], false));
        });
    }
    //eslint-disable-next-line
  }, [path, update_param]);

  return { isPending, isSuccess, isFail, data, error, status };
};

export default useApiQuery;
