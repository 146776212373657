import { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RegisterLayout } from 'components/Layouts';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';
// import useApiQuery from 'hooks/useApiQuery';
// import { setItem } from 'store/app/actions';
// import { selectItem } from 'store/app/getters';

import styles from './Guide.module.css';

const Guide = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [ step, setStep ] = useState(1);
  // const user = useSelector(selectItem(['user']));

  // const { data: homework } = useApiQuery({
  //   name: ['homework_small_mentor', user.current_player?.uid_gamer],
  //   path: `/api/getuserdomashka/${user.current_player?.uid_gamer}/small/`,
  //   skip: !user.current_player
  // });
  // const { data: rewards } = useApiQuery({
  //   name: ['rewards_mentor', user.current_player?.uid_gamer],
  //   path: `/playerrewardsfrommentor/${user.current_player?.uid_gamer}/`,
  //   skip: !user.current_player,
  //   mapper: (data = {}) => {
  //     return Object.values(data).reduce((prev, current) => {
  //       if (current) return ([ ...prev, ...current ]);
  //       return prev;
  //     }, []);
  //   }
  // });
  // const rewardsTotals = rewards && rewards.reduce((totals, { type }) => ({ ...totals, [type]: totals[type] ? totals[type]+1 : 1 }), {});

  const handleClickNext = (step) => {
    setStep(step);

    if (step === 2) {
      navigate('/');
      // let onboarding = [{
      //   key: 'home_1',
      //   selector: '#first_player',
      //   image: <img style={{ position: 'absolute', top: '10px', left: '-100px' }} className={styles.reward_info} src="/images/onboarding/child_page.svg" alt="" />,
      //   link: '/player',
      //   onClose: () => navigate('/player')
      // }];

      // if (!homework || !homework.main || !Object.values(homework.main).find(({ time }) => !!time.uid)) {
      //   onboarding.push({
      //     key: 'home_2',
      //     selector: '#homework',
      //     image: <img style={{ position: 'absolute', top: '-166px', right: '0px' }} className={styles.reward_info} src="/images/onboarding/homework.svg" alt="" />,
      //     link: '/homework-update',
      //   });
      // };

      // if (!rewards || !Object.keys(rewards).length || !Object.values(rewardsTotals).find(value => +value > 3)) {
      //   onboarding.push({
      //     key: 'home_3',
      //     selector: '#ob_rewards',
      //     image: <img style={{ position: 'absolute', top: '-178px', left: '-29px' }} className={styles.reward_info} src="/images/onboarding/rewards.svg?v=1" alt="" />,
      //     link: '/update-rewards',
      //   });
      // };

      // dispatch(setItem(['onboarding'], onboarding));
    }
  };

  return (
    <RegisterLayout>
      {step === 1 && (
        <div className={styles.step}>
          <div>
            Ответьте на несколько вопросов и мы поможем ребёнку с решением даже самой трудной домашки
          </div>
        </div>
      )}
      <ButtonsContainer>
        <Button
          butStyle="primary"
          size="small"
          fullWidth={true}
          onClick={() => handleClickNext(step+1)}
        >
          Далее
        </Button>
      </ButtonsContainer>
    </RegisterLayout>
  );
};

export default Guide;