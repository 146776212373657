import { useSelector } from 'react-redux';
import { MainLayout } from '../../components/Layouts';
import Block from '../../components/Block';
import UserHeader from '../../components/UserHeader';
import { Skills } from '../../components/Skills';
import ButtonsContainer from '../../components/ButtonsContainer';
import Button from '../../components/Button';
// import { PlayersSelect } from '../../components/UsersSelect';
import { selectItem } from '../../store/app/getters';

import styles from './mentor.module.css';

const Statistic = () => {
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const playerData = useSelector(selectItem(['players', currentPlayer?.uid_gamer]));

  if (!user) return null;

  return (
    <MainLayout title="Навыки" infoSlug="statistic">
      {currentPlayer ? (
        <>
          <UserHeader user={currentPlayer} />
          <ButtonsContainer className={styles.staticticButton}>
            <Button
              fullWidth={true}
              butStyle="primary"
              to="/ambitions"
            >
              Цели игрока
            </Button>
          </ButtonsContainer>
        </>
      ) : (
        // <>
        //   {user.player ? 'Необходимо выбрать игрока' : 'Необходимо добавить игрока'}
        //   <div style={{ marginTop: '24px' }} />
        //   <PlayersSelect users={user.player} />
        // </>
        <>
          <div className="text-center" style={{ marginTop: '60px' }}>Добавьте минимум 1 ребенка чтобы просмотреть навыки</div>
          <ButtonsContainer>
            <Button
              to="/add-player"
              fullWidth={true}
              butStyle="primary"
              state={{ from: '/create' }}
              size="medium"
            >
              {/* <ReactSVG src="/icons/icon-plus.svg" className={styles.icon} /> */}
              Добавить ребенка
            </Button>
          </ButtonsContainer>
        </>
      )}

      <div style={{ marginTop: '20px' }} />
      {playerData && playerData.skills &&
        <Block title="Навыки игрока">
          <Skills skills={playerData.skills} showDivider={true} />
        </Block>
      }
    </MainLayout>
  );
};

export default Statistic;
