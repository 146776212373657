import { forwardRef } from 'react';
import Modal from '../../Modal';
import useApiQuery from '../../../hooks/useApiQuery';

import styles from './keywords.module.css';

const KeywordDescription = ({ keyword_uid }) => {
  const keywordData = useApiQuery({
    name: ['keywords', keyword_uid],
    path: `/getKeywordInfo/${keyword_uid}/`,
    skip: !keyword_uid
  });

  const keyword = keywordData.data;
  if (!keyword) return null;

  return (
    <div className={styles.content} dangerouslySetInnerHTML={{ __html: keyword.description}} />
  );
}

const Keywords = ({ items }) => {
  return (
    <div className={styles.list}>
      {items.map(({ uid, name }, index) => {
        if (!uid) return null;
        const control = forwardRef(({ onClick }, ref) => <button ref={ref} onClick={onClick} className={styles.link}>{name}</button>);
        return (
          <Modal
            className={styles.item}
            control={control}
            title={name}
            viewStyle="bottom"
            key={uid}
            buttons={[{
              text: 'Закрыть',
              action: 'close',
              style: 'primary'
            }]}
          >
            <KeywordDescription keyword_uid={uid} />
          </Modal>
        );
      })}
    </div>
  );
};

export default Keywords;