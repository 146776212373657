import { useState, useRef, useEffect } from 'react';
import PhotoSwipeLightbox from 'scripts/PhotoSwipe/photoswipe-lightbox.esm';
import { TailSpin } from 'react-loader-spinner';
import classnames from 'classnames';
import Video from '../Video';
import { drawing } from 'scripts/drawing';

import 'scripts/PhotoSwipe/photoswipe.css';
import './gallery.css';
import styles from './gallery.module.css';

const circle = '<circle cx="16" cy="16" r="8" fill="currentColor" stroke-width="1.5" class="editor-button__circle" />';
const iconSave = '<path d="M7 19V13H17V19H19V7.82843L16.1716 5H5V19H7ZM4 3H17L21 7V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM9 15V19H15V15H9Z" style="transform: translate(5px, 5px);"/>';
const iconEraser = '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.4107 2.84345C13.3866 1.77753 15.0323 1.71327 16.0865 2.69992L22.4494 8.65486C23.5036 9.64151 23.5672 11.3054 22.5913 12.3714L16.297 19.2467L23.133 19.2467C23.6118 19.2467 24 19.6392 24 20.1234C24 20.6075 23.6118 21 23.133 21H0.867032C0.388184 21 -3.71562e-08 20.6075 0 20.1234C3.71562e-08 19.6392 0.388184 19.2467 0.867032 19.2467H6.96292L3.71816 16.21C2.66393 15.2233 2.60037 13.5594 3.57621 12.4935L12.4107 2.84345ZM9.51567 19.2467H13.9341L14.5456 18.5787L6.91017 11.4328L4.84878 13.6845C4.5235 14.0398 4.54469 14.5944 4.8961 14.9233L9.51567 19.2467ZM8.08811 10.1461L15.7235 17.2921L21.3187 11.1804C21.644 10.8251 21.6228 10.2704 21.2714 9.94153L14.9086 3.98659C14.5571 3.65771 14.0086 3.67913 13.6833 4.03443L8.08811 10.1461Z" style="transform: translate(3px, 4px);"/>';
const iconReturn = '<path d="M5.82843 6.99955L8.36396 9.5351L6.94975 10.9493L2 5.99955L6.94975 1.0498L8.36396 2.46402L5.82843 4.99955H13C17.4183 4.99955 21 8.58127 21 12.9996C21 17.4178 17.4183 20.9996 13 20.9996H4V18.9996H13C16.3137 18.9996 19 16.3133 19 12.9996C19 9.68585 16.3137 6.99955 13 6.99955H5.82843Z" style="transform: translate(5px, 5px);"/>';
const iconRotateLeft = '<path d="M4 10.5858L2.1716 8.75736L0.757401 10.1716L5 14.4142L9.2426 10.1716L7.8284 8.75736L6 10.5858V8C6 6.34315 7.3431 5 9 5H13V3H9C6.2386 3 4 5.23858 4 8V10.5858ZM11 9C10.4477 9 10 9.44772 10 10V20C10 20.5523 10.4477 21 11 21H21C21.5523 21 22 20.5523 22 20V10C22 9.44772 21.5523 9 21 9H11ZM12 11H20V19H12V11Z" style="transform: translate(5px, 5px);"/>';
const iconRotateRight = '<path d="M20 10.5858L21.8284 8.75736L23.2426 10.1716L19 14.4142L14.7574 10.1716L16.1716 8.75736L18 10.5858V8C18 6.34315 16.6569 5 15 5H11V3H15C17.7614 3 20 5.23858 20 8V10.5858ZM13 9C13.5523 9 14 9.44772 14 10V20C14 20.5523 13.5523 21 13 21H3C2.44772 21 2 20.5523 2 20V10C2 9.44772 2.44772 9 3 9H13ZM12 11H4V19H12V11Z" style="transform: translate(5px, 5px);"/>';
const iconPenThin = '<rect width="29" height="29" rx="5" fill="#F8F6FA" style="transform: translate(1px, 1px);"/><path d="M22.7997 16.9657C21.2792 18.9601 16.4008 25.0782 17.631 18.5512C17.742 17.9622 19.0775 14.443 17.9131 14.0925C16.3928 13.6348 14.5794 15.072 13.5232 15.9409C12.8855 16.4655 10.5769 19.3944 9.64188 19.2254C8.49986 19.019 10.0079 14.5609 10.0999 14.0187C10.6037 11.0511 11.1342 8.14652 8.15376 6.72491" stroke="black" stroke-linecap="round"/>';
const iconPenMedium = '<rect width="29" height="29" rx="5" fill="#F8F6FA" style="transform: translate(1px, 1px);"/><path d="M22.7997 16.9657C21.2792 18.9601 16.4008 25.0782 17.631 18.5512C17.742 17.9622 19.0775 14.443 17.9131 14.0925C16.3928 13.6348 14.5794 15.072 13.5232 15.9409C12.8855 16.4655 10.5769 19.3944 9.64188 19.2254C8.49986 19.019 10.0079 14.5609 10.0999 14.0187C10.6037 11.0511 11.1342 8.14652 8.15376 6.72491" stroke="black" stroke-width="2" stroke-linecap="round"/>';
const iconPenLarge = '<rect width="29" height="29" rx="5" fill="#F8F6FA" style="transform: translate(1px, 1px);"/><path d="M22.7997 16.9657C21.2792 18.9601 16.4008 25.0782 17.631 18.5512C17.742 17.9622 19.0775 14.443 17.9131 14.0925C16.3928 13.6348 14.5794 15.072 13.5232 15.9409C12.8855 16.4655 10.5769 19.3944 9.64188 19.2254C8.49986 19.019 10.0079 14.5609 10.0999 14.0187C10.6037 11.0511 11.1342 8.14652 8.15376 6.72491" stroke="black" stroke-width="3" stroke-linecap="round"/>';

const Slide = ({ index, isVideo, link }) => {
  if (!isVideo && link.search(/mp4|mov$/gm) !== -1) isVideo = true;
  const [ isLoad, setIsLoad ] = useState(false);
  const [ sizes, setSizes ] = useState({ width: 0, height: 0 });

  return (
    <a
      className={classnames(styles.item, 'slide-item')}
      href={link}
      data-is-custom={isVideo}
      data-pswp-width={sizes.width < window.innerWidth*2 ? sizes.width*2 : sizes.width}
      data-pswp-height={sizes.width < window.innerWidth*2 ? sizes.height*2 : sizes.height}
      key={link}
    >
      {!isLoad &&
        <div className={styles.loader}>
          <TailSpin color="var(--brand-color)" height="64" width="64" />
        </div>
      }
      {isVideo ? (
        <Video src={link} controls={false} onReady={() => setIsLoad(true)} />
      ) : (
        <img src={link} alt="" onLoad={(e) => {
          setIsLoad(true);
          setSizes({ height: e.target.naturalHeight, width: e.target.naturalWidth });
        }} />
      )}
    </a>
  );
};

const Gallery = ({ items, changeItems, onChangeFile }) => {
  const slider = useRef(null);

  useEffect(() => {
    let lightbox = null;
    let editor = null;

    if (slider.current) {
      let сanvasSize = 2000;
      let strokeWidth = 15;
      let strokeColor = '#ff0000';
      let isEdit = false;
      let isMove = false;

      lightbox = new PhotoSwipeLightbox({
        gallery: slider.current,
        children: 'a',
        bgOpacity: 1,
        tapAction: 'none',
        closeOnVerticalDrag: false,
        paddingFn: (viewportSize, itemData, index) => {
          return {
            top: changeItems ? 46 : 0,
            bottom: isEdit ? 74 : 0,
            left: 0,
            right: 0
          };
        },
        pswpModule: () => import('scripts/PhotoSwipe/photoswipe.esm.js'),
      });

      lightbox.addFilter('itemData', (itemData, index) => {
        if (itemData.element && itemData.element.dataset.isCustom === 'true') {
          let html = itemData.element.innerHTML;
          html = html.replace('<video', '<video controls')
          return { element: itemData.element, html: html, type: 'video' };
        }
        return itemData;
      });

      lightbox.on('beforeOpen', () => window.ReactNativeWebView && window.ReactNativeWebView.postMessage('previewOpen'));
      lightbox.on('close', () => window.ReactNativeWebView && window.ReactNativeWebView.postMessage('previewClose'));

      if (changeItems) {
        lightbox.on('uiRegister', () => {
          // save
          lightbox.pswp.ui.registerElement({
            name: 'downloadButton',
            title: 'Скачать',
            order: 1,
            className: 'editor-button--top editor-button--download disabled',
            isButton: true,
            html: {
              isCustomSVG: true,
              inner: iconSave,
            },
            onClick: (e, el, pswpInstance) => {
              if (editor) {
                if (onChangeFile) {
                  const b64 = editor.getImage();
                  fetch(b64)
                    .then(res => res.blob())
                    .then(blob => {
                      const file = new File([ blob ], `${new Date()}.jpg`, { type: 'image/jpg' });
                      if (onChangeFile) onChangeFile(file, pswpInstance.currSlide.data.src);
                    });
                }
                editor.destroy();
                editor = null;
                isEdit = false;
              }

              for (let el of pswpInstance.template.getElementsByClassName('editor-button--bottom')) {
                el.classList.add('disabled');
              }
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--top')) {
                el.classList.add('disabled');
              }
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--button')) {
                el.classList.remove('active');
              }
              if (pswpInstance.options.dataSource.items.length > 1) {
                for (const el of pswpInstance.template.querySelectorAll('.pswp__button--arrow')) {
                  el.style.display = 'inline-block';
                }
              }
              pswpInstance.template.querySelector('.editor-button--button-left').classList.add('active');
              pswpInstance.currSlide.content.element.style.display = 'inline-block';
              lightbox.destroy();
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // undo
          lightbox.pswp.ui.registerElement({
            name: 'undoButton',
            title: 'Отменить',
            order: 2,
            className: 'editor-button--top editor-button--undo disabled',
            isButton: true,
            html: {
              isCustomSVG: true,
              inner: iconReturn,
            },
            onClick: (e, el, pswpInstance) => {
              editor.undoLast();
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // rotate right
          lightbox.pswp.ui.registerElement({
            name: 'rotateLeftButton',
            title: 'Повернуть влево',
            order: 2,
            className: 'editor-button--top editor-button--rotate-left -disabled',
            isButton: true,
            html: {
              isCustomSVG: true,
              inner: iconRotateLeft,
            },
            onClick: async (e, el, pswpInstance) => {
              const sizes = await editor.rotateLeft();
              pswpInstance.currSlide.width = sizes[0];
              pswpInstance.currSlide.height = sizes[1];
              pswpInstance.currSlide.prevDisplayedWidth = sizes[0];
              pswpInstance.currSlide.prevDisplayedHeight = sizes[1];
              lightbox.pswp.updateSize(true);
              pswpInstance.zoomTo(0, pswpInstance.getViewportCenterPoint());
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // rotate left
          lightbox.pswp.ui.registerElement({
            name: 'rotateRightButton',
            title: 'Повернуть вправо',
            order: 3,
            className: 'editor-button--top editor-button--rotate-right -disabled',
            isButton: true,
            html: {
              isCustomSVG: true,
              inner: iconRotateRight,
            },
            onClick: async (e, el, pswpInstance) => {
              const sizes = await editor.rotateRight();
              pswpInstance.currSlide.width = sizes[0];
              pswpInstance.currSlide.height = sizes[1];
              pswpInstance.currSlide.prevDisplayedWidth = sizes[0];
              pswpInstance.currSlide.prevDisplayedHeight = sizes[1];
              lightbox.pswp.updateSize(true);
              pswpInstance.zoomTo(0, pswpInstance.getViewportCenterPoint());
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // button move
          lightbox.pswp.ui.registerElement({
            name: 'bottomMoveButton',
            appendTo: 'wrapper',
            title: 'Перемещение',
            className: `editor-button--button editor-button--button-left ${!isEdit ? 'active' : ''}`,
            isButton: true,
            html: 'Перемещение',
            onClick: (e, el, pswpInstance) => {
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--bottom')) {
                el.classList.add('disabled');
              }
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--button')) {
                el.classList.remove('active');
              }
              el.classList.add('active');

              if (editor) editor.disableDraw();
              isEdit = false;
              isMove = true;
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // button edit
          lightbox.pswp.ui.registerElement({
            name: 'bottomEditButton',
            appendTo: 'wrapper',
            title: 'Рисование',
            className: `editor-button--button editor-button--button-right ${isEdit ? 'active' : ''}`,
            isButton: true,
            html: 'Рисование',
            onClick: (e, el, pswpInstance) => {
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--bottom')) {
                el.classList.remove('disabled');
              }
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--top')) {
                el.classList.remove('disabled');
              }
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--button')) {
                el.classList.remove('active');
              }
              for (const el of pswpInstance.template.querySelectorAll('.pswp__button--arrow')) {
                el.style.display = 'none';
              }
              el.classList.add('active');

              isEdit = true;
              isMove = false;

              const container = pswpInstance.currSlide.container;
              const item = pswpInstance.currSlide.content.element;

              if (editor) {
                editor.enableDraw();
              } else {
                let sizes = [ item.naturalWidth, item.naturalHeight ];
                if (sizes[0] > sizes[1]) {
                  sizes[1] = сanvasSize / sizes[0] * sizes[1];
                  sizes[0] = сanvasSize;
                } else if (sizes[1] > sizes[0]) {
                  sizes[0] = сanvasSize / sizes[1] * sizes[0];
                  sizes[1] = сanvasSize;
                }
                editor = new drawing({
                  container,
                  brushSize: strokeWidth,
                  color: strokeColor,
                  canvasSize: sizes
                });
                editor.setStyleSizes(item.offsetWidth, item.offsetHeight);

                editor.setBackground('#ffffff');
                editor.setBackgroundImage(item);
              }

              item.style.display = 'none';
              lightbox.pswp.updateSize(true);
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // top bg
          lightbox.pswp.ui.registerElement({
            name: 'topBarBg',
            appendTo: 'wrapper',
            className: 'editor-top-bar-bg'
          });

          // bottom bg
          lightbox.pswp.ui.registerElement({
            name: 'bottomBarBg',
            appendTo: 'wrapper',
            className: 'editor-bottom-bar-bg',
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // pen size
          lightbox.pswp.ui.registerElement({
            name: 'largeBrushButton',
            appendTo: 'wrapper',
            title: 'Толстый',
            className: `editor-button--bottom editor-button--size editor-button--size-large ${!isEdit ? 'disabled' : ''}`,
            isButton: true,
            html: {
              isCustomSVG: true,
              inner: iconPenLarge,
            },
            onClick: (e, el, pswpInstance) => {
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--size')) {
                el.classList.remove('active');
              }
              el.classList.add('active');
              editor.setBrushSize(25);
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // pen size
          lightbox.pswp.ui.registerElement({
            name: 'mediumBrushButton',
            appendTo: 'wrapper',
            title: 'Средний',
            className: `editor-button--bottom editor-button--size editor-button--size-medium active ${!isEdit ? 'disabled' : ''}`,
            isButton: true,
            html: {
              isCustomSVG: true,
              inner: iconPenMedium,
            },
            onClick: (e, el, pswpInstance) => {
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--size')) {
                el.classList.remove('active');
              }
              el.classList.add('active');
              editor.setBrushSize(15);
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // pen size
          lightbox.pswp.ui.registerElement({
            name: 'smallBrushButton',
            appendTo: 'wrapper',
            title: 'Тонкий',
            className: `editor-button--bottom editor-button--size editor-button--size-small ${!isEdit ? 'disabled' : ''}`,
            isButton: true,
            html: {
              isCustomSVG: true,
              inner: iconPenThin,
            },
            onClick: (e, el, pswpInstance) => {
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--size')) {
                el.classList.remove('active');
              }
              el.classList.add('active');
              editor.setBrushSize(5);
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // erase
          lightbox.pswp.ui.registerElement({
            name: 'eraseButton',
            appendTo: 'wrapper',
            title: 'Ластик',
            className: `editor-button--bottom editor-button--color editor-button--eraser ${!isEdit ? 'disabled' : ''}`,
            isButton: true,
            html: {
              isCustomSVG: true,
              inner: iconEraser,
            },
            onClick: (e, el, pswpInstance) => {
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--color')) {
                el.classList.remove('active');
              }
              el.classList.add('active');
              editor.setSelectedTool('eraser');
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // color
          lightbox.pswp.ui.registerElement({
            name: 'blackColorButton',
            appendTo: 'wrapper',
            title: 'Черный',
            className: `editor-button--bottom editor-button--color editor-button--color-black ${!isEdit ? 'disabled' : ''}`,
            isButton: true,
            html: {
              isCustomSVG: true,
              inner: circle,
            },
            onClick: (e, el, pswpInstance) => {
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--color')) {
                el.classList.remove('active');
              }
              el.classList.add('active');
              editor.setColor('#000000');
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // color
          lightbox.pswp.ui.registerElement({
            name: 'greenColorButton',
            appendTo: 'wrapper',
            title: 'Зеленый',
            className: `editor-button--bottom editor-button--color editor-button--color-green ${!isEdit ? 'disabled' : ''}`,
            isButton: true,
            html: {
              isCustomSVG: true,
              inner: circle,
            },
            onClick: (e, el, pswpInstance) => {
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--color')) {
                el.classList.remove('active');
              }
              el.classList.add('active');
              editor.setColor('#00ff00');
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // color
          lightbox.pswp.ui.registerElement({
            name: 'blueColorButton',
            appendTo: 'wrapper',
            title: 'Синий',
            className: `editor-button--bottom editor-button--color editor-button--color-blue ${!isEdit ? 'disabled' : ''}`,
            isButton: true,
            html: {
              isCustomSVG: true,
              inner: circle,
            },
            onClick: (e, el, pswpInstance) => {
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--color')) {
                el.classList.remove('active');
              }
              el.classList.add('active');
              editor.setColor('#0000ff');
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // color
          lightbox.pswp.ui.registerElement({
            name: 'redColorButton',
            appendTo: 'wrapper',
            title: 'Красный',
            className: `editor-button--bottom editor-button--color editor-button--color-red ${!isEdit ? 'disabled' : ''} active`,
            isButton: true,
            html: {
              isCustomSVG: true,
              inner: circle,
            },
            onClick: (e, el, pswpInstance) => {
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--color')) {
                el.classList.remove('active');
              }
              el.classList.add('active');
              editor.setColor('#ff0000');
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });

          // color
          lightbox.pswp.ui.registerElement({
            name: 'redColorButton',
            appendTo: 'wrapper',
            title: 'Белый',
            className: `editor-button--bottom editor-button--color editor-button--color-white ${!isEdit ? 'disabled' : ''}`,
            isButton: true,
            html: {
              isCustomSVG: true,
              inner: circle,
            },
            onClick: (e, el, pswpInstance) => {
              for (let el of pswpInstance.template.getElementsByClassName('editor-button--color')) {
                el.classList.remove('active');
              }
              el.classList.add('active');
              editor.setColor('#ffffff');
            },
            onInit: (el, pswp) => el.style.display = 'none'
          });
        });

        lightbox.on('imageSizeChange', ({ content, width, height, slide }) => {
          if (editor) editor.setStyleSizes(width, height);
        });
        lightbox.on('pointerDown', (e) => isEdit && e.preventDefault());
        lightbox.on('pointerMove', (e) => isEdit && e.preventDefault());
        lightbox.on('pointerUp', (e) => isEdit && e.preventDefault());
        lightbox.on('pinchClose', (e) => isEdit && e.preventDefault());
        lightbox.on('verticalDrag', (e) => isEdit && e.preventDefault());
        lightbox.on('containerMove', (e) => (isEdit || isMove) && e.preventDefault());
        lightbox.on('close', ({ content, width, height, slide }) => {
          if (editor) {
            editor.destroy();
            editor = null;
            isEdit = false;
          }
        });
        lightbox.on('contentActivate', ({ content }) => {
          for (const el of content.instance.template.querySelectorAll('.editor-button--bottom, .editor-button--top, .editor-button--button, .editor-bottom-bar-bg')) {
            el.style.display = (content.type === 'image') ? 'inline-block' : 'none';
          }

          if (content.type === 'image')
            lightbox.pswp.template.classList.add('pswp--editor');
          else
            lightbox.pswp.template.classList.remove('pswp--editor');
        });
      }

      lightbox.init();
    }

    return () => {
      if (lightbox) {
        lightbox.destroy();
        lightbox = null;
      }
      if (editor) {
        editor.destroy();
        editor = null;
      }
    };

    // eslint-disable-next-line
  }, [slider]);

  return (
    <div className={styles.root}>
      <div className={styles.scroller} ref={slider}>
        {items.map(({ is_video, link }, index) =>
          <Slide
            index={index}
            link={link}
            isVideo={is_video}
            key={index}
          />
        )}
      </div>
    </div>
  );
};

export default Gallery;