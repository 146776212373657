import { useSelector } from 'react-redux';
import { MainLayout } from '../../../../components/Layouts';
import HeaderMentor from '../../../../components/header/HeaderMentor';
import Dropdown from '../../../../components/Dropdown';
import useApiQuery from '../../../../hooks/useApiQuery';
import { selectItem } from '../../../../store/app/getters';

import styles from './faq.module.css';

const Faq = () => {
  const user = useSelector(selectItem(['user']));

  const { data: faq } = useApiQuery({
    name: ['faq'],
    path: `/faq/all/`
  });

  const { data: pin_code } = useApiQuery({
    name: ['pin_code'],
    path: `/getpincode/${user.uid}/`,
    skip: !user
  });

  return (
    <MainLayout>
      <HeaderMentor user={user} pin_code={pin_code} />
      <div className={styles.title}>
        Вопрос/Ответ
        <img src="/images/colors-row.svg" className={styles.titleBorder} alt="" />
      </div>
      <div className={styles.subtitle}>
        Остались вопросы? <br /> Возможно вы найдете ответы здесь
      </div>
      <div className={styles.content}>
        {faq && faq.map(({ uid, title, description }) => (
          <Dropdown title={title} key={uid}>
            {description}
          </Dropdown>
        ))}
      </div>
    </MainLayout>
  );
}

export default Faq;
