import classnames from 'classnames';

import styles from '../balance.module.css';

const Rewards = ({ items }) => {
  return (
    <>
      <div className={styles.row}>
        Недельные
        <span className={classnames(styles.value, styles.valueCircle)}>
          {items ? items.filter(({ type }) => type === 'W').length : 0}
        </span>
      </div>
      <div className={styles.row}>
        Месячные
        <span className={classnames(styles.value, styles.valueCircle)}>
          {items ? items.filter(({ type }) => type === 'M').length : 0}
        </span>
      </div>
      <div className={styles.row}>
        Квартальные
        <span className={classnames(styles.value, styles.valueCircle)}>
          {items ? items.filter(({ type }) => type === 'Q').length : 0}
        </span>
      </div>
      <div className={styles.row}>
        Годовые
        <span className={classnames(styles.value, styles.valueCircle)}>
          {items ? items.filter(({ type }) => type === 'Y').length : 0}
        </span>
      </div>
    </>
  );
};

export default Rewards;
