import { useLocation } from 'react-router';
import { useEffect } from 'react';

const ScrollToTop = () => {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  useEffect(() => {
    if (hash.substring(1)) {
      const elem = document.getElementById(hash.substring(1));
      let offsetTop = elem ? elem.offsetTop : 0;

      window && offsetTop && window.scroll({
        top: offsetTop,
      });
    }
  }, [hash, key])

  return null;
};

export default ScrollToTop;

