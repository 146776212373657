import Button from 'components/Button';

import styles from './new-quest.module.css';

const NewQuest = ({ children }) => {
  return (
    <div className={styles.root} style={{ backgroundImage: 'url(/images/new_quest_bg.svg)'}}>
      <div className={styles.title}>{children}</div>
      <div className={styles.buttons}>
        <Button
          fullWidth={true}
          butStyle="primary"
          to="/quests-wheel"
        >
          Открыть рулетку
        </Button>
      </div>
    </div>
  )
};

export default NewQuest;
