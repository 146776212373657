import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';

import styles from './audio.module.css';

const PlayButton = ({ isPlaying, onClick }) => {
  const handleMouseEnter = (e) => {
    e.target.querySelector('#circle_1').setAttribute('fill-opacity', 0.4);
    e.target.querySelector('#circle_2').setAttribute('fill-opacity', 0.2);
  }

  const handleMouseLeave = (e) => {
    e.target.querySelector('#circle_1').setAttribute('fill-opacity', 0);
    e.target.querySelector('#circle_2').setAttribute('fill-opacity', 0);
  }

  return (
    <ReactSVG
      className={classnames(styles.playButton, isPlaying && styles.playing)}
      src="/icons/icon-play-video.svg"
      onClick={onClick}
      onMouseEnter={(e) => handleMouseEnter(e)}
      onMouseLeave={(e) => handleMouseLeave(e)}
    />
  );
};

const Audio = ({ src, controls, onReady, autoPlay = false }) => {
  const player = useRef(null);
  const [ isPlaying, setIsPlaying ] = useState(autoPlay);
  const [ played, setPlayed ] = useState(0);

  // useEffect(() => {
  //   if (controls && isReady && !isPlaying) setTimeout(() => setButtonShow(true), 100);
  //   else setButtonShow(false);
  // }, [controls, isReady, isPlaying]);

  const handleClickBar = (e) => {
    const { width, left } = e.target.getBoundingClientRect();
    const playedPos = (e.pageX - left) / width;

    player.current.seekTo(parseFloat(playedPos));
    setPlayed(playedPos);
  };

  const handleProgress = (state) => {
    setPlayed(state.played);
  }

  if (!src) return null;

  return (
    <div className={styles.root}>
      <PlayButton isPlaying={isPlaying} onClick={() => setIsPlaying(!isPlaying)} />

      <div className={styles.progress} onClick={e => handleClickBar(e)}>
        <div className={styles.progressBar} style={{ width: `${played*100}%` }} />
      </div>

      <ReactPlayer
        url={src}
        width="0"
        height="0"
        controls={false}
        playing={isPlaying}
        onReady={() => { onReady && onReady(player.current) }}
        onClick={() => setIsPlaying(false)}
        onEnded={() => { setIsPlaying(false); player.current && player.current.seekTo(0); }}
        onProgress={handleProgress}
        ref={player}
      />
    </div>
  );
};

export default Audio;
