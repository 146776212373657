import Button from 'components/Button';
import classnames from 'classnames';

import styles from './balance.module.css';

const Placeholder = ({ userType, title, childTitle, linkUrl, buttonText }) => {
  if (userType === 'M') {
    return (
      <div className={classnames(styles.item, styles.placeholderMentor)} style={{ backgroundImage: 'url(/images/settings_bg.svg)' }}>
        <div className={styles.placeholderTitle} dangerouslySetInnerHTML={{ __html: title }} />
        <div className={styles.placeholderButtons}>
          <Button
            className={styles.placeholderButton}
            butStyle="primary"
            to={linkUrl}
            fullWidth
          >
            {buttonText}
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classnames(styles.item, styles.placeholder)} style={{ backgroundImage: 'url(/images/settings_bg.png)' }}>
        <div className={styles.placeholderTitle} dangerouslySetInnerHTML={{ __html: childTitle }} />
        <div className={styles.placeholderBar}>
          <div className={styles.placeholderProgress}>start</div>
        </div>
      </div>
    );
  }
};

export default Placeholder;