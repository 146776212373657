import { ReactSVG } from 'react-svg';

import styles from './skill-bar.module.css';

const SkillBar = ({ value = 0, max = 100, color = '#000' }) => {
  const percent = value <= max ? (value * 100) / max : 100;
  return (
    <div className={styles.root}>
      <ReactSVG src="/images/medal.svg" className={styles.icon} style={{ borderColor: color, fill: color }} />
      <div className={styles.content}>
        <span className={styles.value}>{value} <img src="/images/energy.svg" alt="" /></span>
        <div className={styles.bar}>
          <div className={styles.progress} style={{ width: `${percent}%`, background: color }}></div>
        </div>
      </div>
    </div>
  )
};

export default SkillBar;