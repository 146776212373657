import { useState } from 'react';
import Switch from 'components/fields/Switch';
import RewardCheckbox from './RewardCheckbox';
import CustomReward from './CustomReward';

import styles from './rewards-update.module.css';

const durations = [
  { label: 'Неделя', value: 'W' },
  { label: 'Месяц', value: 'M' },
  { label: '3 месяца', value: 'Q' },
  { label: 'Год', value: 'Y' },
];

const defaultDuration = 'W';

const RewardsUpdate = ({ items, onChange }) => {
  const [ duration, setDuration ] = useState(defaultDuration);

  return (
    <div>
      <div className={styles.rewardHeader}>
        <Switch id="duration" name="duration" options={durations} value={duration} onChange={e => setDuration(e.target.value)} />
      </div>

      {items &&
        Object.values(items[duration]).map(({ uid, name, rewards, is_custom, description }) => {
          const selected = Object.values(rewards).find(({ is_checked }) => !!is_checked);

          if (is_custom) {
            return (
              (rewards && Object.values(rewards).length) ? (
                Object.values(rewards).map(({ uid, reward_name, is_checked }) => (
                  <RewardCheckbox
                    uid={uid}
                    name={reward_name}
                    checked={is_checked}
                    selected={selected && selected.uid}
                    onChange={onChange}
                    key={uid}
                  />
                ))
              ) : null
            );
          }

          return (
            <RewardCheckbox
              uid={uid}
              name={name}
              rewards={rewards}
              description={description}
              checked={!!selected}
              selected={selected && selected.uid}
              onChange={onChange}
              key={uid}
            />
          );
        })
      }

      {(items && items[duration]) &&
        Object.values(items[duration]).map(({ uid, is_custom }) => {
          return is_custom ? (
            <CustomReward
              uid={uid}
              onSave={value => onChange(uid, value, true)}
              key={uid}
            />
          ) : null;
        })
      }
    </div>
  );
};

export default RewardsUpdate;
