import classnames from 'classnames';

import styles from './skills.module.css';

const AmbitionItem = ({ ambition, onClickCancel, color }) => {
  if (!color) color = ambition.color;
  const { skill_description, reward_name, skill_icon, summ, value, is_complete } = ambition;
  const percent = summ * 100 / value;

  return (
    <div className={styles.skill}>
      <div className={styles.skillContent}>
        <div className={styles.name}>
          {skill_description}
        </div>
        <div className={styles.status}>
          <div className={styles.bar}>
            <div className={styles.barBg} style={{ background: color }}></div>
            <div className={styles.progress} style={{ minWidth: `${percent}%`, background: color, color: color === '#fff' ? '#1B1B1B' : '#fff' }}>
              {summ}
            </div>
          </div>
          <div className={styles.reward}>
            <div className={styles.rewardIconWrap}>
              <img src={skill_icon} className={styles.rewardIcon} style={{ borderColor: color, fill: color }} alt="" />
            </div>
            <span className={styles.rewardValue}>
              {is_complete ? <img className={styles.rewardCheck} src="/icons/icon-checked-green.svg" alt="" /> : value}
            </span>
          </div>
        </div>
      </div>
      <div className={styles.rewardText}>{reward_name}</div>
      {onClickCancel &&
        <div className={styles.buttons}>
          <button className={styles.cancel} onClick={() => onClickCancel(ambition)}>Отменить</button>
        </div>
      }
    </div>
  )
};

const Ambitions = ({ items, onClickCancel, showDivider, color }) => {
  if (!items) return null;

  return (
    <div className={styles.list}>
      {items.map((item, index) => {
        if (!item.uid) return null;
        return (
          <div className={classnames(styles.item, showDivider && styles.divide)} key={index}>
            <AmbitionItem ambition={item} onClickCancel={onClickCancel} color={color} />
          </div>
        );
      })}
    </div>
  )
};

export default Ambitions;