import { Link } from 'react-router-dom';
import classnames from 'classnames';

import styles from './hp-stories.module.css';

const HpStories = ({ items }) => {
  if (!items) items = [1, 2, 3, 4, 5]; // preloader

  if (!items.length) return null;

  return (
    <div className={classnames(styles.list, 'scroll-list')}>
      {items.map(({ uid, image }, index) => {
        if (!uid) return (
          <div className={classnames(styles.item, 'skeleton-loader')} key={index}></div>
        );

        return (
          <Link
            to={`/quests-group/${uid}`}
            className={styles.item}
            style={{ backgroundImage: `url(${image})` }}
            key={index}
          >
          </Link>
        );
      })}
    </div>
  );
};

export default HpStories;
