import { useSelector, useDispatch } from 'react-redux';
import RewardsList from 'components/RewardsList';
import { MainLayout } from 'components/Layouts';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import * as api from 'helpers/api';

const Rewards = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));

  const { data: rewards } = useApiQuery({
    name: ['rewards_mentor_player'],
    path: `/playerrewardsfrommentor/${user.uid}/player/`,
    skip: !user || !user.uid,
    mapper: (data = {}) => {
      return Object.values(data).reduce((prev, current) => {
        if (current) return ([ ...prev, ...current ]);
        return prev;
      }, []);
    }
  });

  if (!user) return null;

  const handleClickAccept = (reward_uid, type) => {
    dispatch(setItem(['show_loader'], true));

    api
      .get(`/playerrewardsfrommentor/${user.uid}/accept/${reward_uid}/${type}/`)
      .then(response => {
        let result = { ...response.data.response };
        delete(result.message);

        if (result) {
          const rewards = Object.values(result).reduce((prev, current) => {
            if (current) return ([ ...prev, ...current ]);
            return prev;
          }, []);
          dispatch(setItem(['rewards_mentor_player'], rewards, { isHard: true }));
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'rewardsmentor'], { type: 'error', title: 'Ошибка', content: error }));
        }
        dispatch(setItem(['show_loader'], false));
      })
      .catch(error => {
        console.log(error)
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
        dispatch(setItem(['show_loader'], false));
      });
  };

  return (
    <MainLayout title="Награды от взрослых">
      <RewardsList items={rewards} showAccept={true} onClickAccept={handleClickAccept} />
    </MainLayout>
  );
};

export default Rewards;
