import classnames from 'classnames';
import ruPlural from '../../../helpers/ruPlural';

import styles from './modal-approve.module.css';

const ModalApprove = ({ approves, isOpen, isAnimated, name }) => {
  return (
    <div className={classnames(styles.root, (isOpen && isAnimated) && styles.animated)}>
      <h1 className={classnames(styles.title, 'container')}>
        +{approves} {ruPlural(approves, ['Подтверждение', 'Подтверждения', 'Подтверждений'])} <br /> {name && `для ${name}`}
      </h1>
    </div>
  );
};

export default ModalApprove;
