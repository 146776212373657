import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import Modal from '../../../Modal';
import InfoButton from '../../../Block/InfoButton';
import LoginMentor from '../LoginMentor';
import { setItem } from '../../../../store/app/actions';
import { selectItem } from '../../../../store/app/getters';

import styles from './sidebar-player.module.css';

const MentorButton = ({ uid, name, surname, avatar, gender, onClickUser }) => {
  avatar = avatar ? avatar : (`/icons/icon-mentor-${gender ? gender.toLowerCase() : 'm'}.svg`);

  return (
    <div className={styles.button} onClick={() => onClickUser(uid)}>
      <div className={styles.avatar}>
        <img className={styles.buttonImage} src={avatar} alt="" />
      </div>
      <div className={styles.buttonName}>
        {name && name}
      </div>
    </div>
  );
};

const SidebarPlayer = ({ user, mentors }) => {
  const dispatch = useDispatch();
  const [ mentor, setMentor ] = useState(null);
  const open = useSelector(selectItem(['common', 'side_menu', 'open']));

  const toggleOpen = (open) => {
    dispatch(setItem(['common', 'side_menu', 'open'], open));
  };

  const handleClickUser = (uid_mentor) => {
    setMentor(mentors.find(mentor => uid_mentor === mentor.uid_mentor));
    toggleOpen(false);
  };

  const Title = () => (
    <div className={styles.title}>
      <ReactSVG
        src="/icons/icon-close.svg"
        className={styles.closeButton}
        onClick={() => toggleOpen(false)}
      />
      Взрослые
      <InfoButton slug="mentors" />
    </div>
  );

  return (
    <>
      <Modal
        title={<Title />}
        viewStyle="left"
        showClose={false}
        open={open}
        onClose={() => toggleOpen(false)}
      >
        <ul className={styles.list}>
          {(mentors && Object.keys(mentors).length) ? (
            Object.values(mentors).map(({ uid_mentor, name, surname, info, avatar, gender }, index) => {
              name = info ? info.name : name;
              surname = info ? info.surname : surname;
              avatar = info ? info.avatar : avatar;
              gender = info ? info.gender : gender;
              return (
                <li key={uid_mentor + index} className={styles.listItem}>
                  <MentorButton
                    uid={uid_mentor}
                    avatar={avatar}
                    name={name}
                    surname={surname}
                    gender={gender}
                    onClickUser={handleClickUser}
                  />
                </li>
              );
            })
          ) : null}
        </ul>
      </Modal>
      {mentor &&
        <LoginMentor
          user={user}
          mentor={mentor}
          onClose={() => setMentor(null)}
        />
      }
    </>
  );
};

export default SidebarPlayer;
