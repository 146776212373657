import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import useApiQuery from '../../hooks/useApiQuery';
import { MainLayout } from '../../components/Layouts';
import { QuestDetails, QuestDescription, QuestSkills } from '../../components/quests/Quest';
import Block from '../../components/Block';
import ButtonsContainer from '../../components/ButtonsContainer';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import InfoBlock from '../../components/InfoBlock';
import * as api from '../../helpers/api';
import getCurrentHost from '../../helpers/getCurrentHost';
import copyToClipboard from '../../helpers/copyToClipboard';
import { selectItem } from '../../store/app/getters';
import { setItem } from '../../store/app/actions';

const Quest = () => {
  let { qid } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [ startedQuest, setStartedQuest ] = useState(null);

  const user = useSelector(selectItem(['user']));
  const cutPoints = !location.state || !location.state.from || location.state.from !== 'wheel';
  const showBackLink = !cutPoints;

  const handleClickStart = () => {
    api
      .get(`/addUserQuest/${user.uid}/${quest.uid}/`)
      // .get(`/addUserQuest/${user.uid}/${quest.uid}/${cutPoints ? 'reward_cut' : ''}`)
      .then(response => {
        const result = response.data.response;
        if (result && result.uid) {
          dispatch(setItem(['user_quests', 'active', result.uid], result));
          dispatch(setItem(['user', 'active_quest_count'], user.active_quest_count+1));
          setStartedQuest(result.uid);
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'addUserQuest'], { type: 'error', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickShare = (qid) => {
    const result = copyToClipboard(`${getCurrentHost()}/quest/${qid}`);

    if (result)
      dispatch(setItem(['notifications', 'copy_link'], { type: 'success', content: 'Ссылка на квест скопирована' }));
    else
      dispatch(setItem(['notifications', 'copy_link'], { type: 'error', content: 'Ошибка копирования' }));
  }

  const { data: quest, error } = useApiQuery({
    name: ['quest', qid],
    path: `/getQuestAll/?uid=${qid}`,
    mapper: (data = []) => {
      return (data && data.length) ? data[0] : null;
    },
    skip: !qid
  });

  useEffect(() => {
    if (error) {
      dispatch(setItem(['notifications', 'request'], { type: 'error', content: error }));
      navigate('/quests-wheel', { replace: true });
    }
    if (quest === null) {
      dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Квест не найден' }));
      navigate('/quests-wheel', { replace: true });
    }
    // eslint-disable-next-line
  }, [quest, error]);

  return (
    <MainLayout title={quest && quest.name}>
      <QuestDetails user={user} quest={quest} cutPoints={cutPoints} />
      <QuestSkills quest={quest} />
      <div style={{ marginTop: '20px' }} />

      {quest && (
        <Block title="Задание">
          <QuestDescription user={user} quest={quest} />
          {quest.link &&
            <ButtonsContainer>
              <Button butStyle="secondary" size="small" fullWidth="true" href={quest.link}>Дополнительно</Button>
            </ButtonsContainer>
          }

          {user.type === 'M' &&
            <div style={{ marginTop: '15px' }}>
              <InfoBlock color="red">
                Взять квест может только ребенок в своем аккаунте
              </InfoBlock>
            </div>
          }

          <ButtonsContainer>
            {user.type === 'P' ? (
              <Button
                butStyle="primary"
                fullWidth={true}
                onClick={() => handleClickStart()}
              >
                Начать выполнение
              </Button>
            ) : (
              <Button
                butStyle="tertiary"
                onClick={() => handleClickShare(qid)}
              >
                Поделиться квестом с ребенком
              </Button>
            )}
            {showBackLink &&
              <Button
                onClick={() => navigate(-1)}
                butStyle="tertiary"
                fullWidth={true}
              >Вернуться в рулетку</Button>
            }
          </ButtonsContainer>
        </Block>
      )}

      <Modal
        title="Фото"
        viewStyle="bottom"
        open={!!startedQuest}
        onClose={() => { setStartedQuest(null); navigate(`/user-quest/${startedQuest}`); }}
        buttons={[
          { text: 'Хорошо', action: 'click', style: 'primary', onClick: () => navigate(`/user-quest/${startedQuest}`) }
        ]}
      >
        Попроси друзей сфотографировать тебя во время выполнения квеста. Когда нужно будет подтвердить выполнение они должны будут загрузить это фото!
      </Modal>
    </MainLayout>
  );
};

export default Quest;
