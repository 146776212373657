import { useSelector } from 'react-redux';
import QRCode from 'react-qr-code';
import classnames from 'classnames';
import { MainLayout } from '../../components/Layouts';
import Block from '../../components/Block';
import { selectItem } from '../../store/app/getters';
import getCurrentHost from '../../helpers/getCurrentHost';

import styles from './player.module.css';

const InvitePlayer = () => {
  const user = useSelector(selectItem(['user']));

  return (
    <MainLayout contentValign="center">
      <Block align="center" headerAlign="center">
        <QRCode value={`${getCurrentHost()}`} size={200} />
      </Block>
      <Block title={`${user.name} ${user.surname}`} align="center">
        Пригласи друга по своему QR и получи
        <div className={classnames('accent-text', styles.semiBold)}>5 баллов</div>
        после его регистрации.
      </Block>
    </MainLayout>
  );
}

export default InvitePlayer;
