import { useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import Gallery from '../Gallery';

import styles from './message.module.css';

// name, text, audio, galery

const Message = ({ person, type, content, button, onExit, onClose }) => {
  const player = useRef(null);
  const [ isPlaying, setIsPlaying ] = useState(true);
  const [ played, setPlayed ] = useState(0);

  if (!content) return null;

  const { name, text, audio, galery } = content;

  // const handleClickBar = (e) => {
  //   const { width, left } = e.target.getBoundingClientRect();
  //   const playedPos = (e.pageX - left) / width;

  //   player.current.seekTo(parseFloat(playedPos));
  //   setPlayed(playedPos);
  // };

  const handleProgress = (state) => {
    setPlayed(state.played);
  };

  return (
      <div className={classNames(styles.root, 'container')}>
        <div className={classNames(styles.message, styles[type])}>
          <div className={styles.close} onClick={onClose}>
            <ReactSVG src="/icons/icon-close.svg" className={styles.closeIcon} alt="close" />
          </div>
          {(galery && galery.length) ? <div className={styles.gallery}><Gallery items={galery} /></div> : null}
          <div className={styles.content} dangerouslySetInnerHTML={{ __html: text }} />
          <div className={styles.bottom}>
            <img className={styles.head} src={person} alt={name} />
            <div className={styles.buttons}>
              <button
                className={classNames(styles.button, styles.secondary, !audio && styles.disabled)}
                onClick={() => setIsPlaying(!isPlaying)}
              >
                {isPlaying ? 'Пауза' : 'Озвучить'}
                <ReactSVG
                  src={isPlaying ? '/icons/icon-pause-video.svg' : '/icons/icon-play-video.svg'}
                  className={styles.buttonIcon}
                  wrapper="span"
                />
              </button>
              {button &&
                <button className={classNames(styles.button, styles.primary)} onClick={onExit}>
                  {button}
                  {/* <ReactSVG src="/images/game/button_right.svg" className={styles.buttonIcon} wrapper="span" /> */}
                </button>
              }
              {/* <div className={styles.progress} onClick={e => handleClickBar(e)}>
                <div className={styles.progressBar} style={{ width: `${played*100}%` }} />
              </div> */}
            </div>
            <div className={styles.name}>
              {name}
            </div>
          </div>
          {audio && (
            <ReactPlayer
              url={audio}
              width="0"
              height="0"
              controls={false}
              playing={isPlaying}
              onClick={() => setIsPlaying(false)}
              onEnded={() => { setIsPlaying(false); player.current && player.current.seekTo(0); }}
              onProgress={handleProgress}
              ref={player}
            />
          )}
        </div>
      </div>
  );
};

export default Message;