import { Link } from 'react-router-dom';
import classnames from 'classnames';
import getDuration from '../../../helpers/getDuration';
import ruPlural from '../../../helpers/ruPlural';

import styles from './quest-item-simple.module.css';

import { categories_bgs } from '../../../data/mock';

const QuestItemSimple = ({ quest }) => {
  const { uid, info, name, categories, lifetime_end, reward } = quest;
  const cid = categories ? categories[0]['cat_uid']+'' : '1';
  const bg = categories_bgs[cid.slice(-1)];

  if (!uid) return (
    <div className={classnames(styles.quest, 'skeleton-loader')} />
  );

  return (
    <Link to={`/user-quest/${uid}`} className={styles.quest} style={{ backgroundImage: `url(${bg}` }}>
      <div className={styles.title}>
        {info ? info.name : name}
      </div>
        {lifetime_end &&
        <div className={styles.row}>
          <div className={styles.label}>
            Осталось времени:
          </div>
          <div className={styles.value} dangerouslySetInnerHTML={{__html: getDuration(lifetime_end)}} />
        </div>
      }
      {reward &&
        <div className={styles.row}>
          <div className={styles.label}>
            Награда:
          </div>
          <div className={styles.value}>
            <span>{reward}</span> {ruPlural(reward, ['балл', 'балла', 'баллов'])}
          </div>
        </div>
      }
    </Link>
  );
};

export default QuestItemSimple;