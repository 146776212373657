import classnames from 'classnames';

import styles from './avatar-constructor.module.css';

const baseUrl = process.env.REACT_APP_PUCLIC_URL;

const Groups = ({ constructor, selectedGroup, onSelectGroup }) => {
  return (
    <div className={styles.groups}>
      {Object.values(constructor).map(({ uid, variants }, index) => (
        <div className={classnames(styles.group, selectedGroup === uid && styles.active)} key={index} onClick={() => onSelectGroup(uid)}>
          <img src={`${baseUrl}/avatar_components/${variants[0].url}`} className={styles.image} alt="" />
        </div>
      ))}
    </div>
  );
};

export default Groups;
