import { useState, useRef, useEffect } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MainLayout } from '../../../components/Layouts';
import InfoBlock from '../../../components/InfoBlock';
import Block from '../../../components/Block';
import ButtonsContainer from '../../../components/ButtonsContainer';
import Button from '../../../components/Button';
// import { QuestDescription } from '../../../components/quests/Quest';
import { setItem, deleteItem } from '../../../store/app/actions';
import { selectItem } from '../../../store/app/getters';
import useApiQuery from '../../../hooks/useApiQuery';
import * as api from '../../../helpers/api';
import Rating from '../../../components/Rating';

import styles from './Quests.module.css';

const Message = ({ user, quest }) => {
  return (
    <>
      {user.name}, вы подтверждаете,
      что <span className="accent-text">{quest.user.user_name} {quest.user.user_surname ? quest.user.user_surname : ''}</span> выполнил{quest.user.user_gender === 'W' && 'a'} квест <span className="accent-text">{quest.info.name}</span>
    </>
  );
};

const ApproveQuest = () => {
  let { token } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const photoInput = useRef(null);
  const commentInput = useRef(null);
  const [ rating, setRating ] = useState(0);

  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const max_file_size = useSelector(selectItem(['settings', 'max_file_size']));

  const [ uid_gamer, setUidGamer ] = useState(false);

  const { data: quest, error } = useApiQuery({
    name: ['quest_approve', token],
    path: `/getQuestAproove/${token}/`
  });

  useEffect(() => {
    if (quest) setUidGamer(quest.user.uid);
  }, [quest]);

  if (error) return <Navigate replace to="/" />;
  if (!quest || !user) return null;
  if (!quest.start || quest.is_complete || quest.is_cancel) return <Navigate replace to="/" />;

  const onChangePhoto = (e) => {
    const file = e.target.files[0];
    if (!file) return null;
    else if (file.size > max_file_size) {
      dispatch(setItem(['notifications', 'approveQuest'], { type: 'error', title: 'Ошибка', content: 'Макс. размер файла 20Мб' }));
      return null;
    }

    const fd = new FormData();
    fd.append('file', file, file.name);

    api
      .post(`/upload/`, fd, { mimeType: 'multipart/form-data', contentType: false })
      .then(response => {
        const result = response.data.response;

        if (result && result.file) {
          sendApprove(result.file);
        } else {
          const error = response.data.error ? response.data.error : 'Ошибка загрузки файла';
          dispatch(setItem(['notifications', 'approveQuest'], { type: 'error', title: null, content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const sendApprove = (photo) => {
    const fd = new FormData();
    if (photo) fd.append('photo', photo);
    if (rating) fd.append('mark', rating);
    if (commentInput.current && commentInput.current.value) fd.append('comment', commentInput.current.value);
    fd.append('quest_uid', quest.uid);

    api
      .post(`/aproove/`, fd, { mimeType: 'multipart/form-data', contentType: false })
      .then(response => {
        const result = response.data.response;

        if (result && result.sucess) {
          dispatch(deleteItem(['user_quests', uid_gamer, 'active'], quest.uid));

          dispatch(deleteItem(['api'], `/getUserQuest/${uid_gamer}/`));
          dispatch(deleteItem(['api'], `/getUserQuest/${uid_gamer}/${quest.uid}/`));
          dispatch(deleteItem(['api'], `/getUserProfile/${uid_gamer}/`));

          dispatch(setItem(['notifications', 'approveQuest'], { type: 'success', title: null, content: 'Квест успешно подтвержден' }));
          navigate('/');
        } else {
          const error = response.data.error ? response.data.error : 'Ошибка подтверждения';
          dispatch(setItem(['notifications', 'approveQuest'], { type: 'error', title: null, content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  // const onClickSubmit = () => {
  //   photoInput.current.click();
  // };

  if (currentPlayer && currentPlayer.info.tariff && currentPlayer.info.tariff.end && new Date(currentPlayer.info.tariff.end) < new Date())
    return (
      <Navigate to={`/select-tariff?from=approve&player=${currentPlayer.uid_gamer}`} replace={true} />
    );

  return (
    <MainLayout>
      <div style={{ padding: '10px' }} />
      <Block title={<Message user={user} quest={quest} />} align="center" headerAlign="center" />
      <div className={styles.approveRate}>
        <Rating defaultValue={rating} onChange={value => setRating(value)}>Оценка квеста</Rating>
      </div>
      {/* <Block>
        <QuestDescription quest={quest} />
        {quest.link &&
          <Button butStyle="text-primary" href={quest.link}>Дополнительно</Button>
        }
      </Block> */}

      <ButtonsContainer className={styles.buttons}>
        <form className={styles.form}>
          <textarea
            id="comment"
            name="comment"
            rows="4"
            placeholder="Поделитесь мнением о квесте"
            maxLength="255"
            ref={commentInput}
          />
          <input
            id="photo"
            type="file"
            name="photo"
            accept="image/*"
            onChange={e => onChangePhoto(e)}
            style={{ display: 'none' }}
            ref={photoInput}
          />
        </form>
        {(currentPlayer && (currentPlayer.score_day >= currentPlayer.reward_limit)) &&
          <InfoBlock color="orange">Игрок набрал максимальное количество баллов за сегодня и сможет завершить данный квест только завтра</InfoBlock>
        }
        {/* <Button
          fullWidth={true}
          butStyle="primary"
          onClick={() => onClickSubmit()}
          disabled={(currentPlayer && (currentPlayer.score_day >= currentPlayer.reward_limit))}
        >
          Приложить фото и подтвердить
        </Button> */}
        <Button
          fullWidth={true}
          butStyle="primary"
          onClick={() => sendApprove()}
          disabled={quest.need_file}
        >
          Подтвердить
        </Button>
        <Button
          fullWidth={true}
          butStyle="text-primary"
          to="/"
        >
          Отмена
        </Button>
      </ButtonsContainer>
    </MainLayout>
  );
}

export default ApproveQuest;
