import { Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import { setItem } from '../../store/app/actions';
import { selectItem } from '../../store/app/getters';

import styles from './tab-bar.module.css';

const LinkMenuGame = ({ id, href, name, icon, isActive }) => {
  const dispatch = useDispatch();
  const closeChat = () => dispatch(setItem(['service', 'chat_open'], false));

  return (
    <Link className={classnames(styles.button, isActive && styles.active)} to={href} onClick={closeChat} id={id}>
      <ReactSVG src={icon} className={styles.icon} wrapper="span" />
      <span className={styles.name}> {name} </span>
    </Link>
  );
};

const LinkMenu = ({ id, href, name, icon, isActive }) => {
  return (
    <Link className={classnames(styles.button, isActive && styles.active)} to={href} id={id}>
      <ReactSVG src={icon} className={styles.icon} wrapper="span" />
      <span className={styles.name}> {name} </span>
    </Link>
  );
};

const ButtonMenu = ({ id, name, icon , isActive }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(setItem(['common', 'side_menu', 'open'], true));
  };

  return (
    <button className={classnames(styles.button, isActive && styles.active)} onClick={handleClick} id={id}>
      <ReactSVG src={icon} className={styles.icon} wrapper="span" />
      <span className={styles.name}> {name} </span>
    </button>
  );
};

const checkIsActive = (location, menuItem) => {
  const active = menuItem.routes ? menuItem.routes.filter(value => location.startsWith(value)) : [];
  if (active.length || menuItem.href === location) return true;
}

const TabBar = ({ menu }) => {
  const settings = useSelector(selectItem(['settings']));
  const visibleSettings = useSelector(selectItem(['visible_settings']));
  let { pathname } = useLocation();

  if (!menu || !menu.length) return null;

  return (
    <div className={styles.main}>
      <div className={classnames(styles.list, 'container')}>
        {menu.map((item, index) => {
          if ((item.hidden && settings.user_agent !== 'skillary-mobile') || (!visibleSettings?.m_quest_icon && item.id === 'menu_quests')) return null;
          if (!visibleSettings?.a_map_visible_main && item.id === 'menu_map') return null;
          if (item.action === 'open-menu') return (
            <div className={styles.item} key={index}>
              <ButtonMenu {...item} isActive={checkIsActive(pathname, item)} />
            </div>
          );
          if (item.action === 'close-chat') return (
            <div className={styles.item} key={index}>
              <LinkMenuGame {...item} isActive={checkIsActive(pathname, item)} />
            </div>
          );
          return (
            <div className={styles.item} key={index}>
              <LinkMenu {...item} isActive={checkIsActive(pathname, item)} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TabBar;
