import { forwardRef, useState, useEffect } from 'react';
import classNames from 'classnames';

import styles from './checkbox.module.css';

const Checkbox = forwardRef(({ id, name, title, label, icon, checked: check, error, onChange, className, containerClass }, ref) => {
  const [ checked, setChecked ] = useState(check || false);

  useEffect(() => {
    setChecked(check);
  }, [check]);

  const handleChange = (e) => {
    setChecked(!checked);
    onChange && onChange(e);
  }

  return (
    <div className={classNames(styles.container, containerClass)}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.content}>
        {icon && <span className={styles.icon}>{icon}</span>}
        {label && <label className={styles.label} htmlFor={id}>{label}</label>}
        <label className={styles.checkbox} htmlFor={id}>
          <input
            type="checkbox"
            id={id}
            name={name}
            onChange={e => handleChange(e)}
            className={styles.input}
            checked={checked}
            ref={ref}
          />
          <span className={classNames(styles.checkboxIcon, checked && styles.checked, className)} />
        </label>
        {error &&
          <span className={styles.errorText}>{ error }</span>
        }
      </div>
    </div>
  );
});

export default Checkbox;
