import { useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RegisterLayout } from 'components/Layouts';
import Form from 'components/Form';
import PhoneCode from 'components/fields/PhoneCode';
import Button from 'components/Button';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
import standartPhone from 'helpers/standartPhone';
import parseSearchParams from 'helpers/parseSearchParams';
import ruPlural from 'helpers/ruPlural';
import * as api from 'helpers/api';

import styles from './account.module.css';

const Register = () => {
  let { search } = useLocation();
  const { phone } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectItem(['user']));
  const settings = useSelector(selectItem(['settings']));
  const visibleSettings = useSelector(selectItem(['visible_settings']));
  const verification = useSelector(selectItem(['verification']));
  const mobile_visible_payment = useSelector(selectItem(['settings', 'mobile_visible_payment']));

  useEffect(() => {
    if (!search) return null;
    let params = parseSearchParams(search);

    if (!params) return null;
    if (params.key) {
      api.get(`/api/keypost/${params.key.value}/`);
      delete params.key;
    }
    if (Object.keys(params).length) {
      dispatch(setItem(['tokens'], params));
    }

    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (!search || !verification.checkingPhone || !verification.isCodeSended) return null;
    const params = parseSearchParams(search);

    if (!params || !params.key) return null;
    if (verification.isCodeValid)
      api.get(`/api/keypost/${params.key.value}/${verification.checkingPhone.replace(/\s+/g, '')}/${verification.code}/`);
    else if (!verification.code)
      api.get(`/api/keypost/${params.key.value}/${verification.checkingPhone.replace(/\s+/g, '')}/`);
    // eslint-disable-next-line
  }, [verification]);

  const onPhoneSubmit = (result) => {
    dispatch(setItem(['user'], result.user));
  };

  const inputs = [
    { id: 'phone', name: 'phone', component: PhoneCode, validation: 'phone',
      props: {
        label: <>Bведите номер телефона <span className="accent-text-orange">взрослого</span></>,
        placeholder: '+7',
        fullWidth: true,
        mask: '+7 ### ### ## ##',
        type: 'tel',
        value: standartPhone(phone),
        onPhoneSubmit: onPhoneSubmit,
        path: '/auth'
      }
    }
  ];

  useEffect(() => {
    if (user && user.uid) {
      if (!user.name) navigate('/create-player');
      else navigate('/');
    }

    // eslint-disable-next-line
  }, [user]);

  const onFormSubmit = (data) => {
    if (!verification.isCodeSended) dispatch(setItem(['verification', 'sendCode'], true));
    if (!verification.isCodeValid) return null;
  };

  return (
    <RegisterLayout showLogo>
      <h1 className={styles.title}>Регистрация</h1>
      <div className={styles.subtitle}>
        <div>Развиваем навыки и формируем полезные привычки школьников</div>
        {/* {verification.isCodeSended && (
          <>
            Проверьте код из смс, он придет на номер
            <div><b>{verification.checkingPhone}</b></div>
          </>
        )} */}
      </div>
      {(mobile_visible_payment && visibleSettings?.reg_trial_text) && (
        <div className={styles.subtitle}>
          <span className="accent-text-green">
            {settings?.trial_period > 100 ?
              'Попробуйте бесплатно' : `Попробуйте ${settings.trial_period} ${ruPlural(settings.trial_period, ['день', 'дня', 'дней'])} бесплатно`
            }
          </span>
        </div>
      )}
      <div className={styles.content}>
        <Form
          inputs={inputs}
          onSubmit={onFormSubmit}
          buttons={!verification.isCodeSended && (
            <>
              <Button
                type="submit"
                fullWidth={true}
                butStyle="primary"
              >
                Получить код
              </Button>

              <div className={styles.terms}>
                Нажимая кнопку ”<span className="accent-text">Получить код</span>” вы соглашаетесь с <a href="https://skillary.club/docs/license.pdf">политикой обработки персональных данных</a> и принимаете <a href="https://skillary.club/docs/oferta.pdf">условия оферты сервиса</a>
              </div>
            </>
          )}
        />
      </div>
    </RegisterLayout>
  );
}

export default Register;
