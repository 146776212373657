import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { MainLayout } from 'components/Layouts';
import PlayerLogin from 'components/PlayerLogin';
import { selectItem } from 'store/app/getters';
import useApiQuery from 'hooks/useApiQuery';

const InvitePlayer = () => {
  let { uid } = useParams();
  const user = useSelector(selectItem(['user']));

  const { data: player } = useApiQuery({
    name: ['players', uid],
    path: `/getUserProfile/${uid}/`,
    mapper: (data = {}, prev) => {
      return {...prev, ...data.main};
    }
  });

  if (!user) return null;

  return (
    <MainLayout title="Ссылка для входа">
      {player &&
        <h3 className="text-center">Здесь находится аккаунт ребёнка {player.info ? player.info.name : player.name}</h3>
      }
      <div style={{ marginTop: '50px' }} />
      {player &&
        <PlayerLogin player={player} />
      }
      <div style={{ marginBottom: '40px' }} />
    </MainLayout>
  );
}

export default InvitePlayer;
