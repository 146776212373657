import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import * as api from '../../helpers/api';
import { MainLayout } from '../../components/Layouts';
import Form from '../../components/Form';
import { MentorsSelect } from '../../components/UsersSelect';
import Input from '../../components/fields/Input';
import Select from '../../components/fields/Select';
import Switch from '../../components/fields/Switch';
import DateInput from '../../components/fields/DateInput';
import ButtonsContainer from '../../components/ButtonsContainer';
import Button from '../../components/Button';
import UserHeader from '../../components/UserHeader';
import Block from '../../components/Block';
import { selectItem } from '../../store/app/getters';
import { setItem } from '../../store/app/actions';
import standartPhone from '../../helpers/standartPhone';

import styles from './player.module.css';

import { genders, grades, letters } from '../../data/mock';

// const tgBotUrl = process.env.REACT_APP_TG_BOT_URL;

const Update = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const visibleSettings = useSelector(selectItem(['visible_settings']));
  const [ userLocation, setUserLocation ] = useState(null);
  const [ count, setCount ] = useState(0);
  // const user_agent = useSelector(selectItem(['settings', 'user_agent']));

  const minDateBirth = new Date();
  minDateBirth.setFullYear(minDateBirth.getFullYear() - 12);

  const maxDateBirth = new Date();
  maxDateBirth.setFullYear(maxDateBirth.getFullYear() - 6);

  let locationOpts = [];
  let birthLocationOpts = [];

  useEffect(() => {
    if (user.location) {
      locationOpts.push({
        label: user.location,
        value: user.location
      });
      setUserLocation(user.location);
    }
    if (user.birth_location) {
      birthLocationOpts.push({
        label: user.birth_location,
        value: user.birth_location
      });
    }

    // eslint-disable-next-line
  }, [user]);

  const inputs = [
    { id: 'phone', name: 'phone', component: Input,
      props: { label: 'Номер телефона', value: standartPhone(user.phone), placeholder: '+7', fullWidth: true,
        mask: '+7 ### ### ## ##', disabled: !!(user.phone)
      }
    },
    { id: 'nickname', name: 'nickname', component: Input,
      props: { label: 'Никнейм', placeholder: 'Например, Ivanov', fullWidth: true,
      value: (user.nickname && user.nickname !== 'undefined') ? user.nickname : ''
    }
    },
    { id: 'gender', name: 'gender', component: Switch, validation: 'select',
      props: { id: 'gender', label: 'Пол', options: genders, value: user.gender || '' }
    },
    { id: 'school', name: 'school', component: Select, validation: 'select',
      props: {
        label: 'Номер школы', placeholder: 'Выберите школу', fullWidth: true, isSearchable: true,
        value: user.school || '', options: (user.school && user.school_name) ? [{ label: user.school_name, value: user.school }] : [],
        loadUrl: `/getschool/${userLocation}`, mapper: (data) => data.map(({ name, uid }) => ({ label: name, value: uid })),
        customParams: {
          user_uid: user.uid,
          city: user.location,
          buttonUrl: '/newschool/',
          buttonText: 'Школы нет в списке',
          inputText: 'Введите название школы',
        }
      }
    },
    { id: 'class', name: 'class', component: Select, validation: 'select',
      props: { label: 'Класс', placeholder: '1', options: grades, value: user.class || '' }
    },
    { id: 'class_sign', name: 'class_sign', component: Select, validation: 'select',
      props: { placeholder: 'А', options: letters, value: user.class_sign || '' }
    },
    { id: 'birth_date', name: 'birth_date', component: DateInput,
      props: { type: 'date', label: 'Дата рождения', value: user.birth_date || '', placeholder: '5 июня 1990 года',
        fullWidth: true, icon: <ReactSVG src="/icons/icon-calendar.svg" />, minDate: minDateBirth, maxDate: maxDateBirth
      }
    },
    /* { id: 'birth_time', name: 'birth_time', component: Input,
      props: { type: 'time', label: 'Время рождения', placeholder: 'Например, 12:30', fullWidth: true, value: user.birth_time || '',
        pattern: '[0-9]{2}:[09]{2}', icon: <ReactSVG src="/icons/icon-time.svg" />
      }
    },
    { id: 'birth_location', name: 'birth_location', component: Select, validation: 'select',
      props: {
        label: 'Место рождения', value: user.birth_location, query: user.birth_location, options: birthLocationOpts,
        placeholder: 'Выберите город', fullWidth: true, isSearchable: true, searchPlaceholder: 'Сочи',
        loadUrl: '/getcity', mapper: (data) => data.map(({ city }) => ({ label: city, value: city }))
      }
    }, */
    { id: 'location', name: 'location', component: Select, validation: 'select',
      props: {
        label: 'Город проживания', value: user.location, query: user.location, options: locationOpts,
        placeholder: 'Выберите город', fullWidth: true, isSearchable: true, searchPlaceholder: 'Например, "Новоуральск"',
        loadUrl: '/getcity', mapper: (data) => data.map(({ city }) => ({ label: city, value: city }))
      }
    }
  ];

  const onFormChange = (data) => {
    if (data.location) setUserLocation(data.location);
  };

  const onSubmit = data => {
    const fd = new FormData();
    for (let key in data) {
      if (key !== 'phone') fd.append(`params[${key}]`, data[key]);
    }

    api
      .post(`/updateUserProfile/${user.uid}/`, fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          dispatch(setItem(['user'], result.user));
          dispatch(setItem(['notifications', 'updateUser'], { type: 'success', content: 'Изменения сохранены' }));
        } else {
          dispatch(setItem(['notifications', 'updateUser'], { type: 'error', content: result.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  const handleClickSignOut = () => {
    dispatch(setItem(['user'], {}, { isHard: true }));
  };

  const buttons = (
    <>
      <Button
        type="submit"
        fullWidth={true}
        butStyle="primary"
      >
        Сохранить
      </Button>
    </>
  );

  return (
    <MainLayout title={<span onClick={() => setCount(count+1)}>Профиль</span>} infoSlug="profile">
      <UserHeader user={user} showEdit={true} />
      <MentorsSelect users={ user.mentor } />

      {/* {user_agent !== 'skillary-mobile' && (
        <ButtonsContainer>
          {user.tg_key ? (
            <Button
              butStyle="primary"
              fullWidth={true}
              disabled={true}
            >
              Telegram-бот подключен
            </Button>
          ) : (
            <>
              <Button
                href={`${tgBotUrl}${user.self_key}`}
                butStyle="primary"
                fullWidth={true}
                target="_blank"
              >
                Подключить Telegram бота
              </Button>
              <div className={styles.buttonsNotice}>Подключите Telegram-бота для получения важных уведомлений</div>
            </>
          )}
        </ButtonsContainer>
      )} */}

      <div style={{ marginBottom: '40px' }} />

      {visibleSettings?.a_referal_block && (
        <Block title="Реферальная программа">
          <ButtonsContainer>
            <Button
              to="/invited"
              butStyle="secondary"
              fullWidth={true}
            >
              Мои приглашенные
            </Button>
          </ButtonsContainer>
        </Block>
      )}

      <Block title="Данные игрока">
        <Form inputs={inputs} onChange={onFormChange} onSubmit={onSubmit} buttons={buttons} inputClass="dark" />
        {user && <div className={styles.userUid}>Пользователь ID {user.uid}</div>}
      </Block>

      {count > 10 && (
        <div className={styles.removeButton}>
          <button className={styles.link} onClick={handleClickSignOut}>Выйти</button>
        </div>
      )}
    </MainLayout>
  );
}

export default Update;
