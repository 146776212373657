import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import AvatarConstructor, { ConstructorPlaceholder } from 'components/AvatarConstructor';
import useApiQuery from 'hooks/useApiQuery';
import { setItem } from 'store/app/actions';
import { selectItem } from 'store/app/getters';
import * as api from 'helpers/api';

const CreateAvatar = () => {
  const user = useSelector(selectItem(['user']));
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [ payStatus, setPayStatus ] = useState(null);

  const { data: constructor } = useApiQuery({
    name: ['avatar_structure', user],
    update_param: user && user.crystalls,
    path: `/getavatarstructure/${user.gender}/`,
    skip: !user || !user.gender
  });

  const saveUserAvatar = (values_string) => {
    if (!values_string) {
      dispatch(setItem(['notifications', 'generateAvatar'], { type: 'error', content: 'Выберите компоненты' }));
      return null;
    }

    api
      .get(`/generateAvatar/${values_string}/${user.uid}/`)
      .then(response => {
        if (response.data.url) {
          dispatch(setItem(['user', 'avatar'], response.data.url));
          dispatch(setItem(['notifications', 'generateAvatar'], { type: 'success', content: 'Аватар обновлен' }));
          navigate('/update');
        } else {
          const error = response.data.error ? response.data.error : 'Неизвестная ошибка';
          dispatch(setItem(['notifications', 'generateAvatar'], { type: 'error', title: 'Ошибка', content: error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };


  const handleClickBuy = (item) => {
    if (item.price > user.crystalls) {
      setPayStatus('errorPrice');
      return null;
    }

    const fd = new FormData();
    fd.append('component_uid', item.uid);
    api
      .post('/payavatarcomponent/', fd)
      .then(response => {
        const result = response.data.response;
        if (result.success) {
          setPayStatus('success');
          dispatch(setItem(['user', 'crystalls'], result.crystalls));
        } else {
          setPayStatus(response.data.error);
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'pay_avatar_item'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  return (
    <MainLayout title="Мой аватар">
      {(user && constructor) ? (
        <AvatarConstructor
          user={user}
          constructor={constructor}
          onClickSave={saveUserAvatar}
          onClickBuy={handleClickBuy}
          payStatus={payStatus}
        />
      ) : (
        <ConstructorPlaceholder />
      )}
    </MainLayout>
  );
};

export default CreateAvatar;
