import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RegisterLayout } from 'components/Layouts';
import Block from 'components/Block';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import useApiQuery from 'hooks/useApiQuery';
import * as api from 'helpers/api';
import { selectItem } from 'store/app/getters';
import { setItem } from 'store/app/actions';
import RewardsUpdate from 'components/RewardsUpdate';

import styles from './Rewards.module.css';

const GuideRewards = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player;
  const playerUid = currentPlayer?.uid_gamer;

  const { data: rewards } = useApiQuery({
    name: ['rewards', playerUid],
    path: `/getMentorRewards/${playerUid}/`
  });

  useEffect(() => {
    if (user && !currentPlayer) navigate('/add-player');
    // eslint-disable-next-line
  }, [currentPlayer]);

  const handleChangeReward = (uid, name, checked, reward_uid) => {
    const fd = new FormData();

    fd.append('update[reward_uid]', uid);
    fd.append('update[reward_name]', name);
    fd.append('update[mentor_uid]', user.uid);
    fd.append('update[player_uid]', playerUid);

    if (!checked) fd.append('update[delete_reward]', reward_uid);

    api
      .post(`/updateMentorRewards/`, fd)
      .then(response => {
        const result = response.data.response;

        if (result) {
          dispatch(setItem(['rewards', playerUid], result, { isHard: true }));
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'success', content: 'Сохранено' }));
        }
        if (response.data.error) {
          dispatch(setItem(['notifications', 'updateProfile'], { type: 'error', content: response.data.error }));
        }
      })
      .catch(error => {
        dispatch(setItem(['notifications', 'request'], { type: 'error', content: 'Ошибка обработки запроса. Перезагрузите страницу и попробуйте позже' }));
      });
  };

  // const handleClickFinish = () => {
  //   dispatch(setItem(['onboarding'], [
  //     // {
  //     //   key: 'home_1',
  //     //   selector: '#about',
  //     //   image: <img style={{ position: 'absolute', top: '100%', left: '10px' }} src="/images/onboarding/mentor_1.svg?v=1" alt="" />
  //     // },
  //     {
  //       key: 'home_2',
  //       selector: '#first_player',
  //       image: <img  style={{ position: 'absolute', top: '10px', left: '-100px' }} className={styles.reward_info} src="/images/onboarding/mentor_2.svg" alt="" />,
  //       link: '/player',
  //       onClick: () => {
  //         dispatch(setItem(['onboarding'], [
  //           {
  //             key: 'home_3',
  //             selector: '#player_descr',
  //           },
  //           {
  //             key: 'home_4',
  //             selector: '#player_balance',
  //             image: <img style={{ position: 'absolute', top: '-178px', left: '0' }} src="/images/onboarding/mentor_3.svg" alt="" />,
  //           },
  //           // {
  //           //   key: 'home_5',
  //           //   selector: '#active_quests',
  //           //   image: <img style={{ position: 'absolute', top: '-166px', right: '0' }} src="/images/onboarding/mentor_4.svg" alt="" />,
  //           // },
  //           {
  //             key: 'home_6',
  //             selector: '#sign_as_player',
  //             image: <img style={{ position: 'fixed', top: '10px', left: '0' }} src="/images/onboarding/mentor_5.svg" alt="" />,
  //           }
  //         ]));
  //       }
  //     }
  //   ]));
  // };

  // const checkedRewards = rewards && Object.values(rewards).map(dur => {
  //   return Object.values(dur).reduce((prev, current) => {
  //     return current.rewards ? [ ...prev, ...Object.values(current.rewards) ] : prev;
  //   }, []);
  // });

  // const isSubmitActive = checkedRewards ? checkedRewards.filter(item => item.length >= 4).length === 4 : false;

  return (
    <RegisterLayout>
      <Block>
        <div className={styles.description}>
          Выберите свои награды для ребенка. <br />
          Награды — это ваша совместная деятельность с ребенком. Выберите <span className="error-bg">минимум 4 вида</span> наград для каждого временного периода

          <br />
          <br />

          {currentPlayer && (
            <>
              Награды для {currentPlayer.info ? currentPlayer.info.name : currentPlayer.name}:
            </>
          )}
        </div>
      </Block>
      <Block>
        <RewardsUpdate items={rewards} onChange={handleChangeReward} />
      </Block>
      <ButtonsContainer>
        <Button butStyle="primary" size="small" to="/">Вперед в Skillary</Button>
      </ButtonsContainer>
    </RegisterLayout>
  );
};

export default GuideRewards;
