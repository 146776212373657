import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { setItem } from 'store/app/actions';

import styles from './header.module.css';
import Button from 'components/Button';
import { ReactSVG } from 'react-svg';

const AddButton = ({ active, onClick }) => {
  return (
    <Link to="/add-player" className={classNames(styles.profile, active && styles.active)} onClick={onClick}>
      <div className={styles.profileImageContainer}>
        <ReactSVG src="/icons/icon-plus.svg" className={styles.iconAdd} alt="" />
      </div>
      <div className={styles.profileName}>Добавить</div>
    </Link>
  );
};

const Avatar = ({ user, href, onClick, active, index }) => {
  const name = user.info ? user.info.name : user.name;
  const gender = user.info ? user.info.gender : user.gender;
  let avatar = user.info ? user.info.avatar : user.avatar;
  if (!avatar) avatar = `/icons/icon-${(user.type && user.type === 'M') ? 'mentor' : 'child'}-${gender ? gender.toLowerCase() : 'm'}.svg`;

  return (
    <Link to={href} className={classNames(styles.profile, active && styles.active)} onClick={onClick}>
      <div className={styles.profileImageContainer} id={index === 0 ? 'first_player' : null}>
        <img src={avatar} className={styles.profileImage} alt="" />
      </div>
      <div className={styles.profileName}>{name}</div>
    </Link>
  );
};

const HeaderMentor = ({ user, pin_code }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const isAdd = pathname.startsWith('/add-player');
  const containerRef = useRef(null);
  const playerRef = useRef(null);

  const handleClickUser = (uid_gamer) => {
    const player = user.player.find((player) => player.uid_gamer === uid_gamer);
    dispatch(setItem(['user', 'current_player'], player, { isHard: true }));
  };

  // useEffect(() => {
  //   if (containerRef.current && playerRef.current) {
  //     const left = playerRef.current.offsetLeft - containerRef.current.offsetLeft;
  //     containerRef.current.scrollTo(left - 46 - 20, 0);
  //   }
  // }, [containerRef, playerRef]);

  return (
    <div className={styles.root}>
      <div className={classNames(styles.container, 'container')}>
        <div className={styles.top}>
          <Button
            butStyle="text"
            icon={<ReactSVG src="/icons/user.svg" wrapper="span" />}
            className={styles.userName}
          >
            {user.name}
          </Button>
          <Button
            butStyle="text"
            icon={<ReactSVG src="/icons/icon-settings.svg" wrapper="span" />}
            to="/update"
          />
        </div>
        <div className={classNames(styles.users, 'scroll-list')} ref={containerRef}>
          {/* <div className={styles.usersItem}>
            <Avatar
              user={user}
              active={isMentorHome}
              href="/"
            />
          </div> */}
          {(user.player && user.player.length) ? (
            user.player.map((player, index) => {
              const isActive = !isAdd && user.current_player && user.current_player.uid_gamer === player.uid_gamer;
              return (
                <div className={styles.usersItem} key={player.uid_gamer} ref={isActive ? playerRef : null}>
                  <Avatar
                    user={player}
                    active={isActive}
                    href="/"
                    onClick={() => handleClickUser(player.uid_gamer)}
                    index={index}
                  />
                </div>
              );
            })
          ) : null}
          <AddButton active={isAdd} />
        </div>
      </div>
    </div>
  );
};

export default HeaderMentor;
