import classnames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './quest-item.module.css';

const QuestUser = ({ quest }) => {
  const { uid, info, is_cancel_reason, is_cancel_reason_desc, name } = quest;

  if (!uid) return (
    <div className={styles.quest}>
      <div className={classnames(styles.container, styles.cancel, 'skeleton-loader')} />
    </div>
  );

  return (
    <Link to={`/user-quest/${uid}`} className={styles.block}>
      <div className={classnames(styles.container, styles.cancel)}>
        <div className={styles.titleContainer}>
          <div className={styles.error}>Квест отменён</div>
          <div className={styles.title}>{info ? info.name : name}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Причина отмены:</div>
          <div className={styles.value}>{is_cancel_reason ? is_cancel_reason_desc : '-'}</div>
        </div>
        <div className={styles.row}>
          <div className={styles.label}>Награда:</div>
          <div className={styles.value}>Не получена</div>
        </div>
      </div>
    </Link>
  );
};

export default QuestUser;