import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import ruPlural from '../../../helpers/ruPlural';
import getDuration from '../../../helpers/getDuration';

import styles from './quest-item.module.css';

const QuestItemUser = ({ quest, showSign }) => {
  const { uid, info, name, reward, lifetime_end, is_complete, is_cancel, end } = quest;
  const durationText = lifetime_end && getDuration(lifetime_end);

  return (
    <Link
      to={`/user-quest/${uid}`}
      className={styles.block}
    >
      <div className={classnames(styles.container, (showSign && quest.complete_sign) && styles.sign)}>
        <div className={classnames(styles.row, styles.rowTitle)}>
          <div className={classnames(styles.label, styles.labelTitle)}>{info ? info.name : name}</div>
          <div className={styles.value}>
            <ReactSVG src='/icons/icon-info.svg' className={styles.info} />
          </div>
        </div>
        {(is_complete || is_cancel) ? (
          <>
            <div className={styles.row}>
              <div className={styles.label}>Дата прохождения:</div>
              <div className={styles.value}>{end && format(new Date(end.replace(' ', 'T')), 'd MMMM yyyy', {locale: ru})}г</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Полученная награда:</div>
              <div className={styles.value}>{reward} {ruPlural(reward, ['балл', 'балла', 'баллов'])}</div>
            </div>
          </>
        ) : (
          <>
            <div className={styles.row}>
              <div className={styles.label}>Осталось времени:</div>
              <div className={styles.value} dangerouslySetInnerHTML={{__html: durationText}} />
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Награда:</div>
              <div className={styles.value}>{reward} {ruPlural(reward, ['балл', 'балла', 'баллов'])}</div>
            </div>
          </>
        )}
      </div>
    </Link>
  );
};

export default QuestItemUser;