import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller } from 'react-hook-form';
import InputsContainer from '../fields/InputsContainer';
import ButtonsContainer from '../ButtonsContainer';
import getValidationSchema from '../../helpers/getValidationSchema';

import styles from './form.module.css';

const Form = ({ inputs, onSubmit, onChange, onValidChange, buttons, inputClass }) => {
  const validationSchema = getValidationSchema(inputs);

  const defaultValues = inputs.reduce((prev, { name, props }) => {
    if (!props.value) return { ...prev };
    return { ...prev, [name]: props.value };
  }, {});

  const { register, control, handleSubmit, watch, formState: { isValid, errors } } = useForm({
    defaultValues,
    mode: onValidChange ? 'onChange' : 'onSubmit',
    resolver: yupResolver(validationSchema)
  });

  useEffect(() => {
    if (onValidChange) onValidChange(isValid);
    //eslint-disable-next-line
  }, [ isValid ]);

  useEffect(() => {
    if (!onChange) return;
    const subscription = watch((values) => onChange(values));
    return () => subscription.unsubscribe();
    //eslint-disable-next-line
  }, [ watch ]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputsContainer className={styles.fields}>
        {inputs.map(({ id, name, validation, props, component: Component }, index) => (
          <Controller
            name={name}
            control={control}
            key={name + index}
            render={({ field: { value = '', ...rest } }) => (
              <Component
                id={id}
                {...props}
                {...register}
                {...rest}
                value={value}
                error={(validation && !onValidChange) && errors[name]?.message}
                inputClass={inputClass}
              />
            )}
          />
        ))}
      </InputsContainer>
      <ButtonsContainer className={styles.buttons}>
        {buttons}
      </ButtonsContainer>
    </form>
  );
}

export default Form;
