import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';
import Modal from 'components/Modal';

import styles from './login-modal.module.css';

const LoginModal = ({ player, open, onClose, onClickLogin, onClickCopy, user_agent }) => {
  const name = player.info ? player.info.name : player.name;
  const gender = player.info ? player.info.gender : player.gender;
  const self_key = player.info ? player.info.self_key : player.self_key;
  let avatar = player.info ? player.info.avatar : player.avatar;
  if (!avatar) avatar = `/icons/icon-${(player.type && player.type === 'M') ? 'mentor' : 'child'}-${gender ? gender.toLowerCase() : 'm'}.svg`;

  return (
    <Modal
      viewStyle="bottom"
      open={open}
      title="Войти в аккаунт"
      onClose={onClose}
    >
      <div className={styles.login}>
        <img src={avatar} className={styles.loginImage} alt="" />
        <div className={styles.loginName}>Войти на телефоне {name}</div>

        <ButtonsContainer>
          <Button
            className={styles.loginButton}
            butStyle="secondary"
            to={`/invite-player/${player.uid_gamer}`}
          >
            <span>Показать <span className="nowrap">QR-код</span></span>
          </Button>
          <Button
            className={styles.loginButton}
            butStyle="primary"
            onClick={() => onClickCopy(self_key)}
            href={user_agent === 'skillary-mobile' ? '#share-link' : undefined}
          >
            Отправить ссылку
          </Button>
          <Button
            className={styles.loginButtonMain}
            butStyle="secondary"
            fullWidth={true}
            onClick={() => onClickLogin(player.uid_gamer)}
          >
            Войти на этом телефоне
          </Button>
        </ButtonsContainer>
      </div>
    </Modal>
  );
};

export default LoginModal;