import { useState } from 'react';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Gallery from 'components/Gallery';
import HomeworkComments from './HomeworkComments';

import styles from './homework.module.css';

const MentorButtons = ({ current, onSendComment, onClickReject, onClickSubmit, bonus, onChangeFile }) => {
  const [ commentText, setCommentText ] = useState('');
  const [ isCommentOpen, setCommentOpen ] = useState(false);

  if (!current) return null;

  const handleClickReject = (uid) => {
    setCommentOpen(true);
    onClickReject(uid);
  };

  const handleClickSubmit = (uid, withBonus) => {
    setCommentOpen(true);
    onClickSubmit(uid, withBonus);
  };

  return (
    <>
      <HomeworkComments {...current} user_type="M" />

      {(current.is_rejected && current.comment) &&
        <div className={styles.comment}>{current.comment}</div>
      }

      {(current.files && current.files.length) ? (
        <div className={styles.gallery}>
          <Gallery
            items={current.files.map(({ file_url }) => ({ link: file_url }))}
            changeItems={true}
            onChangeFile={(file, old_file) => onChangeFile(file, current.uid, current.type, old_file)}
          />
        </div>
      ) : null}

      {!current.is_done &&
        <ButtonsContainer className={styles.buttons}>
          {(!current.is_done && !(current.on_work && current.on_work.worked_time && !current.oper_complete)) && (
            <>
              {!current.is_rejected && (
                <Button
                  butStyle="secondary"
                  fullWidth={true}
                  onClick={() => handleClickReject(current.uid)}
                  disabled={!(current.files && current.files.length)}
                >
                  Переделать
                </Button>
              )}
              <Button
                butStyle="primary"
                onClick={() => handleClickSubmit(current.uid)}
                disabled={!(current.files && current.files.length)}
              >
                Выполнено
              </Button>
              <Button
                butStyle="primary"
                onClick={() => handleClickSubmit(current.uid, true)}
                disabled={!(current.files && current.files.length)}
                className={styles.accurButton}
              >
                Выполнено аккуратно <span className="nowrap">(+{bonus} кристаллов)</span>
              </Button>
            </>
          )}
        </ButtonsContainer>
      }
      <Modal
        title="Оставьте комменарий"
        open={isCommentOpen}
        viewStyle="bottom"
        closeOnDocumentClick={false}
        onClose={() => setCommentOpen(false)}
        buttons={[{
          text: 'Отправить комментарий',
          action: 'close',
          onClick: () => onSendComment(current.uid, commentText),
          disabled: !commentText,
          style: 'primary'
        }]}
      >
        <textarea className={styles.textarea} placeholder="Введите текст" onChange={e => setCommentText(e.target.value)} />
      </Modal>
    </>
  );
};

export default MentorButtons;
