import { useState } from 'react';
import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import Switch from 'components/fields/Switch';
import PlayersList from 'components/PlayersList';
import Button from 'components/Button';
import ButtonsContainer from 'components/ButtonsContainer';
import ButtonsGrid from 'components/ButtonsGrid';
import InfoBlock from 'components/InfoBlock';
import useApiQuery from 'hooks/useApiQuery';
import { selectItem } from 'store/app/getters';

import styles from './mentor.module.css';

const Rating = () => {
  const user = useSelector(selectItem(['user']));
  const currentPlayer = user.current_player ? user.current_player : null;
  const [ type, setType ] = useState('class');

  const tabs = [
    {
      label: 'В классе',
      value: 'class'
    },
    {
      label: 'В школе',
      value: 'school'
    }
  ];

  const school = currentPlayer ? (currentPlayer.info ? currentPlayer.info.school : currentPlayer.school) : null;
  const class_s = currentPlayer ? (currentPlayer.info ? currentPlayer.info.class : currentPlayer.class) : null;
  const class_sign = currentPlayer ? (currentPlayer.info ? currentPlayer.info.class_sign : currentPlayer.class_sign) : null;

  const { data: rating } = useApiQuery({
    name: ['rating'],
    path: `/ratingschoolclass/${currentPlayer?.uid}/${school}/${class_s}/${class_sign}/`,
    skip: !user || !currentPlayer || !school,
    mapper: (data = {}, prev) => {
      let next = {
        class: {},
        school: {}
      };
      const { rating } = data;

      if (rating.class.length) {
        for (let key in rating.class) {
          const item = rating.class[key];
          next.class[item.rank_class] = item;
        }
      }

      if (rating.school.length) {
        for (let key in rating.school) {
          const item = rating.school[key];
          next.school[item.rank_school] = item;
        }
      }

      return next;
    }
  });

  if (!user) return null;

  const onChangeTab = (e) => {
    setType(e.target.value);
  };

  return (
    <MainLayout title="Рейтинг" infoSlug="raiting">
      {currentPlayer ? (
        <>
          <Switch
            id="school"
            name="school"
            options={tabs}
            value={type}
            view="radio"
            onChange={(e) => onChangeTab(e)}
            inputClass="dark"
          />
          {school && class_s && class_sign ? (
            rating && <PlayersList players={rating[type]} current={currentPlayer ? currentPlayer.uid_gamer : null} type={type} />
          ) : (
            <div style={{ marginTop: '25px' }}>
              <InfoBlock color="orange">
                <div className={styles.ratingNoSchool}>
                  В сервисе “настройки” <br /> выберите школу и класс
                  <ButtonsGrid buttons={[{ name: 'Настройки', link: '/update', icon: '/images/services/icon-settings.png' }]} />
                </div>
              </InfoBlock>
            </div>
          )}
        </>
      ) : (
        // <>
        //   {(user.player && user.player.length) ? 'Выберите игрока для просмотра рейтинга' : 'Для просмотра рейтинга добавьте игрока'}
        //   <div style={{ marginTop: '24px' }} />
        //   <PlayersSelect users={user.player} />
        // </>
        <>
          <div className="text-center" style={{ marginTop: '60px' }}>Добавьте минимум 1 ребенка для просмотра рейтинга</div>
          <ButtonsContainer>
            <Button
              to="/add-player"
              fullWidth={true}
              butStyle="primary"
              state={{ from: '/create' }}
              size="medium"
            >
              {/* <ReactSVG src="/icons/icon-plus.svg" className={styles.icon} /> */}
              Добавить ребенка
            </Button>
          </ButtonsContainer>
        </>
      )}
    </MainLayout>
  );
};

export default Rating;
