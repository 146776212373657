import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useApiQuery from 'hooks/useApiQuery';
import { MainLayout } from 'components/Layouts';
import { selectItem } from 'store/app/getters';
import { deleteItem } from 'store/app/actions';
import BonusesList from 'components/BonusesList';
import Form from 'components/Form';
import DateInput from 'components/fields/DateInput';

import styles from './player.module.css';

const DateSelectors = ({ dateStart = '', dateEnd = '', onChange }) => {
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 1);

  const maxDate = new Date();

  const inputs = [
    { id: 'date_start', name: 'date_start', component: DateInput,
      props: { type: 'date', value: dateStart, placeholder: 'с',
        fullWidth: true, minDate: minDate, maxDate: maxDate
      }
    },
    { id: 'date_end', name: 'date_end', component: DateInput,
      props: { type: 'date', value: dateEnd, placeholder: 'по',
        fullWidth: true, minDate: minDate, maxDate: maxDate
      }
    },
  ];

  return (
    <div className={styles.bonusesDates}>
      <Form inputs={inputs} onChange={onChange} inputClass="dark" />
    </div>
  );
};

const buildPath = (uid, type, page, date_start, date_end) => {
  let path = `/getUserBonus/${uid}/${type}/${page}/`;

  if (date_end) {
    if (date_start) path = `${path}${date_start}/${date_end}/`;
    else path = `${path}/${date_end}/`;
  } else {
    path = `${path}${date_start}/`;
  }

  return path;
};

const Bonuses = () => {
  const dispatch = useDispatch();
  const { hash } = useLocation();
  const type = hash ? hash.substr(1, hash.length) : 'A';
  const user = useSelector(selectItem(['user']));
  const [ dates, setDates ] = useState({ date_start: '', date_end: '' });
  const bonuses = useSelector(selectItem(['bonuses', user.uid, type, dates.date_start, dates.date_end], []));
  const [ isLast, setIsLast ] = useState(false);
  const [ page, setPage ] = useState(1);

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop } = document.documentElement;
    if ((scrollHeight - 100) < (scrollTop + window.innerHeight)) {
      if (!isLast) setPage(page+1);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
      dispatch(deleteItem(['bonuses'], user.uid));
    };
    // eslint-disable-next-line
  }, []);

  useApiQuery({
    name: ['bonuses', user.uid, type, dates.date_start, dates.date_end],
    path: buildPath(user.uid, type, page, dates.date_start, dates.date_end),
    skip: !user.uid,
    mapper: (data = {}, prev = {}) => {
      if (data.isLast) setIsLast(true);
      else setIsLast(false);

      const item = data.units.reduce((prev, current) => ({ ...prev, [current.uid]: current }), {});

      return { ...prev, ...item };
    }
  });

  useEffect(() => {
    setPage(1);
  }, [type, dates]);

  const onFormChange = (values) => {
    if (values) setDates(values);
  };

  if (!user) return null;

  return (
    <MainLayout title="Мои начисления">
      <DateSelectors onChange={onFormChange} dateStart={dates.date_start} dateEnd={dates.date_end} />
      <BonusesList items={Object.values(bonuses).reverse()} />
    </MainLayout>
  );
};

export default Bonuses;
