import Button from '../Button';
import InfoBlock from '../InfoBlock';
import styles from './referal-list.module.css';

const Item = ({ item, status, onResendInvite }) => {
  const { uid, avatar, name, surname, nickname, lvl, timestamp, message } = item;

  return (
    <div className={styles.item}>
      <div className={styles.image}>
        <img src={avatar} alt="" />
      </div>
      <div className={styles.content}>
        <div className={styles.name}>{name} {surname}</div>
        {(nickname || lvl) && <div className={styles.level}>{(nickname && nickname !== 'undefined') ? `@${nickname} ` : null} {lvl && `Уровень ${lvl}`}</div>}
        {timestamp && <div className={styles.date}>Присоединился {timestamp}</div>}
        {message && <div className={styles.message}>{message}</div>}
        {status === 'active' &&
          <div className={styles.buttons}>
            <Button butStyle="primary" onClick={() => onResendInvite(uid)}>Напомнить</Button>
          </div>
        }
      </div>
    </div>
  );
};

const ReferalList = ({ active, complete, sended, onResendInvite }) => {
  return (
    <div className={styles.list}>
      {active.length ? (
        active.map((item, index) => (
          <Item item={item} key={'a' + item.uid + index} status={(sended && sended[item.uid]) ? 'sended' : 'active'} onResendInvite={onResendInvite} />
        ))
      ) : null}
      {complete.length ? (
        complete.map((item, index) => (
          <Item item={item} key={'c' + item.uid + index} status="complete" onResendInvite={onResendInvite} />
        ))
      ) : null}
      {(!active.length && !complete.length) &&
        <InfoBlock color="orange">Нет приглашенных игроков</InfoBlock>
      }
    </div>
  );
};

export default ReferalList;