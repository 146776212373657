import Popup from 'reactjs-popup';

import './tooltip.css';

const Tooltip = ({ control: Control, children }) => {
  if (!Control) return null;

  return (
    <Popup
      className={'tooltip'}
      trigger={<Control />}
      position='bottom center'
      closeOnDocumentClick
      keepTooltipInside={true}
    >
      <span>{children}</span>
    </Popup>
  );
};

export default Tooltip;
