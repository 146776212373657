import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Rating from '../../Rating';
import ruPlural from '../../../helpers/ruPlural';
import getDuration from '../../../helpers/getDuration';

import { categories_bgs } from '../../../data/mock';

import styles from './quest-item.module.css';

const QuestItemEnded = ({ quest, lock }) => {
  const { quest_uid, active_quest_uid, name, quest_rating, start, end, reward_std } = quest;
  let { reward } = quest;
  const cid = quest_uid + '';
  const bg = categories_bgs[cid.slice(-1)];

  if (!reward) reward = reward_std;

  if (!quest_uid) return (
    <div className={classnames(styles.quest, 'skeleton-loader')} />
  );

  const duration = (start && end) && new Date(end.replace(' ', 'T')).getTime() / 1000 - new Date(start.replace(' ', 'T')).getTime() / 1000;
  const durationText = duration ? getDuration(duration) : '0 минут';

  const link = active_quest_uid ? `/user-quest/${active_quest_uid}` : `/quest/${quest_uid}`;

  return (
    <Link to={link} className={styles.quest} style={{ backgroundImage: `url(${bg}` }}>
      <div className={classnames(styles.title, styles.ended)} dangerouslySetInnerHTML={{ __html: name }} />
      {lock && <div className={styles.overlay} />}
      <div className={styles.endWrapper}>
        <div className={styles.rating}>
          <Rating defaultValue={quest_rating} disable={true} />
        </div>
        <div className={styles.bottom}>
          <div className={styles.count} dangerouslySetInnerHTML={{ __html: `Пройдено за ${durationText}` }} />
          <div className={styles.count}>Награда {reward ? reward : 0} {ruPlural(reward, ['Балл', 'Балла', 'Баллов'])}</div>
        </div>
      </div>
    </Link>
  );
};

export default QuestItemEnded;
