import { ReactSVG } from 'react-svg';
import Button from '../../Button';

import styles from './pin-pad.module.css';

const numbers = [1,2,3,4,5,6,7,8,9,null,0,'D'];

const PinPad = ({ onClickButton, onClickRemove }) => {
  return (
    <div className={styles.buttons}>
      {numbers.map(number => {
        if (number === null) return <span key={number} />;
        if (number === 'D') return (
          <Button
            butStyle="secondary"
            className={styles.button}
            onClick={onClickRemove}
            key={number}
          >
            <ReactSVG src="/icons/icon-remove-symbol.svg" wrapper="span" className={styles.icon} />
          </Button>
        );
        return (
          <Button
            butStyle="secondary"
            className={styles.button}
            onClick={() => onClickButton(number)}
            key={number}
          >
            {number}
          </Button>
        );
      })}
    </div>
  );
};

export default PinPad;
