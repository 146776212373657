export const user_quest_types = {
  active: { id: 'active', name: 'Активные квесты'},
  complete: { id: 'complete', name: 'Пройденные квесты'},
  cancel: { id: 'cancel', name: 'Отменённые'}
};

export const bonuses_types = {
  all: { id: 'A', name: 'Все операции'},
  in: { id: 'P', name: 'Начисления'},
  out: { id: 'M', name: 'Списания'}
};

export const tariffs = [
  { value: 0, label: 'Без ограничений' }
];

export const types = [
  { value: 'M', label: 'Я наставник<br />(родитель)' },
  { value: 'P', label: 'Я игрок<br />(ребенок)' }
];

export const grades = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' }
];

export const letters = [
  { value: 'А', label: 'А' },
  { value: 'Б', label: 'Б' },
  { value: 'В', label: 'В' },
  { value: 'Г', label: 'Г' },
  { value: 'Д', label: 'Д' },
  { value: 'Е', label: 'Е' },
  { value: 'Ё', label: 'Ё' },
  { value: 'Ж', label: 'Ж' },
  { value: 'З', label: 'З' },
  { value: 'И', label: 'И' },
  { value: 'К', label: 'К' },
  { value: 'Л', label: 'Л' },
  { value: 'М', label: 'М' },
  { value: 'Н', label: 'Н' },
  { value: 'О', label: 'О' },
  { value: 'П', label: 'П' },
  { value: 'Р', label: 'Р' },
  { value: 'С', label: 'С' },
  { value: 'Т', label: 'Т' },
  { value: 'У', label: 'У' },
  { value: 'Ф', label: 'Ф' },
  { value: 'Х', label: 'Х' },
  { value: 'Ц', label: 'Ц' },
  { value: 'Ч', label: 'Ч' },
  { value: 'Ш', label: 'Ш' },
  { value: 'Щ', label: 'Щ' },
  { value: 'Ы', label: 'Ы' },
  { value: 'Э', label: 'Э' },
  { value: 'Ю', label: 'Ю' },
  { value: 'Я', label: 'Я' }
];

export const genders = [
  { value: 'M', label: 'М' },
  { value: 'W', label: 'Ж' }
];

export const playersTabs = [
  { label: 'Наша школа', value: 'current' },
  { label: 'Общий рейтинг', value: 'total' }
];

export const ambitionsTabs = [
  { label: 'Активные цели', value: 'active' },
  { label: 'Достигнутые цели', value: 'complete' }
];

export const uploadTabs = [
  { label: 'Загрузить фото', value: 'image' },
  { label: 'Загрузить видео', value: 'video' }
];

export const categories_colors_home = [
  [ '#A7E7D7', '#D273FF' ],
  [ '#D273FF', '#FF9317' ],
  [ '#FFBEAF', '#25D19F' ],
  // [ '#FFBEAF', '#25D19F' ],
  // [ '#EB5B53', '#1A91FF' ],
  // [ '#F6CA45', '#DDF645' ],
  [ '#A7E7D7', '#D273FF' ],
  [ '#D273FF', '#FF9317' ],
  [ '#FFBEAF', '#25D19F' ],
  [ '#A7E7D7', '#D273FF' ],
  [ '#D273FF', '#FF9317' ],
  [ '#FFBEAF', '#25D19F' ],
  [ '#A7E7D7', '#D273FF' ],
];

export const categories_colors = [
  '#9AA3FA', '#BFE470', '#FF8F6B', '#84CBFF', '#FFA858', '#9AA3FA', '#BFE470', '#FF8F6B', '#84CBFF', '#FFA858'
];

export const skillColors = [
  '#796BFF', '#26C000', '#FF42BF', '#FF7426', '#00D8D8', '#82006D', '#796BFF', '#26C000', '#FF42BF', '#FF7426', '#00D8D8', '#82006D'
];

export const categories_bgs = [
  '/images/quests_bg/pink.svg', '/images/quests_bg/green.svg', '/images/quests_bg/orange.svg', '/images/quests_bg/blue.svg', '/images/quests_bg/yellow.svg',
  '/images/quests_bg/pink.svg', '/images/quests_bg/green.svg', '/images/quests_bg/orange.svg', '/images/quests_bg/blue.svg', '/images/quests_bg/yellow.svg'
];

export const categories_bgs_small = [
  '/images/quests_bg_small/1.svg', '/images/quests_bg_small/2.svg', '/images/quests_bg_small/3.svg', '/images/quests_bg_small/4.svg',
  '/images/quests_bg_small/5.svg', '/images/quests_bg_small/6.svg', '/images/quests_bg_small/1.svg', '/images/quests_bg_small/2.svg',
  '/images/quests_bg_small/3.svg', '/images/quests_bg_small/4.svg'
];

export const courses_bgs = [
  '/images/courses_bg/1.svg', '/images/courses_bg/2.svg', '/images/courses_bg/3.svg', '/images/courses_bg/4.svg', '/images/courses_bg/5.svg',
  '/images/courses_bg/6.svg', '/images/courses_bg/1.svg', '/images/courses_bg/2.svg', '/images/courses_bg/3.svg', '/images/courses_bg/4.svg'
];
export const homeworks_bgs = [
  '/images/homework_bg/1.svg', '/images/homework_bg/2.svg', '/images/homework_bg/3.svg', '/images/homework_bg/4.svg', '/images/homework_bg/5.svg',
  '/images/homework_bg/6.svg', '/images/homework_bg/1.svg', '/images/homework_bg/2.svg', '/images/homework_bg/3.svg', '/images/homework_bg/4.svg'
];

export const mentor_services = [
  {
    id: 's_guide',
    name: 'Видео гайд',
    link: '/video-guide',
    icon: '/images/services/icon-play.png'
  },
  {
    id: 's_profile',
    name: 'Профиль',
    link: '/update-player',
    icon: '/images/services/icon-avatar-empty.png'
  },
  {
    id: 's_backpack',
    name: 'Рюкзак',
    link: '/backpack',
    icon: '/images/services/icon-backpack.svg'
  },
  {
    id: 's_friends',
    name: 'Друзья',
    link: '/friends',
    icon: '/images/services/icon-friends.png',
    disabled: true
  },
  {
    id: 's_rating',
    name: 'Рейтинг',
    link: '/rating',
    icon: '/images/services/icon-rating.png'
  },
  {
    id: 's_statistic',
    name: 'Навыки',
    link: '/statistic',
    icon: '/images/services/icon-target.png'
  },
  {
    id: 's_store',
    name: 'Магазин',
    link: '/store',
    icon: '/images/services/icon-stores.png',
  },
  {
    id: 's_vict',
    name: 'Викторины',
    link: '/vict',
    icon: '/images/services/icon-help.png',
    disabled: true
  },
  {
    id: 's_update',
    name: 'Настройки',
    link: '/update',
    icon: '/images/services/icon-settings.png'
  }
];

export const services = [
  {
    id: 's_guide',
    name: 'Видео гайд',
    link: '/video-guide',
    icon: '/images/services/icon-play.png'
  },
  {
    id: 's_profile',
    name: 'Профиль',
    link: '/update',
    icon: '/images/services/icon-avatar-empty.png'
  },
  {
    id: 's_backpack',
    name: 'Рюкзак',
    link: '/backpack',
    icon: '/images/services/icon-backpack.svg'
  },
  {
    id: 's_friends',
    name: 'Друзья',
    link: '/friends',
    icon: '/images/services/icon-friends.png',
    disabled: true
  },
  {
    id: 's_rating',
    name: 'Рейтинг',
    link: '/rating',
    icon: '/images/services/icon-rating.png'
  },
  {
    id: 's_statistic',
    name: 'Навыки',
    link: '/statistic',
    icon: '/images/services/icon-target.png'
  },
  {
    id: 's_store',
    name: 'Магазин',
    link: '/store',
    icon: '/images/services/icon-stores.png',
  },
  {
    id: 's_vict',
    name: 'Викторины',
    link: '/vict',
    icon: '/images/services/icon-help.png',
    disabled: true
  },
  {
    id: 's_update',
    name: 'Настройки',
    link: '/update',
    icon: '/images/services/icon-settings.png'
  }
];

export const faq_items = [
  {
    id: 'guide',
    name: 'Комикс',
    href: '/comics',
    image3: '/images/about/bg_comics.svg?v=1',
    image_child: '/images/about/bg_comics_m.svg?v=1',
    image_mentor: '/images/about/bg_comics_m.svg?v=1',
    show_mentor: true,
    show_player: true
  },
  {
    id: 'quests',
    name: 'Квесты',
    href: '/about-video/kvesti',
    image: '/images/about/apps.svg',
    image3: '/images/about/bg_quests.svg',
    image_child: '/images/about/bg_quests_p.svg',
    image_mentor: '/images/about/bg_quests_m.svg',
    color: '#FF803B',
    show_mentor: true,
    show_player: true
  },
  {
    id: 'motivation',
    name: 'Мотивация',
    href: '/about-video/motivation',
    image: '/images/about/point.svg',
    image3: '/images/about/bg_mot.svg',
    image_child: '/images/about/bg_mot_p.svg',
    image_mentor: '/images/about/bg_mot_m.svg',
    color: '#4CB4FF',
    show_mentor: true,
    show_player: true
  },
  {
    id: 'pay',
    name: 'Оплата',
    href: '/about-video/pay',
    image: '/images/about/ruble.svg',
    image3: '/images/about/bg_pay.svg',
    image_mentor: '/images/about/bg_pay_m.svg',
    color: '#FF88E5',
    show_mentor: true,
    show_player: false
  },
  // {
  //   name: 'Игровая механика',
  //   slug: 'about_game',
  //   image: '/images/about/settings.svg',
  //   color: '#7C67FF',
  //   image3: '/images/about/bg_game.svg',
  //   show_mentor: true,
  //   show_player: true
  // },
  {
    id: 'faq',
    name: 'Ответы на популярные вопросы',
    href: '/faq',
    image: '/images/about/question.svg',
    image3: '/images/about/bg_question.svg',
    image_mentor: '/images/about/bg_question.svg',
    color: '#82006D',
    show_mentor: true,
    show_player: false
  },
  {
    id: 'feedback',
    name: 'Обратная связь',
    slug: 'about_feedback',
    image: '/images/about/message.svg',
    image3: '/images/about/bg_message.svg',
    image_mentor: '/images/about/bg_message.svg',
    color: '#87CF53',
    show_mentor: true,
    show_player: false
  },
];

export const backpack_items = [
  {
    id: 'bp_rewards',
    name: 'Награды от взрослых',
    href: '/rewards',
    image: '/images/about/mentor_rewards.svg',
    color: '#2AA6FF'
  },
  {
    id: 'bp_gifts',
    name: 'Подарки от Skillary',
    href: '/gifts',
    image: '/images/about/skillary_gifts.svg',
    color: '#FF803B',
    disabled: true
  },
  {
    id: 'bp_artefacts',
    name: 'Артефакты',
    href: '/artefacts',
    image: '/images/about/chest.svg',
    color: '#78C93E',
  },
  // {
  //   id: 'bp_bonuses',
  //   name: 'Мои начисления',
  //   href: '/bonuses#a',
  //   // image: '/images/about/chest.svg',
  //   color: 'rgb(215, 201, 0)',
  // },
];

export const comics = [
  {
    src: '/images/guide/1g.png',
    audio: '/audio/guide/1.mp3',
    color: '#fff'
  },
  {
    src: '/images/guide/2g.png',
    audio: '/audio/guide/2.mp3',
    color: '#fff'
  },
  {
    src: '/images/guide/3g.png',
    audio: '/audio/guide/3.mp3',
    color: '#fff'
  },
  {
    src: '/images/guide/4g.png',
    audio: '/audio/guide/4.mp3',
    color: '#fff'
  },
  {
    src: '/images/guide/5g.png',
    audio: '/audio/guide/5.mp3',
    color: '#fff'
  },
  {
    src: '/images/guide/6g.png',
    audio: '/audio/guide/6.mp3',
    color: '#fff'
  },
  {
    src: '/images/guide/7g.png',
    color: '#302349'
  },
  {
    src: '/images/guide/8g.png',
    audio: '/audio/guide/7.mp3',
    color: '#302349'
  },
  {
    src: '/images/guide/9g.png',
    audio: '/audio/guide/8.mp3',
    color: '#302349'
  },
  {
    src: '/images/guide/10g.png',
    audio: '/audio/guide/9.mp3',
    color: '#302349'
  },
  {
    src: '/images/guide/11g.png',
    audio: '/audio/guide/10.mp3',
    color: '#302349'
  },
  {
    src: '/images/guide/12g.png',
    audio: '/audio/guide/11.mp3',
    color: '#302349'
  },
  {
    src: '/images/guide/13g.png',
    audio: '/audio/guide/12.mp3',
    color: '#302349'
  },
  {
    src: '/images/guide/14g.png',
    audio: '/audio/guide/13.mp3',
    color: '#fff'
  },
  {
    src: '/images/guide/15g.png',
    color: '#fff'
  },
  {
    src: '/images/guide/16g.png',
    color: '#fff'
  },
  // {
  //   src: '/images/guide/17g.png',
  //   color: '#fff'
  // },
  // {
  //   src: '/images/guide/18g.png',
  //   color: '#fff'
  // },
  // {
  //   src: '/images/guide/19g.png',
  //   color: '#fff'
  // }
];
