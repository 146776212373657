import { intervalToDuration } from 'date-fns';
import ruPlural from './ruPlural';

const getDuration = (seconds, maxValues = 2) => {
  let outDate = [];
  const timeEnd = intervalToDuration({ start: 0, end: seconds * 1000 });
  if (timeEnd.days && timeEnd.days > 0)
    outDate.push(`<span>${timeEnd.days}</span> ${ruPlural(timeEnd.days, ['день', 'дня', 'дней'])}`);

  if (timeEnd.hours && timeEnd.hours > 0)
    outDate.push(`<span>${timeEnd.hours}</span> ${ruPlural(timeEnd.hours, ['час', 'часа', 'часов'])}`);

  if (timeEnd.minutes && timeEnd.minutes > 0)
    outDate.push(`<span>${timeEnd.minutes}</span> ${ruPlural(timeEnd.minutes, ['минута', 'минуты', 'минут'])}`);

  if (maxValues < 3 && outDate.length > 2)
    outDate.pop();
  if (maxValues < 2 && outDate.length > 1)
    outDate.pop();

  if (!outDate.length && seconds > 0) outDate.push('<span>1</span> минуту')

  return outDate.join(' ');
};

export default getDuration;
