import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import HeadLevel from './HeadLevel';

import styles from './header.module.css';

const Avatar = ({ user, href }) => {
  let avatar = user.info ? user.info.avatar : user.avatar;
  if (!avatar) {
    avatar = `/icons/icon-${(user.type && user.type === 'M') ? 'mentor' : 'child'}-${user.gender ? user.gender.toLowerCase() : 'm'}.svg`;
  }

  return (
    <Link to={href} className={styles.profile}>
      <img src={avatar} className={styles.profileImage} alt="" />
    </Link>
  );
};

const HeaderPlayer = ({ user }) => {
  return (
    <div className={styles.root}>
      <div className={styles.left}>
        <Avatar user={user} href="/update" />
      </div>
      <div className={styles.center}>
          <HeadLevel progress={user.progress} />
      </div>
      <div className={styles.right}>
        <Link to="/backpack" className={classnames(styles.button)}>
          <ReactSVG className={styles.buttonIcon} src="/icons/icon-backpack.svg" wrapper="span" />
        </Link>
      </div>
    </div>
  );
};

export default HeaderPlayer;
