import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import ruPlural from '../../../helpers/ruPlural';

import styles from './quest-item.module.css';

const QuestItem = ({ quest }) => {
  const { uid, name, reward_std, categories } = quest;
  const category = categories ? categories[0] : null;

  return (
    <Link to={`/quest/${uid}`} className={styles.block}>
      <div className={styles.container}>
        <div className={classnames(styles.row, styles.rowTitle)}>
          <div className={classnames(styles.label, styles.labelTitle)}>{name}</div>
          <div className={styles.value}>
            <ReactSVG src='/icons/icon-info.svg' className={styles.info} />
          </div>
        </div>
        {category &&
          <div className={styles.row}>
            <div className={styles.label}>Категория:</div>
            <div className={styles.value}>{category.name}</div>
          </div>
        }
        <div className={styles.row}>
          <div className={styles.label}>Награда:</div>
          <div className={styles.value}>{reward_std} {ruPlural(reward_std, ['балл', 'балла', 'баллов'])}</div>
          {/* <div className={styles.value}>{reward_cut} {ruPlural(reward_cut, ['балл', 'балла', 'баллов'])}</div> */}
        </div>
      </div>
    </Link>
  );
};

export default QuestItem;