import { forwardRef } from 'react';
import { format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import classNames from 'classnames';
import Keywords from 'components/quests/Keywords';
import Tooltip from 'components/Tooltip';
import getDuration from 'helpers/getDuration';

import styles from './quests.module.css';

const QuestDetails = ({ quest }) => {
  if (!quest) return (
    <div className={classNames(styles.block, 'skeleton-loader')}></div>
  );

  const { lifetime_end, info, reward, categories, is_complete, is_cancel, start, end,
    lifetime, reward_std, keywords } = quest;

  let rows = [];

  const lifetime_secs = info ? info.lifetime*60*60 : lifetime*60*60;

  if (is_complete || is_cancel) {
    rows = [
      {
        label: 'Дата прохождения',
        value: end && format(new Date(end.replace(' ', 'T')), 'd MMMM yyyy', {locale: ru})
      }, {
        label: 'Полученная награда',
        value: `${reward} <img src="/images/energy.svg" alt="" />`,
        status: 'danger'
      }, {
      //   label: 'Статус',
      //   value: is_complete ? 'Пройден' : 'Отменен',
      // }, {
        label: 'Категория квеста',
        value: categories && categories[0]['name'],
      }
    ];
  } else if (start) {
    rows = [
      {
        label: 'Осталось времени',
        value: lifetime_end && getDuration(lifetime_end),
      }, {
        label: 'Награда',
        value: `${reward} <img src="/images/energy.svg" alt="" />`,
        status: 'danger'
      }
    ];
  } else {
    rows = [
      {
        label: 'Награда',
        value: `${reward_std} <img src="/images/energy.svg" alt="" />`,
        status: 'danger'
        // value: `${cutPoints ? reward_cut : reward_std} ${ruPlural(cutPoints ? reward_cut : reward_std, ['балл', 'балла', 'баллов'])}`,
      }, {
        label: 'Категория квеста',
        value: categories && categories[0]['name']
      }, {
        label: 'Время на выполнение',
        value: lifetime_secs && getDuration(lifetime_secs)
      }
    ]
  };

  return (
    <div className={styles.block}>
      {rows.map(({ label, value, description, status }, index) => {
        if (description) {
          const control = forwardRef(({ onClick }, ref) => <div className={styles.row} ref={ref} onClick={onClick}>
            <div className={styles.label}>{label}:</div>
            <div className={styles.value} dangerouslySetInnerHTML={{__html: value}} />
          </div>);
          return (
            <Tooltip
              control={control}
              key={index}
            >{description}</Tooltip>
          )
        } else {
          return (
            <div className={styles.row} key={index}>
              <div className={classNames(styles.label, status && styles[status])}>{label}:</div>
              <div className={classNames(styles.value, status && styles[status])} dangerouslySetInnerHTML={{__html: value}} />
            </div>
          )
        }
      })}
      {(keywords && keywords.length) ?
        <div className={styles.row}>
          <Keywords items={keywords} />
        </div>
      : null}
    </div>
  );
};

export default QuestDetails;
