import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useApiQuery from './hooks/useApiQuery';
import { selectItem } from './store/app/getters';
import { setItem } from './store/app/actions';
import { getCookie, setCookie } from './helpers/cookies';

const VersionQuery = () => {
  const version = getCookie('version') || 0;

  const { data } = useApiQuery({
    name: ['version'],
    path: `/getVersion/${version}/`
  });

  if (data && data.need_clear) {
    // localStorage.clear();
    caches.delete('workbox-precache-v2-https://play.skillary.club/');
    caches.delete('images');

    setCookie('version', data.version, { expires: new Date(new Date().getTime()+(30*24*60*60*1000))});
    if (getCookie('version') === data.version) Location.reload();
  }
  return null;
};

const SettingsQuery = () => {
  useApiQuery({
    name: ['visible_settings'],
    path: `/visiblesettings/`,
    mapper: (data = {}, prev = {}) => {
      return {...prev, ...data.reduce((acc, current) => ({ ...acc, [current.element]: current.visible }),{})};
    }
  });

  return null;
};

const MainQuery = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectItem(['user']));
  let location = useLocation();

  const { data, status } = useApiQuery({
    name: ['user'],
    path: `/getUserProfile/${(user && user.uid) && user.uid}/`,
    skip: !user || !user.uid || !location || location.pathname.startsWith('/auth'),
    update_param: location.pathname,
    mapper: (data = {}, prev) => {
      let next = {};

      for (const key in data) {
        if (key === 'main') next = data[key];
        else if (key === 'mentors') next.mentor = data[key];
        else if (key === 'players') next.player = data[key];
      }

      return next;
      // return {...prev, next};
    }
  });

  useEffect(() => {
    if (user && data.type === 'M') { // is mentor
      if (data.player && data.player[0] && data.player[0].info) { // has players
        if (!user.current_player || (user.current_player && !user.current_player.info)) { // if current player empty
          dispatch(setItem(['user', 'current_player'], data.player[0]));
        } else {
          const player = data.player.find((player) => Number(player.uid_gamer) === Number(user.current_player.uid_gamer));
          if (player) dispatch(setItem(['user', 'current_player'], player, { isHard: true }));
        }
      }
    }
    //eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (status === 401) dispatch(setItem(['user'], {}, { isHard: true }));
    //eslint-disable-next-line
  }, [status]);

  return null;
};


const CurrentPlayerQuery = ({ uid }) => {
  let location = useLocation();

  useApiQuery({
    name: ['players', uid],
    path: `/getUserProfile/${uid}/`,
    skip: !uid || !location,
    update_param: location.pathname,
    mapper: (data = {}, prev) => {
      return {...prev, ...data.main};
    }
  });

  return null;
};

export { SettingsQuery, MainQuery, VersionQuery, CurrentPlayerQuery };
