import { forwardRef } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames';
import Modal from '../Modal';
import NoItems from '../NoItems';

import styles from './rewards-list.module.css';

const medalColors = {
  M: '#05C1ED',
  Y: '#6470B5',
  W: '#99CC63',
  Q: '#FFD16A',
};

const RewardsList = ({ items = [1, 2, 3, 4, 5], showAccept, onClickAccept }) => {
  const control = forwardRef(({ onClick }, ref) => (
    <button className={styles.button} onClick={onClick} ref={ref}>Я получил награду!</button>
  ));

  if (!Object.keys(items).length) return ( // no items
    <NoItems children="Нет назначенных наград" />
  );

  return (
    <div className={styles.items}>
      {items.map(({ reward_uid, reward_name, accepted, uid, type }, index) => {
        if (!reward_name) return (
          <div className={classNames(styles.item, 'skeleton-loader')} key={index} />
        );

        return (
          <div className={styles.item} key={index}>
            {type === 'S' ? (
              <ReactSVG className={styles.icon} src="/images/gift_crystall.svg" wrapper="span" />
            ) : (
              <ReactSVG className={styles.icon} src="/images/medal-2.svg" wrapper="span" style={{ color: medalColors[type] }} />
            )}
            <div className={styles.text}>{reward_name}</div>
            {accepted ? (
              <ReactSVG className={styles.checkmark} src="/icons/icon-checked-circle-thin.svg" />
            ) : (
              showAccept && (
                <Modal
                  viewStyle="bottom"
                  control={control}
                  buttons={[
                    {
                      action: 'close',
                      text: 'Да',
                      style: 'primary',
                      onClick: () => onClickAccept(uid, type)
                    },
                    {
                      action: 'close',
                      text: 'Нет',
                      style: 'secondary',
                    }
                  ]}
                >
                  <div className={styles.modal}>
                    <div className={styles.modalImageContainer}>
                    {type === 'S' ? (
                      <ReactSVG className={styles.modalImage} src="/images/gift_crystall.svg" wrapper="span" />
                    ) : (
                      <ReactSVG className={styles.modalImage} src="/images/medal-2.svg" wrapper="span" />
                    )}
                    </div>
                    <div className={styles.modalText}>
                      Награда “{reward_name}” была получена?
                    </div>
                  </div>
                </Modal>
              )
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RewardsList;
