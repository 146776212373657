import { useRef, forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import { SkillBar } from 'components/Skills';
import ButtonsContainer from 'components/ButtonsContainer';
import Button from 'components/Button';
import InfoBlock from 'components/InfoBlock';
import Modal from 'components/Modal';
import Checkbox from 'components/fields/Checkbox';
import * as api from 'helpers/api';
import { wheelStop, wheelRoll } from 'scripts/wheel';
import { setItem } from 'store/app/actions';

import styles from './quests-wheel.module.css';

const QuestsWheel = forwardRef(({ user, wheel, quest_hard_level, wheelTitle, wheelTime, allowClick, onSetHardLvl }, ref) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const container = useRef(null);
  const list = useRef(null);
  const [ error, setError ] = useState(null);
  const toDate = new Date().getTime() / 1000 - 3600;

  const [ isShowDifficult, setShowDifficult ] = useState(false);
  const [ difficult, setDifficult ] = useState(quest_hard_level);
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);

  const start = () => {
    dispatch(setItem(['user', 'wheel'], {}, { isHard: true }));

    setTimeout(() => {
      wheelRoll(list.current);

      api
        .get(`/getQuestAll/?random=1&limit=3`)
        .then(response => {
          const result = response.data.response;
          if (!result) return null;

          setTimeout(() => {
            wheelStop(list.current);

            if (!response.data.roulett.allow) setError(response.data.roulett.message);
            dispatch(setItem(['user', 'wheel'], result, { isHard: true }));
            dispatch(setItem(['user', 'wheelTitle'], response.data.roulett.title));
            dispatch(setItem(['user', 'wheelTime'], new Date().getTime() / 1000));
          }, 1000);
        })
        .catch(error => {
          wheelStop(list.current);
        });
    });
  }

  return (
    <>
      <div className={styles.title}>{(wheelTitle && wheelTime && wheelTime > toDate) ? wheelTitle : null}</div>
      <ButtonsContainer className={styles.buttons}>
        {!allowClick ? (
            <InfoBlock color="orange">
              У вас максимальное количество активных квестов. Завершите или отмените хотя бы один из них, чтобы взять новый.
            </InfoBlock>
        ) : null}
        <Button
          fullWidth={true}
          className={styles.difficultButton}
          butStyle="secondary"
          size="medium"
          onClick={() => setShowDifficult(true)}
        >
          <span>
            Сложность:
            {user && (
              (difficult && difficult.length) ? (
                difficult.map(id => <span className={styles.difficult} key={id}>{id}</span>)
              ) : (
                <span className={classnames('accent-text', styles.difficultText)}>Не выбрано</span>
              )
            )}
          </span>
        </Button>
        <Button
          fullWidth={true}
          butStyle="secondary"
          size="medium"
          to="/interests"
        >
          Интересы
        </Button>
        <Button
          fullWidth={true}
          butStyle="primary"
          onClick={() => start()}
          disabled={!allowClick || error}
        >
          Запустить рулетку
        </Button>
      </ButtonsContainer>

      <div className={styles.root}>
        <ReactSVG src="/icons/icon-triangle.svg" className={classnames(styles.arrow, wheel && styles.animate)} />

        <div className={styles.container} ref={container}>
          <div className={styles.list} ref={list}>
            {[...Array(12)].map((q, index) => {
              const item = (wheel && wheel[index]) ? wheel[index] : null;

              return (
                <div
                  className={classnames(styles.item, item && styles.visible)}
                  onClick={() => item && navigate(`/quest/${item.uid}`, { state: { from: 'wheel' } })}
                  key={index}
                >
                  {item ? (
                    <>
                      <div className={styles.points}>+{item.reward_std} <img src="/images/energy.svg" alt="" /></div>
                      <div className={styles.name}>{item.name}</div>
                      {(item.categories && item.categories.length) &&
                        <div className={styles.itemCategory}>
                          <b>Категория:</b> {item.categories[0].name}
                        </div>
                      }
                      <div className={styles.itemSkills}>
                        {item.skills && Object.values(item.skills).slice(0, 3).map(({ value, name }, index) => {
                          if (!value) return null;
                          return (
                            <div className={styles.skill} key={index}>
                              <SkillBar value={value} max={item.reward_std} color={name} />
                            </div>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    <img src={`/images/wheel_${index%3}.svg`} className={styles.placeholder} alt="" />
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {error &&
          <div className={styles.overlay}>
            <div className={styles.error}>
              {error}
            </div>
          </div>
        }

        <Modal
          viewStyle="bottom"
          open={isShowDifficult}
          onClose={() => setShowDifficult(false)}
          buttons={[
            {
              text: 'Сохранить',
              style: 'primary',
              onClick: () => {
                const ids = [];
                if (ref1.current.checked) ids.push(1);
                if (ref2.current.checked) ids.push(2);
                if (ref3.current.checked) ids.push(3);

                setDifficult(ids);
                onSetHardLvl(ids);
                setShowDifficult(false);
              }
            }
          ]}
        >
          <Checkbox
            id="easy"
            name="easy"
            label="1 - Легкий"
            checked={!!difficult?.find(id => Number(id) === 1)}
            containerClass={styles.difficultCheckbox}
            ref={ref1}
          />
          <Checkbox
            id="normal"
            name="normal"
            label="2 - Средний"
            checked={!!difficult?.find(id => Number(id) === 2)}
            containerClass={styles.difficultCheckbox}
            ref={ref2}
          />
          <Checkbox
            id="hard"
            name="hard"
            label="3 - Сложный"
            checked={!!difficult?.find(id => Number(id) === 3)}
            containerClass={styles.difficultCheckbox}
            ref={ref3}
          />
        </Modal>
      </div>
    </>
  );
});

export default QuestsWheel;
