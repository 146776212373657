import { Navigate } from 'react-router-dom';
import * as Common from '../pages/Common';
import * as Account from '../pages/Account';
import * as Player from '../pages/Player';
import * as Mentor from '../pages/Mentor';
import * as Quests from '../pages/Quests';
import * as Game from '../pages/Game';

const routes = {
  main: [
    { path: '/quests-list', element: <Quests.QuestsList /> },
    { path: '/quest/:qid', element: <Quests.Quest /> },
    { path: '/video-guide', element: <Common.VideoGuide /> },
    { path: '/about-video/:slug', element: <Common.AboutVideo /> },
    { path: '/login', element: <Navigate replace to="/" /> },
    { path: '/auth/:token', element: <Account.Auth /> },
    { path: '/register', element: <Navigate replace to="/" /> },
    { path: '/register/:phone', element: <Navigate replace to="/" /> },
    { path: '*', element: <Common.NotFound /> },

    { path: '/game', element: <Game.Game /> },
    { path: '/game-chat', element: <Game.Chat /> },
  ],

  register: [
    { path: '/', element: <Account.Register />, exact: true },
    { path: '/register', element: <Account.Register /> },
    { path: '/register/:phone', element: <Account.Register /> },
    { path: '/login', element: <Account.Login /> },
    { path: '/auth/:token', element: <Account.Auth /> },
    { path: '/add-mentoring/:token', element: <Account.CreateMentoring /> },
    { path: '*', element: <Navigate replace to="/" /> }
  ],

  player: [
    { path: '/', element: <Player.Home />, exact: true },
    { path: '/game', element: <Game.Game /> },
    { path: '/quests-wheel', element: <Player.QuestsWheel /> },
    { path: '/user-quest/:qid', element: <Player.UserQuest /> },
    { path: '/user-quests/', element: <Player.UserQuestsList /> },
    { path: '/get-quest-approve/:qid', element: <Player.GetQuestApprove /> },
    { path: '/quest-success/:qid', element: <Player.QuestSuccess /> },
    { path: '/rate-quest/:qid', element: <Player.RateQuest /> },
    { path: '/invite-mentor', element: <Player.InviteMentor /> },
    { path: '/invite-mentor/:phone', element: <Player.InviteMentor /> },
    { path: '/invite-player', element: <Player.InvitePlayer /> },
    { path: '/update', element: <Player.Update /> },
    { path: '/create-avatar', element: <Player.CreateAvatar /> },
    { path: '/rating', element: <Player.Rating /> },
    { path: '/statistic', element: <Player.Statistic /> },
    { path: '/ambitions', element: <Player.Ambitions /> },
    { path: '/invited', element: <Player.InvitedUsers /> },
    { path: '/bonuses', element: <Player.Bonuses /> },
    { path: '/facts', element: <Player.Facts /> },
    { path: '/approve-quest/:token', element: <Player.ApproveQuest /> },
    { path: '/quests', element: <Player.Quests /> },

    // { path: '/courses/:cid', element: <Player.Courses /> },
    // { path: '/courses/:cid/:gid', element: <Player.CourseQuests /> },
    // { path: '/user-courses', element: <Player.UserCourses /> },
    // { path: '/user-course/:cid', element: <Player.UserCourseQuests /> },
    { path: '/quests-group/:gid', element: <Player.GroupQuests /> },

    { path: '/services', element: <Player.Services /> },
    { path: '/wallet', element: <Player.Wallet /> },
    { path: '/guide', element: <Player.Comics /> },
    { path: '/backpack', element: <Player.Backpack /> },
    { path: '/rewards', element: <Player.Rewards /> },
    { path: '/rewards-from-mentor', element: <Player.RewardsMentor /> },
    { path: '/artefacts', element: <Player.Artefacts /> },
    { path: '/interests', element: <Player.Interests /> },
    { path: '/store', element: <Player.Store /> },
    { path: '/comics', element: <Player.Comics /> },

    { path: '/homework', element: <Player.HomeworkList /> },
    { path: '/homework/:date', element: <Player.Homework /> },
  ],

  emptyMentor: [
    { path: '/create', element: <Account.CreateMentor /> },
    { path: '/create-player', element: <Account.CreatePlayer /> },
    { path: '/create-player/:uid', element: <Account.CreatePlayer /> },
    { path: '/create-player-success', element: <Account.CreatePlayerSuccess /> },
    { path: '/add-mentoring/:token', element: <Account.CreateMentoring /> },
    // { path: '/register-success', element: <Account.RegisterSuccess /> },
    // { path: '/select-city', element: <Account.SelectCity /> },
    // { path: '/select-school', element: <Account.SelectSchool /> },
    // { path: '/select-success', element: <Account.SelectSuccess /> },
    { path: '*', element: <Navigate replace to="/create" /> }
  ],
  mentor: [
    { path: '/', element: <Mentor.Home />, exact: true },
    { path: '/register-success', element: <Account.RegisterSuccess /> },
    { path: '/select-city', element: <Account.SelectCity /> },
    { path: '/select-school', element: <Account.SelectSchool /> },
    { path: '/select-success', element: <Account.SelectSuccess /> },

    { path: '/player', element: <Mentor.Home /> },
    { path: '/add-player', element: <Mentor.AddPlayer /> },
    { path: '/create-player', element: <Account.CreatePlayer /> },
    { path: '/create-player/:uid', element: <Account.CreatePlayer /> },
    { path: '/create-player-success', element: <Account.CreatePlayerSuccess /> },
    { path: '/invite-player/:uid', element: <Mentor.InvitePlayer /> },
    { path: '/add-mentoring/:token', element: <Account.CreateMentoring /> },

    { path: '/quests', element: <Mentor.Quests /> },
    { path: '/quests-wheel', element: <Mentor.QuestsWheel /> },
    { path: '/user-quest/:qid', element: <Mentor.UserQuest /> },
    { path: '/user-quests/', element: <Mentor.UserQuestsList /> },
    { path: '/update', element: <Mentor.Update /> },
    { path: '/update-player', element: <Mentor.UpdatePlayer /> },
    { path: '/update-player/:uid', element: <Mentor.UpdatePlayer /> },
    { path: '/rating', element: <Mentor.Rating /> },
    { path: '/statistic', element: <Mentor.Statistic /> },
    { path: '/ambitions', element: <Mentor.Ambitions /> },
    { path: '/create-ambition', element: <Mentor.CreateAmbition /> },
    { path: '/invite-mentor/:uid', element: <Mentor.InviteMentor /> },
    { path: '/invite-mentor/:uid/:phone', element: <Mentor.InviteMentor /> },
    { path: '/invited', element: <Mentor.InvitedUsers /> },
    { path: '/bonuses', element: <Mentor.Bonuses /> },
    { path: '/facts', element: <Mentor.Facts /> },
    { path: '/player-facts', element: <Mentor.FactsPlayer /> },
    { path: '/approve-quest/:token', element: <Mentor.ApproveQuest /> },

    // { path: '/courses/:cid', element: <Mentor.Courses /> },
    // { path: '/courses/:cid/:gid', element: <Mentor.CourseQuests /> },
    // { path: '/user-courses', element: <Mentor.UserCourses /> },
    // { path: '/user-course/:cid', element: <Mentor.UserCourseQuests /> },
    { path: '/quests-group/:gid', element: <Mentor.GroupQuests /> },

    { path: '/services', element: <Mentor.Services /> },
    { path: '/grants', element: <Mentor.Grants /> },
    { path: '/tasks', element: <Mentor.Tasks /> },
    { path: '/wallet', element: <Mentor.Wallet /> },
    { path: '/comics', element: <Mentor.Comics /> },
    { path: '/guide', element: <Mentor.Guide /> },
    { path: '/store', element: <Mentor.Store /> },
    { path: '/faq', element: <Mentor.Faq /> },
    { path: '/backpack', element: <Mentor.Backpack /> },
    { path: '/artefacts', element: <Mentor.Artefacts /> },
    { path: '/interests', element: <Mentor.Interests /> },

    { path: '/select-tariff', element: <Mentor.SelectTariff /> },
    { path: '/pay-success', element: <Mentor.PayTariffSuccess /> },

    { path: '/update-rewards', element: <Mentor.UpdateRewards /> },
    { path: '/rewards', element: <Mentor.Rewards /> },
    { path: '/guide-rewards', element: <Mentor.GuideRewards /> },

    { path: '/homework', element: <Mentor.HomeworkList /> },
    { path: '/homework/:date', element: <Mentor.Homework /> },
    { path: '/homework-update', element: <Mentor.HomeworkSchedule /> },
  ]
};

export default routes;
