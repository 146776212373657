import { useSelector } from 'react-redux';
import { MainLayout } from 'components/Layouts';
import Video from 'components/Video';
import { selectItem } from 'store/app/getters';

import styles from './common.module.css';

const videoUrlMentor = 'https://youtu.be/aZ8V5lpvE7Q';
const videoUrlPlayer = 'https://youtu.be/wphqCbBiFX8';

const VideoGuide = () => {
  const user = useSelector(selectItem(['user']));

  return (
    <MainLayout title="Как пользоваться сервисом">
      <div className={styles.video}>
        {user &&
          <Video src={user.type === 'M' ? videoUrlMentor : videoUrlPlayer} onError={e => console.log(e)} />
        }
      </div>
    </MainLayout>
  );
};

export default VideoGuide;
