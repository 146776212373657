import { useState, useRef } from 'react';
import classnames from 'classnames';
import Slider from 'react-slick';
import { ReactSVG } from 'react-svg';
import { TailSpin } from 'react-loader-spinner';

import styles from './gallery.module.css';

const NextArrow = ({ className, style, onClick }) => (
  <ReactSVG
    src="/icons/arrow-right-galley.svg"
    className={classnames(className, styles.arrow)}
    style={style}
    onClick={onClick}
  />
);

const PrevArrow = ({ className, style, onClick }) => (
  <ReactSVG
    src="/icons/arrow-left-galley.svg"
    className={classnames(className, styles.arrow)}
    style={style}
    onClick={onClick}
  />
);

const Slide = ({ link }) => {
  const [ isLoad, setIsLoad ] = useState(false);

  return (
    <div
      className={classnames(styles.item, 'slide-item')}
      key={link}
    >
      {!isLoad &&
        <div className={styles.loader}>
          <TailSpin color="var(--brand-color)" height="64" width="64" />
        </div>
      }
      <img src={link} alt="" onLoad={() => setIsLoad(true)} />
    </div>
  );
};

const Gallery = ({ items }) => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    lazyLoad: 'progressive',
    lazyIndicator: <TailSpin color="var(--brand-color)" height="64" width="64" />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    adaptiveHeight: true
  };

  const slider = useRef(null);

  const renderSlides = () => (
    items.map((link, index) =>
      <Slide
        index={index}
        link={link}
        key={index}
      />
    )
  );

  return (
    <div className={styles.root}>
      <Slider {...settings} ref={slider}>
        {renderSlides(true)}
      </Slider>
    </div>
  );
};

export default Gallery;