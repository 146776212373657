import styles from './progress-bar.module.css';

const ProgressBar = ({ value, total, color }) => {
  const percent = value <= total ? (value * 100) / total : 100;

  return (
    <div className={styles.root}>
      <div className={styles.fill} style={{ width: `${percent}%`, backgroundColor: color && color }} />
    </div>
  )
};

export default ProgressBar;
