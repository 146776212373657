import { forwardRef } from 'react';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';

import styles from '../fields.module.css';

const Input = forwardRef(({ id, name, placeholder, mask, type='text', hidden, value,
  containerClass, title, label, fullWidth, error, icon, notice, pattern, inputClass, ...props }, ref) => {
  const Component = mask ? NumberFormat : 'input';

  return (
    <div className={classnames(styles.container, fullWidth && styles.fullWidth, containerClass && styles[containerClass], hidden && styles.hidden)}>
      {title && <div className={styles.title}>{title}</div>}
      {label && <label className={styles.label} htmlFor={id}>{label}</label>}
      <div className={styles.inputContainer}>
        <Component
          id={id}
          name={name}
          placeholder={placeholder}
          type={type}
          className={classnames(styles.input, icon && styles.withIcon, inputClass && inputClass, error && 'error', mask && 'masked', value && 'filled')}
          value={value}
          pattern={pattern}
          ref={ref}
          {...props}
          {...(mask ? {format: mask} : {})}
          {...(mask ? {allowEmptyFormatting: true} : {})}
        />
        {icon &&
          <span className={styles.icon}>{icon}</span>
        }
      </div>
      {error &&
        <span className={styles.errorText}>{error}</span>
      }
      {notice &&
        <span className={styles.notice}>{notice}</span>
      }
    </div>
  );
});

export default Input;